import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-35602760"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "trade-product-item"
};
const _hoisted_2 = {
  style: {
    "display": "flex",
    "align-items": "center"
  }
};
const _hoisted_3 = {
  class: "product-name"
};
import { fmtImageSrc } from '@/util';
import store from '@/store';
import { fmtNumber } from '@/util';
export default {
  __name: 'TradeProductItem',
  props: {
    name: {
      type: String,
      default: ''
    },
    last: {
      type: String,
      default: '0.00'
    },
    up: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    }
  },

  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_image, {
        src: _unref(fmtImageSrc)(props.icon),
        width: "25",
        height: "25",
        round: ""
      }, null, 8, ["src"]), _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(store).state.marketInfo[props.name]?.name), 1)]), _createElementVNode("span", {
        class: "product-price",
        style: _normalizeStyle({
          color: __props.up ? '#04AC92' : '#EC2752'
        })
      }, _toDisplayString(_unref(fmtNumber)(props.last)), 5)]);
    };
  }

};