export default {
  required: 'Erforderlich',
  home: 'Startseite',
  market: 'Märkte',
  currency: 'Handeln',
  walletTrade: 'Stelle',
  contract: 'Vertrag',
  wallet: 'Geldbörsen',
  pulling: 'Zum Aktualisieren nach unten ziehen...',
  loosing: 'Zum Aktualisieren loslassen...',
  loading: 'Wird geladen...',
  loadingError: 'Laden fehlgeschlagen',
  nothing: 'Keine Daten',
  finished: '',
  price: 'Preis',
  number: 'Menge',
  recharge: 'Anzahlung',
  transfer: 'Überweisen',
  invite: 'Einladen',
  inviteDes: 'Laden Sie Freunde ein, sich uns anzuschließen',
  contact: 'Kontaktiere uns',
  contactDes: '7*24 Online',
  fastTrade: 'Optionen',
  name: 'Name',
  fastPrice: 'Preis',
  trend: '24H %',
  all: 'Alle',
  growthList: 'Gewinner',
  loserList: 'Verlierer',
  vol: '24H Vol',
  buy: 'Kaufen',
  sell: 'Verkaufen',
  optimal: 'Marktpreis',
  marketOrder: 'Markthandel',
  limitOrder: 'Beschränken Sie den Handel',
  useable: 'Erhältlich',
  tradeVol: 'Höhe',
  entrust: 'Limit-Order',
  entrustTotal: 'Höhe',
  noLogin: 'Ausloggen, ',
  toLogin: 'Jetzt einloggen',
  cancelOrder: 'Absagen',
  cancelError: 'Stornieren fehlgeschlagen',
  cancelSuccess: 'Erfolgreich kündigen',
  cancelTip: 'Sicher stornieren?',
  completed: 'Vollendet',
  canceled: 'Abgebrochen',
  marketPrice: 'Markt',
  limitPrice: 'Grenze',
  entrustPrice: 'Preis',
  entrustNum: 'Menge',
  currencyType: 'Symbol',
  headerCurrencyType: 'Symbol',
  currencyOrder: 'Handelsaufzeichnung',
  history: 'Geschichte',
  tryAgain: 'Versuchen Sie es nochmal',
  entrustPriceTip: 'Eingabepreis',
  tradeNumTip: 'Eingabemenge',
  tradeNumLess: 'Die Menge sollte größer als 0 sein',
  numLessTip: 'Mangelhaftes Gleichgewicht',
  tradePriceTip: 'Betrag eingeben',
  balanceTip: 'Mangelhaftes Gleichgewicht',
  tradeError: 'Handel fehlgeschlagen',
  tradeSuccess: 'Handeln Sie erfolgreich',
  tip: 'Notiz',
  tradeConfirm: 'Zum Handeln bestätigen？',
  cancelText: 'Absagen',
  confirmText: 'Bestätigen',
  toBuy: 'Aufkaufen',
  toSell: 'Kurz verkaufen',
  confirmToBuy: 'Aufkaufen',
  confirmToSell: 'Kurz verkaufen',
  slPrice: 'SL Preis',
  sl: 'SL',
  tp: 'TP',
  tpPrice: 'TP Preis',
  currentOrder: 'Aktueller Auftrag',
  historyOrder: 'Geschichte',
  setting: 'Satz',
  unwind: 'Nah dran',
  handUnwind: 'Manuell schließen',
  futuresPay: '',
  forceUnwind: 'Liquidation',
  slTp: 'SL/TP',
  fastTradeUnwind: 'Optionen',
  blowUp: 'Liquidation',
  openNum: 'Menge',
  warrant: 'Rand',
  openPrice: 'Preis',
  fee: 'Gebühr',
  profit: 'Profitieren',
  lastPrice: 'Derzeitiger Preis',
  unwindPrice: 'Geschlossener Preis',
  unwindError: 'Schließen fehlgeschlagen',
  unwindSuccess: 'Erfolgreich schließen',
  setSuccess: 'Erfolgreich eingestellt',
  setError: 'Festlegen fehlgeschlagen',
  setSlTp: 'Satz SL/TP',
  second: 's',
  multiple: 'Mehrere',
  direction: 'Art',
  tradeCurrency: 'Symbol',
  loss: 'Verlust',
  fill: 'Gewinnen',
  executePrice: 'Preis',
  currentPrice: 'Derzeitiger Preis',
  closePrice: 'Geschlossener Preis',
  secondNum: 'Zeitraum',
  contractOrder: 'Terminaufträge',
  fastTradeOrder: 'Optionsaufträge',
  overview: 'Überblick',
  ieoBuy: 'IEO',
  balanceTotal: 'Gesamtvermögen',
  withdraw: 'zurückziehen',
  tradeOrder: 'Handelsauftrag',
  rechargeHistory: 'Hinterlegungssatz',
  withdrawHistory: 'Aufzeichnung zurückziehen',
  freeze: 'Gefroren',
  amountTo: '',
  tipText: 'Notiz',
  ieoTip: 'IEO ist jetzt nicht geöffnet, bitte versuchen Sie es später erneut',
  financeList: 'Finanzbericht',
  currencyAccount: 'Spot-Konto',
  contractAccount: 'Futures-Konto',
  time: 'Zeit',
  status: 'Status',
  balanceTyle: 'Konto',
  pay: 'Ergebnis',
  income: 'Einkommen',
  lastBalance: 'Balance',
  orderNo: 'ID',
  money: 'Höhe',
  date: 'Datum',
  finallyIncome: 'Betrag erhalten',
  commitMoney: 'Höhe',
  orderNum: 'ID',
  waitReview: 'Überprüfung',
  reviewPass: 'Bestanden',
  reviewReject: 'Abgelehnt',
  waitWallet: 'Überprüfung',
  walletPass: 'Bestanden',
  walletReject: 'Abgelehnt',
  withdrawSuccess: 'Erfolgreich abheben',
  withdrawError: 'Übertragung fehlgeschlagen',
  high24H: '24H Hoch',
  low24H: '24H Niedrig',
  num24H: '24H Vol',
  minute: 'Protokoll',
  hour: 'Std',
  day: 'Tag',
  week: 'Woche',
  markets: 'Markt',
  open: 'Offen',
  close: 'Nah dran',
  high: 'Hoch',
  low: 'Niedrig',
  volume: 'Vol',
  fastLogin: 'Jetzt einloggen',
  register: 'registrieren',
  auth: 'Verifizieren',
  authSuccess: 'Verifiziert',
  noPass: 'Abgelehnt',
  authCommit: 'Überprüfung',
  onAuth: 'Unbestätigt',
  onLogin: 'Ausloggen',
  securitySet: 'Sicherheit',
  userAgree: 'Benutzerunterstützung',
  aboutUs: 'Über uns',
  logout: 'Ausloggen',
  logoutSuccess: 'Abmeldung erfolgreich',
  lookAuthInfo: 'Überprüfen Sie meine Informationen',
  authAgain: 'Überprüfen Sie erneut',
  authInfo: 'Information',
  account: 'Konto',
  countryArea: 'Land/Region',
  idNo: 'ID#',
  idNoTip: 'ID-Nummer eingeben',
  email: 'Email',
  emailInput: 'E-Mail-Adresse eingeben',
  setLoginPwd: 'Login-Passwort festlegen',
  loginPwdInput: 'Login-Passwort eingeben (8-20 Zeichen)',
  loginPwdTip: 'Login-Passwort eingeben',
  loginPwdLen: 'Das Login-Passwort sollte 8-20 Zeichen lang sein',
  setFinancePwd: 'Fondskennwort festlegen',
  financePwdInput: 'Fondspasswort eingeben (6-20 Zeichen)',
  financePwdTip: 'Fondspasswort eingeben',
  financePwdLen: 'Das Fondspasswort sollte 6–20 Zeichen lang sein',
  inviteCode: 'Einladungscode',
  inviteCodeTip: 'Einladungscode eingeben',
  inviteInput: 'Einladungscode ist erforderlich',
  imageCode: 'Captcha',
  imageCodeInput: 'Captcha eingeben',
  registerTip: 'Registrieren bedeutet, dass Sie einverstanden sind ',
  agree: '《Benutzervereinbarung》',
  agreeText: 'Benutzervereinbarung',
  next: 'Nächste',
  registerSuccess: 'Registrieren Sie sich vollständig',
  registerError: 'Registrierung fehlschlagen',
  securityCheck: 'Sicherheit',
  checkCodeInput: 'Bestätigungscode eingeben',
  sendCheckCode: 'Senden',
  sendSuccess: 'Gesendet',
  sendError: 'Senden fehlgeschlagen',
  welcome: 'Willkommen zu',
  loginEmail: 'Email',
  loginEmailInput: 'E-Mail-Adresse eingeben',
  loginPwd: 'Passwort',
  login: 'Anmeldung',
  forgetPwd: 'Passwort vergessen',
  loginTip: 'Login bedeutet, dass Sie damit einverstanden sind',
  loginSuccess: 'Melden Sie sich vollständig an',
  loginError: 'Login fehlgeschlagen',
  fullname: 'Name',
  fullnameTip: 'Geben Sie Ihren Namen ein',
  country: 'Land/Region',
  countryTip: 'Land/Region eingeben',
  imgFront: 'Laden Sie ein Foto von der Vorderseite Ihres Ausweises hoch',
  imgReverse: 'Laden Sie ein Foto von der Rückseite Ihres Ausweises hoch',
  commit: 'einreichen',
  commitSuccess: 'Vollständig einreichen',
  commitError: 'Senden fehlgeschlagen',
  selectCurrency: 'Wählen Sie Krypto',
  rechargeNet: 'Netzwerk',
  rechargeAddress: 'Hinterlegungsadresse',
  copyAddress: 'Adresse kopieren',
  minRecharge: 'Mindesteinzahlungsbetrag',
  copySuccess: 'Komplett kopieren',
  copyError: 'Kopieren fehlgeschlagen',
  withdrawTip: 'Sie können abheben, nachdem Sie Ihr Konto verifiziert haben',
  withdrawAddress: 'Auszahlungsadresse',
  withdrawAddressInput: 'Auszahlungsadresse eingeben',
  withdrawNet: 'Netzwerk',
  withdrawNum: 'Höhe',
  withdrawNumInput: 'Betrag eingeben',
  withdrawNumLess: 'Mangelhaftes Gleichgewicht',
  withdrawMin: 'Mindestauszahlungsbetrag',
  balanceUse: 'Erhältlich',
  actualMoney: 'Betrag erhalten',
  actualMoneyText: 'Betrag erhalten',
  financePwd: 'Passwort für den Fonds',
  trade: '',
  withdrawFail: 'Rückzug fehlgeschlagen',
  commitTime: 'Datum',
  from: 'Von',
  to: 'Zu',
  transferMoney: 'Betrag eingeben',
  transferSuccess: 'Vollständig übertragen',
  transferError: 'Übertragung fehlgeschlagen',
  transferHistory: 'Datensatz übertragen',
  updateFinancePwd: 'Fondspasswort ändern',
  confirmPwd: 'Kennwort bestätigen',
  confirmPwdInput: 'Geben Sie das Bestätigungspasswort ein',
  updatePwd: 'Passwort ändern',
  updatePwdSuccess: 'Komplett ändern',
  updatePwdError: 'Fehler beim Ändern',
  noAlike: 'Passwort ist inkonsistent',
  emailUpdate: 'E-Mail Adresse ändern',
  loginPwdUpdate: 'Login-Passwort ändern',
  financePwdUpdate: 'Fondspasswort ändern',
  newEmail: 'Neue Email Adresse',
  emailUpdateTip: 'Geben Sie eine neue E-Mail-Adresse ein',
  checkEmail: 'Email Adresse bestätigen',
  updateEmail: 'E-Mail Adresse ändern',
  updateEmailError: 'Fehler beim Ändern',
  updateEmailSuccess: 'Komplett ändern',
  checkError: 'Überprüfung fehlgeschlagen',
  pwdLen: '8-20 Zeichen',
  inviteUrl: 'Empfehlungslink',
  myInviteCode: 'Einladungscode',
  fastInvite: 'Jetzt einladen',
  myInvite: 'Einladungsliste',
  registerTime: 'Datum',
  bulletin: 'Bekanntmachung',
  inviteFriend: 'Freunde einladen',
  saveAccount: 'Konto und Passwort speichern',
  indicator: 'Indikatoren',
  type: 'Arten von',
  amount: 'Höhe',
  scanError: 'Bilderkennung fehlgeschlagen',
  scanInitErr: 'Initialisierung fehlgeschlagen',
  noCamera: 'Keine Kameraerlaubnis',
  secondTip: 'Der Betrag sollte nicht weniger als {tip} betragen',
  completePrice: 'Preis',
  profitText: 'Profitieren',
  profitAndLoss: 'Geschätzter Gewinn und Verlust',
  timeUp: 'Zeit vorbei',
  balanceText: 'Geschichte',
  closeAll: 'Alle schließen',
  assetRate: 'Margin-Rate',
  cancel: 'Absagen',
  cancelInfo: 'Abbrechen bestätigen?',
  buyCurrency: 'Symbol',
  closeCurrency: 'Währung',
  buyPrice: 'Preis',
  valuation: 'Bewertung',
  profitP: 'Ertrag',
  buySuccess: 'Erfolgreich',
  swiperTitle1: 'Die weltbeste digitale Krypto-Börse',
  swiperSub1: 'Vertrauen von mehr als 100.000 Benutzern',

  ieoBuying: 'Laufend',
  ieoOpen: 'Anfangsdatum (UTC-5)',
  ieoClose: 'Enddatum(UTC-5)',
  about: 'Über',
  ieoPlan: 'Verteilung',
  ieoInfo: 'Information',
  ieoContact: 'Sozialen Medien',
  ieoStart: 'Initialisierung',
  ieoBuyStart: 'IEO Start',
  ieoBuyEnd: 'IEO Fertig',
  ieoIsTrade: 'Starten',
  introduce: 'Einführung',
  ieoSubBuy: 'IEO',
  subBuyHistory: 'IEO Geschichte',
  rechargeTip1: '1. Dies ist Ihre neueste und eindeutige Adresse. Die Plattform wird Sie niemals auffordern, Geld auf ein anderes Konto zu überweisen oder nach Ihrem Bestätigungscode zu fragen.',
  rechargeTip2: '2. Die Einzahlung erfordert mindestens 10 Netzwerkblöcke zur Bestätigung. Nach der Bestätigung erhält Ihr Konto automatisch das Geld.',
  withdrawTip1: '1. Stellen Sie sicher, dass Sie für diese Auszahlung dasselbe Netzwerk wie auf der Einzahlungsseite auswählen, und überprüfen Sie, ob die empfangende Plattform das Netzwerk unterstützt. Andernfalls verlieren Sie Ihr Vermögen.',
  withdrawTip2: '2. Netzwerkblöcke bestätigen Ihre Auszahlung, nachdem die Plattform Ihren Antrag genehmigt hat.',
  withdrawTip3: '3. Die Auszahlung erfordert mindestens 10 Netzwerkblöcke zur Bestätigung. Nach der Bestätigung erhält Ihr Konto automatisch das Geld.',
  coming: 'Es kommt bald.',
  createTeam: 'Rücklagen',
  angelFund: 'Engel-Fonds',
  ecology: 'Institutionen und Ökosysteme',
  presale: 'Vorverkaufsfreigabe',
  mechanism: 'Bergbaumechanismus',
  trading: 'Börsenhandel',

  issuePrice: 'Ausgabepreis',
  minBuyPrice: 'Mindestabonnement',
  buyNum: 'Abonnements pro Tag',
  buyTotal: 'Gesamttagesabonnement',
  Increment: 'Abonnementerhöhung',
  blockchain: 'Blockchain',

  sellTotal: 'Gesamtausgabe',
  currentBuy: 'Aktuelles Abonnement',
  lessMinTip: 'Darf nicht unter dem Mindestbetrag liegen.',
  stakeAccount: 'Staking-Konto',
  serviceAccount: 'Dienstkonto',
  lock: 'Sperren',
  lockPrice: 'Gesperrter Preis',
  ieoHistory: 'IEO-Geschichte',
  lockHistory: 'Verlauf sperren',
  sellHistory: 'Geschichte verkaufen',
  stake: 'Abstecken',
  service: 'Service',
  ieoBuyTip: "*IEO-Hinweis: Die Anzahl der LTJ ist begrenzt. Wir werden den IEO-Antrag des Benutzers prüfen und alle 3 Stunden nach dem Zufallsprinzip ein Los ziehen. Benutzer mit einem relativ großen Betrag haben eine höhere Wahrscheinlichkeit, LTJ zu kaufen. Jeder Benutzer kann bis zu 3 Mal pro Tag kaufen.",
  ieoLockTip: '*Sperrpreis: Benutzer müssen USDT als Pfandwährung verwenden, um den Preis von LTJ zu sperren, und das Pfandverhältnis beträgt 30 % des LTJ-Werts. Nach dem Start von LTJ können Benutzer LTJ zu dem zu diesem Zeitpunkt festgelegten Preis verkaufen.',
  ieoSellTip: '*IEO-Verkauf: Der Verkauf von LTJ erfordert 10 % des LTJ-Werts als Servicegebühr. Nach dem Verkauf von LTJ wird der von Ihnen ausgetauschte USDT in 20 Tagen und der verpfändete USDT in 20 Tagen wieder freigegeben.',
  whitePaper: 'WEISSES PAPIER',
  cost: 'Kosten',
  currentEstimate: 'Aktuelle Bewertung',
  stakeNum: 'Staking-Betrag',
  estimate: 'Bewertung',
  consume: 'Verbrauchen',

  seedSell: 'Samenrunde',
  privateSell: 'Private Runde',
  openSell: 'KOL-Runde',
  ieoAward: 'Öffentliche Runde',
  ieoGrowUp: 'Team',
  partner: 'Berater',
  consultant: 'Marketing',
  ieoName: 'Liquidität',
  conAward: 'KI zum Verdienen',
  staking: 'Abstecken',

  release: 'Freigeben',
  closedTime: 'Geschlossene Zeit',
  remark: 'Anmerkung',

  totalProfit: 'Gesamtgewinn',
  transferIn: 'Einsteigen',
  transferOut: 'Auslagern',
  equity: 'Reinvermögen',

  homeremind1: 'Beliebte Kryptowährungen',
  homeremind2: 'Weitere Märkte anzeigen',
  homeremind3: 'Letzter Preis (USD)',
  homeremind4: '24-Stunden-Wechsel',
  homeremind5: 'Aktion',
  homeremind6: 'Mehr sehen',
  homeremind7: "Die Top-Auswahl der Benutzer",
  homeremind8: '24-Stunden-Handelsvolumen',
  homeremind9: 'Erstklassige virtuelle Assets',
  homeremind10: 'Unsere Kernprodukte',
  homeremind11: 'Eine große Auswahl an Handelstools von ',
  homeremind12: 'Stelle',
  homeremind13: 'Über 700 Kryptos stehen zum Handel zur Verfügung',
  homeremind14: 'Verbessern Sie Ihre Erfahrung durch den Handel mit Futures',
  homeremind15: 'NYECoins Verdienen',
  homeremind16: 'Beste APYs in der Branche',
  homeremind17: 'Unsere vielfältigen Dienstleistungen',
  homeremind18: 'Entdecken Sie unbegrenzte Möglichkeiten mit unseren vielfältigen Dienstleistungen',
  homeremind19: 'IEO-Abonnement',
  homeremind20: "Die besten Kryptowährungen im Vorverkauf",
  homeremind21: 'VIP-Kundendienst',
  homeremind22: '7*24 Stunden',
  homeremind23: 'Gemeinschaft',
  homeremind24: 'Entdecken Sie hier bei uns Vermögenschancen',
  homeremind25: 'Handeln Sie unterwegs. Irgendwo irgendwann.',
  homeremind26: 'Bleiben Sie mit unserer App und unserem Desktop-Client auf dem Laufenden.',
  homeremind27: 'Folgen Sie uns in den sozialen Medien',
  homeremind28: 'Bleiben Sie mit unseren neuesten Nachrichten auf dem Laufenden',
  homeremind29: 'Bleiben Sie im Handel. Irgendwo irgendwann.',
  homeremind30: 'Bleiben Sie mit unserer mobilen APP und unserem Website-Terminal auf dem Laufenden.',

  msb: 'NYECoins ist in MSB, USA, reguliert.',
  msb1: 'Vertrauenswürdig, sicher, schnell',
  checkNow: 'Jetzt prüfen',

  foundingTeam: 'Gründerteam',
  advisorTeam: 'Beraterteam',
  community: 'Gemeinschaft',

  phone: 'Telefonnummer',
  phoneTip: 'Geben Sie Ihre Telefonnummer ein',


  rebateAccount: 'Provisionskonto',
  rebateType: 'Typ',
  recommend: 'Verweisung',
  myRebate: 'meine Provision',
  rebateRule: 'Regeln der Kommission',
  yes: 'Ja',
  no: 'NEIN',
  authName: 'Verifizieren',
  countryName: 'Ländliche Gegend',
  allCommission: 'Gesamtprovision',
  myClient: 'Meine Kunden',
  inviteUrl1: 'Empfehlungslink',
  inviteCode1: 'Referenzcode',
  commissioned: 'Kommission',

  swiperTitle2: 'Der Weltmarktführer im Online-Kryptohandel. Kryptowährungen kaufen, verkaufen und halten.',
  swiperSub2: 'Wir haben Millionen von Benutzern weltweit auf ihrer Reise zu digitalen Assets begleitet.',

  homeremind31: 'Was machen wir?',
  homeremind32: 'Wir wollen die Grenze zwischen Kryptowährungshändlern und normalen Verbrauchern verwischen. Wir werden die Kryptowährung populär machen, indem wir freien Eintritt gewähren und den Menschen helfen, zu verstehen und ihnen anzuvertrauen, was unserer Meinung nach die Zukunft des Geldes ist.',
  homeremind33: 'Merkmale von NYECOINS',
  homeremind34: 'NYECOINS bringt Anbieter und Käufer ohne eine Zentralbank oder einen einzigen Administrator zusammen, um klare und faire Geschäfte zu gewährleisten',
  homeremind35: 'Niedrigere Transaktionskosten',
  homeremind36: 'Eine dezentrale Plattform, die Kunden mit geringeren Transaktionszahlungen und Betriebskosten zur Verfügung steht.',
  homeremind37: 'C2C-Transaktionen',
  homeremind38: 'C2C-Operationen sind mit NYECOINS einfach, schnell und sicher. Senden Sie BTC oder eine Währung Ihrer Wahl an jeden Kunden auf der Plattform.',
  homeremind39: 'Premium-Toolkit für Marken',
  homeremind40: 'Wir haben das Verbraucher-Targeting optimiert, indem wir Unternehmen die Tools und Erkenntnisse an die Hand gegeben haben, mit denen sie ihre Kunden kennen.',
  homeremind41: 'Unterstützt von globalen Partnern',
  homeremind42: 'NYECOINS-Team',
  homeremind43: 'Wir stellen Ihnen das Team vor, das alles möglich macht.',
  homeremind44: 'Mark Nichols',
  homeremind45: 'Mitbegründerin',
  homeremind46: 'Edward Blankenship',
  homeremind47: 'Senior-Software-Entwickler',
  homeremind48: 'Richard Norman',
  homeremind49: 'Community Manager',
  homeremind50: 'Paul Garrett',
  homeremind51: 'UI/UX-Designer',


  ieoIntro1: 'Liquiditätsanreize',
  ieoIntro2: 'Einsatzreserve',
  ieoIntro3: 'Ökosystempartner',
  ieoIntro4: 'Öffentlicher Verkauf',
  ieoIntro5: 'Privatverkauf A',
  ieoIntro6: 'Samenrunde',
  ieoIntro7: 'LinkToJoin-Team',
  ieoIntro8: 'Enterprise-Gebührenreserve',
}