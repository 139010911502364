export default {
  required: 'Necessaria',
  home: 'Inizio',
  market: 'Mercati',
  currency: 'Commercio',
  walletTrade: 'Punto',
  contract: 'Contrarre',
  wallet: 'Portafogli',
  pulling: 'Tirare verso il basso per aggiornare...',
  loosing: 'Rilascia per aggiornare...',
  loading: 'Caricamento in corso...',
  loadingError: 'Caricamento fallito',
  nothing: 'Nessun dato',
  finished: '',
  price: 'Prezzo',
  number: 'Quantità',
  recharge: 'Depositare',
  transfer: 'Trasferimento',
  invite: 'Invitare',
  inviteDes: 'Invita gli amici a unirsi a noi',
  contact: 'Contattaci',
  contactDes: '7*24 in linea',
  fastTrade: 'Opzioni',
  name: 'Nome',
  fastPrice: 'Prezzo',
  trend: '24H %',
  all: 'Tutte',
  growthList: 'Vincitrici',
  loserList: 'Perdenti',
  vol: '24H Vol',
  buy: 'Acquistare',
  sell: 'Vendere',
  optimal: 'Prezzo di mercato',
  marketOrder: 'Commercio di mercato',
  limitOrder: 'Limitare il commercio',
  useable: 'Disponibile',
  tradeVol: 'Quantità',
  entrust: 'Ordine limite',
  entrustTotal: 'Quantità',
  noLogin: 'Disconnettersi, ',
  toLogin: 'Accedi ora',
  cancelOrder: 'Annulla',
  cancelError: 'Impossibile annullare',
  cancelSuccess: 'Annulla con successo',
  cancelTip: 'Sicuro di annullare？',
  completed: 'Completato',
  canceled: 'Annullata',
  marketPrice: 'Mercata',
  limitPrice: 'Limite',
  entrustPrice: 'Prezzo',
  entrustNum: 'Quantità',
  currencyType: 'Simbolo',
  headerCurrencyType: 'Simbolo',
  currencyOrder: 'Registro commerciale',
  history: 'Storia',
  tryAgain: 'Riprova',
  entrustPriceTip: 'Prezzo di ingresso',
  tradeNumTip: 'Quantità di input',
  tradeNumLess: 'La quantità deve essere maggiore di 0',
  numLessTip: 'Equilibrio insufficiente',
  tradePriceTip: 'Quantità di input',
  balanceTip: 'Equilibrio insufficiente',
  tradeError: 'Scambio fallito',
  tradeSuccess: 'Fai trading con successo',
  tip: 'Avviso',
  tradeConfirm: 'Conferma per fare trading？',
  cancelText: 'Annulla',
  confirmText: 'Confermare',
  toBuy: 'Comprare',
  toSell: 'Vendere allo scoperto',
  confirmToBuy: 'Comprare',
  confirmToSell: 'Vendere allo scoperto',
  slPrice: 'SL Prezzo',
  sl: 'SL',
  tp: 'TP',
  tpPrice: 'TP Prezzo',
  currentOrder: 'Ordine attuale',
  historyOrder: 'Storia',
  setting: 'Impostare',
  unwind: 'Vicina',
  handUnwind: 'Chiudi manualmente',
  futuresPay: '',
  forceUnwind: 'Liquidazione',
  slTp: 'SL/TP',
  fastTradeUnwind: 'Opzioni',
  blowUp: 'Liquidazione',
  openNum: 'Quantità',
  warrant: 'Margine',
  openPrice: 'Prezzo',
  fee: 'Tassa',
  profit: 'Profitto',
  lastPrice: 'Prezzo attuale',
  unwindPrice: 'Prezzo chiuso',
  unwindError: 'Impossibile chiudere',
  unwindSuccess: 'Chiudi con successo',
  setSuccess: 'Impostato con successo',
  setError: 'Impossibile impostare',
  setSlTp: 'Impostare SL/TP',
  second: 's',
  multiple: 'Molteplici',
  direction: 'Tipa',
  tradeCurrency: 'Simbolo',
  loss: 'Perdita',
  fill: 'Vincita',
  executePrice: 'Prezzo',
  currentPrice: 'Prezzo attuale',
  closePrice: 'Prezzo chiuso',
  secondNum: 'Periodo',
  contractOrder: 'Ordini futures',
  fastTradeOrder: 'Opzioni Ordini',
  overview: 'Panoramica',
  ieoBuy: 'IEO',
  balanceTotal: 'Completo vantaggio',
  withdraw: 'ritirare',
  tradeOrder: 'Ordine commerciale',
  rechargeHistory: 'Registro di deposito',
  withdrawHistory: 'Ritira record',
  freeze: 'Congelato',
  amountTo: '',
  tipText: 'Avviso',
  ieoTip: 'IEO non è aperto ora, riprova più tardi',
  financeList: 'Record finanziario',
  currencyAccount: 'Conto Spot',
  contractAccount: 'Conto futures',
  time: 'Tempo',
  status: 'Stato',
  balanceTyle: 'Account',
  pay: 'Risultato',
  income: 'Reddito',
  lastBalance: 'Equilibrio',
  orderNo: 'ID',
  money: 'Quantità',
  date: 'Data',
  finallyIncome: 'Ricevi importo',
  commitMoney: 'Amount',
  orderNum: 'ID',
  waitReview: 'Revisione',
  reviewPass: 'Passato',
  reviewReject: 'Respinto',
  waitWallet: 'Revisione',
  walletPass: 'Passato',
  walletReject: 'Respinto',
  withdrawSuccess: 'Ritiro con successo',
  withdrawError: 'Impossibile trasferire',
  high24H: '24H Alto',
  low24H: '24H Basso',
  num24H: '24H Vol',
  minute: 'Minuti',
  hour: 'Ore',
  day: 'Giorno',
  week: 'Settimana',
  markets: 'Mercata',
  open: 'Aprire',
  close: 'Vicina',
  high: 'Alto',
  low: 'Basso',
  volume: 'Vol',
  fastLogin: 'Accedi ora',
  register: 'Registrati',
  auth: 'Verificare',
  authSuccess: 'Verificato',
  noPass: 'Respinto',
  authCommit: 'Revisione',
  onAuth: 'Non verificato',
  onLogin: 'Disconnettersi',
  securitySet: 'Sicurezza',
  userAgree: 'Supporto utenti',
  aboutUs: 'Chi siamo',
  logout: 'Disconnettersi',
  logoutSuccess: 'Esci con successo',
  lookAuthInfo: 'Controlla le mie informazioni',
  authAgain: 'Ri-verificare',
  authInfo: 'Informazione',
  account: 'Account',
  countryArea: 'Paese/Regione',
  idNo: 'ID#',
  idNoTip: 'Immettere il numero ID',
  email: 'E-mail',
  emailInput: 'Inserisci l\'indirizzo e-mail',
  setLoginPwd: 'Imposta la password di accesso',
  loginPwdInput: 'Immettere la password di accesso (8-20 caratteri)',
  loginPwdTip: 'Immettere la password di accesso',
  loginPwdLen: 'La password di accesso deve contenere da 8 a 20 caratteri',
  setFinancePwd: 'Imposta la password del fondo',
  financePwdInput: 'Inserisci la password del fondo (6-20 caratteri)',
  financePwdTip: 'Inserire la password del fondo',
  financePwdLen: 'La password del fondo deve contenere da 6 a 20 caratteri',
  inviteCode: 'Codice invito',
  inviteCodeTip: 'Inserisci il codice di invito',
  inviteInput: 'È richiesto il codice di invito',
  imageCode: 'Captcha',
  imageCodeInput: 'Inserisci il captcha',
  registerTip: 'Registrati significa che sei d\'accordo ',
  agree: '《Accordo per gli utenti》',
  agreeText: 'Accordo per gli utenti',
  next: 'Prossima',
  registerSuccess: 'Registrati Completamente',
  registerError: 'Impossibile registrarsi',
  securityCheck: 'Sicurezza',
  checkCodeInput: 'Immettere il codice di verifica',
  sendCheckCode: 'Inviare',
  sendSuccess: 'Spedito',
  sendError: 'Impossibile inviare',
  welcome: 'Benvenuto a',
  loginEmail: 'E-mail',
  loginEmailInput: 'Inserisci l\'indirizzo e-mail',
  loginPwd: 'Password per il login',
  login: 'Login',
  forgetPwd: 'Ha dimenticato la password',
  loginTip: 'Login significa che sei d\'accordo',
  loginSuccess: 'Accedi completamente',
  loginError: 'Impossibile accedere',
  fullname: 'Nome',
  fullnameTip: 'Inserisci il tuo nome',
  country: 'Paese/Regione',
  countryTip: 'Immettere il paese/regione',
  imgFront: 'Carica una foto della parte anteriore del tuo documento d\'identità',
  imgReverse: 'Carica una foto del retro del tuo documento d\'identità',
  commit: 'Invia',
  commitSuccess: 'Invia completamente',
  commitError: 'Impossibile inviare',
  selectCurrency: 'Scegli la criptovaluta',
  rechargeNet: 'Rete',
  rechargeAddress: 'Indirizzo di deposito',
  copyAddress: 'Copia indirizzo',
  minRecharge: 'Importo minimo del deposito',
  copySuccess: 'Copia completamente',
  copyError: 'Impossibile copiare',
  withdrawTip: 'Puoi prelevare dopo aver verificato il tuo account',
  withdrawAddress: 'Indirizzo di ritiro',
  withdrawAddressInput: 'Inserisci l\'indirizzo di ritiro',
  withdrawNet: 'Rete',
  withdrawNum: 'Quantità',
  withdrawNumInput: 'Quantità di input',
  withdrawNumLess: 'Equilibrio insufficiente',
  withdrawMin: 'Importo minimo di prelievo',
  balanceUse: 'Disponibile',
  actualMoney: 'Ricevi importo',
  actualMoneyText: 'Ricevi importo',
  financePwd: 'Parola d\'ordine del fondo',
  trade: '',
  withdrawFail: 'Impossibile ritirarsi',
  commitTime: 'Data',
  from: 'A partire dal',
  to: 'A',
  transferMoney: 'Quantità di input',
  transferSuccess: 'Trasferisci completamente',
  transferError: 'Impossibile trasferire',
  transferHistory: 'Registro di trasferimento',
  updateFinancePwd: 'Cambia la password del fondo',
  confirmPwd: 'Conferma password',
  confirmPwdInput: 'Immettere la password di conferma',
  updatePwd: 'Cambia la password',
  updatePwdSuccess: 'Cambia completamente',
  updatePwdError: 'Impossibile modificare',
  noAlike: 'La password è incoerente',
  emailUpdate: 'Modifica indirizzo e-mail',
  loginPwdUpdate: 'Modifica password di accesso',
  financePwdUpdate: 'Cambia la password del fondo',
  newEmail: 'Nuovo Indirizzo Email',
  emailUpdateTip: 'Inserisci un nuovo indirizzo email',
  checkEmail: 'Verifica indirizzo e-mail',
  updateEmail: 'Modifica indirizzo e-mail',
  updateEmailError: 'Impossibile modificare',
  updateEmailSuccess: 'Cambia completamente',
  checkError: 'Impossibile verificare',
  pwdLen: '8-20 caratteri',
  inviteUrl: 'Link di riferimento',
  myInviteCode: 'Codice invito',
  fastInvite: 'Invita ora',
  myInvite: 'Elenco degli inviti',
  registerTime: 'Data',
  bulletin: 'Annuncio',
  inviteFriend: 'Invita gli amici',
  saveAccount: 'Salva account e password',
  indicator: 'Indicatori',
  type: 'Tipi di',
  amount: 'Quantità',
  scanError: 'Riconoscimento dell\'immagine non riuscito',
  scanInitErr: 'Inizializzazione fallita',
  noCamera: 'Nessuna autorizzazione della fotocamera',
  secondTip: 'L\'importo non deve essere inferiore a {tip}',
  completePrice: 'Prezzo',
  profitText: 'Profitto',
  profitAndLoss: 'Profitti e perdite stimati',
  timeUp: 'Tempo scaduto',
  balanceText: 'Storia',
  closeAll: 'Chiudi tutto',
  assetRate: 'Tasso di margine',
  cancel: 'Annulla',
  cancelInfo: 'Confermi per annullare?',
  buyCurrency: 'Simbolo',
  closeCurrency: 'Valuta',
  buyPrice: 'Prezzo',
  valuation: 'Valutazione',
  profitP: 'Prodotto',
  buySuccess: 'Con successo',
  swiperTitle1: 'Il miglior scambio di criptovalute digitale al mondo',
  swiperSub1: 'Scelto da più di 100.000 utenti',

  ieoBuying: 'Incorsa',
  ieoOpen: 'Data d\'inizio (UTC-5)',
  ieoClose: 'Data di fine(UTC-5)',
  about: 'Di',
  ieoPlan: 'Distribuzione',
  ieoInfo: 'Informazione',
  ieoContact: 'Social media',
  ieoStart: 'Inizializzazione',
  ieoBuyStart: 'IEO Inizio',
  ieoBuyEnd: 'IEO Fine',
  ieoIsTrade: 'Lanciare',
  introduce: 'introduzione',
  ieoSubBuy: 'IEO',
  subBuyHistory: 'IEO Storia',
  rechargeTip1: '1. Questo è il tuo indirizzo più recente e univoco, la piattaforma non ti chiederà mai di trasferire fondi su un altro conto o di richiedere il tuo codice di verifica.',
  rechargeTip2: '2. Il deposito richiede almeno 10 blocchi di rete per la conferma. Dopo la conferma, il tuo account riceverà automaticamente i fondi.',
  withdrawTip1: '1. Assicurati di selezionare la stessa rete per questo prelievo come quella sul lato del deposito e verifica che la piattaforma di ricezione supporti la rete. Altrimenti, perderai i tuoi beni.',
  withdrawTip2: '2. I blocchi di rete confermeranno il tuo prelievo dopo che la piattaforma avrà approvato la tua richiesta.',
  withdrawTip3: '3. Il ritiro richiede almeno 10 blocchi di rete per la conferma. Dopo la conferma, il tuo account riceverà automaticamente i fondi.',
  coming: 'Arriverà presto.',
  createTeam: 'Fondo di riserva',
  angelFund: 'Fondo Angelo',
  ecology: 'Istituzioni ed Ecosistemi',
  presale: 'Rilascio in prevendita',
  mechanism: 'Meccanismo minerario',
  trading: 'Commercio di borsa',

  issuePrice: 'Prezzo di Emissione',
  minBuyPrice: 'Abbonamento Minimo',
  buyNum: 'Abbonamenti al Giorno',
  buyTotal: 'Abbonamento Giornaliero Totale',
  Increment: 'Incremento di Sottoscrizione',
  blockchain: 'Blockchain',

  sellTotal: 'Emissione Totale',
  currentBuy: 'Abbonamento in Corso',
  lessMinTip: 'Non può essere inferiore all\'importo minimo.',
  stakeAccount: 'Conto puntato',
  serviceAccount: 'Conto di servizio',
  lock: 'Serratura',
  lockPrice: 'Prezzo bloccato',
  ieoHistory: 'Storia dell\'IEO',
  lockHistory: 'Blocca cronologia',
  sellHistory: 'Vendi Storia',
  stake: 'Picchettamento',
  service: 'Servizio',
  ieoBuyTip: "*Avviso IEO: il numero di LTJ è limitato. Esamineremo l'applicazione IEO dell'utente e sorteggeremo a sorte ogni 3 ore. Gli utenti con un importo relativamente elevato avranno una maggiore probabilità di acquistare LTJ. Ogni utente può acquistare fino a 3 volte al giorno.",
  ieoLockTip: '*Blocca prezzo: gli utenti devono utilizzare USDT come valuta di impegno per bloccare il prezzo del LTJ e il rapporto di impegno è pari al 30% del valore del LTJ. Dopo il lancio di LTJ, gli utenti possono vendere LTJ al prezzo bloccato in quel momento.',
  ieoSellTip: '*Vendita IEO: la vendita di LTJ richiede il 10% del valore del LTJ come commissione di servizio. Dopo aver venduto LTJ, l\'USDT che hai scambiato sarà sbloccato in 20 giorni e l\'USDT promesso sarà sbloccato in 20 giorni.',
  whitePaper: 'CARTA BIANCA',
  cost: 'Costo',
  currentEstimate: 'Valutazione attuale',
  stakeNum: 'Importo della puntata',
  estimate: 'Valutazione',
  consume: 'Consumare',

  seedSell: 'Seme rotondo',
  privateSell: 'Giro privato',
  openSell: 'COL Rotondo',
  ieoAward: 'Giro pubblico',
  ieoGrowUp: 'Squadra',
  partner: 'Consiglieri',
  consultant: 'Marketing',
  ieoName: 'Liquidità',
  conAward: 'AI per guadagnare',
  staking: 'Picchettamento',

  release: 'Pubblicazione',
  closedTime: 'Orario Chiuso',
  remark: 'Nota',

  totalProfit: 'Profitto totale',
  transferIn: 'Trasferimento in entrata',
  transferOut: 'Trasferire fuori',
  equity: 'patrimonio netto',
  
  homeremind1: 'Criptovalute popolari',
  homeremind2: 'Visualizza più mercati',
  homeremind3: 'Ultimo prezzo(USD)',
  homeremind4: 'Cambio 24 ore',
  homeremind5: 'Azione',
  homeremind6: 'Visualizza altro',
  homeremind7: "Le migliori scelte degli utenti",
  homeremind8: 'Volume degli scambi nelle 24 ore',
  homeremind9: 'Risorse virtuali Prime',
  homeremind10: 'I nostri prodotti principali',
  homeremind11: 'Un’ampia varietà di strumenti di trading tra cui scegliere',
  homeremind12: 'Macchiare',
  homeremind13: 'Oltre 700 criptovalute disponibili per il trading',
  homeremind14: 'Migliora la tua esperienza scambiando futures',
  homeremind15: 'NYECoins Guadagna',
  homeremind16: 'I migliori APY del settore',
  homeremind17: 'I nostri servizi diversificati',
  homeremind18: 'Esplora possibilità illimitate con i nostri diversi servizi',
  homeremind19: 'Abbonamento IEO',
  homeremind20: "Le migliori criptovalute in prevendita",
  homeremind21: 'Servizi clienti VIP',
  homeremind22: '7*24 ore',
  homeremind23: 'Comunità',
  homeremind24: 'Scopri opportunità di ricchezza qui con noi',
  homeremind25: 'Fai trading in movimento. Ovunque sempre.',
  homeremind26: 'Rimani aggiornato con la nostra app e il client desktop.',
  homeremind27: 'Seguici sui social media',
  homeremind28: 'Rimani aggiornato con le nostre ultime novità',
  homeremind29: 'Rimani nel trading. Ovunque sempre.',
  homeremind30: 'Rimani aggiornato con la nostra APP mobile e il terminale del sito web.',

  msb: 'NYECoins è regolamentato in MSB, USA.',
  msb1: 'Affidabile, sicuro, veloce',
  checkNow: 'Controlla ora',

  foundingTeam: 'Squadra Fondatrice',
  advisorTeam: 'Gruppo di consulenti',
  community: 'Comunità',

  phone: 'Numero di telefono',
  phoneTip: 'Inserisci il tuo numero di telefono',


  rebateAccount: 'conto delle commissioni',
  rebateType: 'tipo',
  recommend: 'Rinvio',
  myRebate: 'la mia commissione',
  rebateRule: 'Regole della Commissione',
  yes: 'SÌ',
  no: 'NO',
  authName: 'Verificare',
  countryName: 'Paese/zona',
  allCommission: 'Commissione totale',
  myClient: 'I miei clienti',
  inviteUrl1: 'Link di riferimento',
  inviteCode1: 'Codice di riferimento',
  commissioned: 'commissione',

  swiperTitle2: 'Il leader globale nel trading di criptovalute online. Acquista, vendi e mantieni criptovalute.',
  swiperSub2: 'Abbiamo guidato milioni di utenti globali nel loro percorso verso le risorse digitali.',

  homeremind31: 'Cosa facciamo?',
  homeremind32: 'Vogliamo offuscare il confine tra i commercianti di criptovaluta e i consumatori ordinari. Renderemo popolare la criptovaluta offrendo l\'ingresso gratuito e aiutando le persone a comprendere e confidare quello che crediamo essere il futuro del denaro.',
  homeremind33: 'Caratteristiche di NYECOINS',
  homeremind34: 'NYECOINS riunisce venditori e acquirenti senza una banca centrale o un unico amministratore per garantire accordi chiari ed equi',
  homeremind35: 'Costi di transazione inferiori',
  homeremind36: 'Una piattaforma decentralizzata a disposizione dei clienti con pagamenti di transazione e spese operative inferiori.',
  homeremind37: 'Transazioni C2C',
  homeremind38: 'Le operazioni C2C sono facili, veloci e sicure con NYECOINS. Invia BTC o la valuta che preferisci a qualsiasi cliente sulla piattaforma.',
  homeremind39: 'Kit di strumenti premium per i marchi',
  homeremind40: 'Abbiamo ottimizzato il targeting dei consumatori fornendo alle aziende gli strumenti e le informazioni per conoscere i propri clienti.',
  homeremind41: 'Supportato da partner globali',
  homeremind42: 'Squadra NYECOINS',
  homeremind43: 'Lascia che ti presentiamo il team che realizzerà tutto questo.',
  homeremind44: 'Mark Nichols',
  homeremind45: 'Cofondatore',
  homeremind46: 'Edward Blankenship',
  homeremind47: 'Senior Software Engineer',
  homeremind48: 'Richard Norman',
  homeremind49: 'Direttore della comunità',
  homeremind50: 'Paul Garrett',
  homeremind51: 'Progettista dell\'interfaccia utente/UX',


  ieoIntro1: 'Incentivi alla liquidità',
  ieoIntro2: 'Riserva di puntata',
  ieoIntro3: "Partner dell'ecosistema",
  ieoIntro4: 'Vendita pubblica',
  ieoIntro5: 'Vendita privata A',
  ieoIntro6: 'Seme rotondo',
  ieoIntro7: 'Squadra LinkToJoin',
  ieoIntro8: 'Riserva tariffa aziendale',
}