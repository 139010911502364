import "core-js/modules/es.array.push.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderSlot as _renderSlot, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-3bde507a"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "custom-tab-c"
};
const _hoisted_2 = {
  class: "ctab-c-header"
};
const _hoisted_3 = {
  style: {
    "display": "flex"
  }
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  style: {
    "margin-left": "3px",
    "font-size": "13.5px"
  }
};
import store from '@/store';
import { ref, watch } from 'vue';
import { useI18n } from "vue-i18n";
export default {
  __name: 'CustomTab',
  props: {
    tabList: {
      type: Array
    },
    allShow: {
      type: Boolean,
      default: true
    },
    offsetTop: {
      type: Number,
      default: 46
    }
  },
  emits: ['allClick', 'change'],

  setup(__props, {
    emit
  }) {
    const props = __props;
    const {
      t
    } = useI18n();
    const tabActive = ref(0);
    watch(() => tabActive.value, () => {
      emit('change', tabActive.value);
      store.state.tabIndex = tabActive.value;
    });

    const getTabItemClass = index => {
      const itemClass = ['ctab-c-item'];

      if (tabActive.value === index) {
        itemClass.push('ctab-active-item');
      }

      if (index + 1 < props.tabList.length) {
        itemClass.push('ctab-c-right');
      }

      return itemClass;
    };

    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");

      const _component_van_sticky = _resolveComponent("van-sticky");

      const _component_van_tab = _resolveComponent("van-tab");

      const _component_van_tabs = _resolveComponent("van-tabs");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_sticky, {
        "offset-top": props.offsetTop
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.tabList, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(getTabItemClass(index)),
            onClick: $event => tabActive.value = index
          }, _toDisplayString(item), 11, _hoisted_4);
        }), 128))]), props.allShow ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          style: {
            "display": "flex",
            "align-items": "center"
          },
          onClick: _cache[0] || (_cache[0] = $event => emit('allClick'))
        }, [_createVNode(_component_van_icon, {
          name: "apps-o",
          style: {
            "color": "#919497"
          },
          size: "18px"
        }), _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(t)('all')), 1)])) : _createCommentVNode("", true)])]),
        _: 1
      }, 8, ["offset-top"]), _createVNode(_component_van_tabs, {
        class: "tab-c-content",
        active: tabActive.value,
        "onUpdate:active": _cache[1] || (_cache[1] = $event => tabActive.value = $event)
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.tabList.length, index => {
          return _openBlock(), _createBlock(_component_van_tab, {
            key: index
          }, {
            default: _withCtx(() => [_renderSlot(_ctx.$slots, `tab${index}`)]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 3
      }, 8, ["active"])]);
    };
  }

};