import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-696f1fcb"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "check-popup"
};
const _hoisted_2 = {
  class: "header"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  class: "content"
};
const _hoisted_5 = {
  class: "email"
};
const _hoisted_6 = {
  class: "btn-area"
};
import { ref } from 'vue';
import { Toast } from 'vant';
import 'vant/es/toast/style';
import { sendEmailVerify } from '@/apis';
import { useI18n } from "vue-i18n";
export default {
  __name: 'CheckPopup',
  props: {
    checkShow: {
      type: Boolean,
      default: false
    },
    email: {
      type: String
    },
    nextRouter: {
      type: String
    },
    captchaId: {
      type: String,
      default: ''
    },
    captcha: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    nextLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['hide', 'next'],

  setup(__props, {
    emit
  }) {
    const props = __props;
    const {
      t
    } = useI18n();
    const checkCode = ref('');
    const countDown = ref(null);
    const countTime = ref(false);
    const sendLoading = ref(false);
    const time = ref(1000 * 60);
    let checkKey = '';

    const sendCheckCode = async () => {
      if (props.nextLoading) {
        return;
      }

      sendLoading.value = true;
      const res = await sendEmailVerify({
        captcha_id: props.captchaId,
        captcha_code: props.captcha,
        email: props.email
      });
      sendLoading.value = false;

      if (res.code === 200) {
        Toast({
          message: t('sendSuccess'),
          position: 'bottom'
        });
        checkKey = res.data.key;
        countDown.value.start();
        countTime.value = true;
        return;
      }

      Toast({
        message: t('sendError'),
        position: 'bottom'
      });
    };

    const finish = () => {
      countTime.value = false;
      countDown.value.reset();
    };

    const next = () => {
      emit('next', {
        checkKey: checkKey,
        checkCode: checkCode.value
      });
      countTime.value = false;
      countDown.value.reset();
    };

    return (_ctx, _cache) => {
      const _component_van_count_down = _resolveComponent("van-count-down");

      const _component_van_loading = _resolveComponent("van-loading");

      const _component_a_input = _resolveComponent("a-input");

      const _component_van_button = _resolveComponent("van-button");

      const _component_van_popup = _resolveComponent("van-popup");

      return _openBlock(), _createBlock(_component_van_popup, {
        show: props.checkShow,
        position: "bottom",
        round: "",
        onClickOverlay: _cache[2] || (_cache[2] = $event => emit('hide'))
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_unref(t)('securityCheck')), 1), _createElementVNode("div", {
          class: "close",
          onClick: _cache[0] || (_cache[0] = $event => emit('hide'))
        }, _toDisplayString(_unref(t)('cancelText')), 1)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(props.email), 1), _createVNode(_component_a_input, {
          class: "check-input",
          value: checkCode.value,
          "onUpdate:value": _cache[1] || (_cache[1] = $event => checkCode.value = $event),
          placeholder: _unref(t)('checkCodeInput'),
          bordered: false
        }, {
          suffix: _withCtx(() => [_withDirectives(_createElementVNode("span", {
            onClick: sendCheckCode,
            style: _normalizeStyle({
              color: props.nextLoading ? '#969799' : 'rgb(171,84,219)'
            })
          }, _toDisplayString(_unref(t)('sendCheckCode')), 5), [[_vShow, !countTime.value && !sendLoading.value]]), _withDirectives(_createVNode(_component_van_count_down, {
            ref_key: "countDown",
            ref: countDown,
            time: time.value,
            format: "ss",
            "auto-start": false,
            onFinish: finish,
            style: {
              "--van-count-down-text-color": "#969799"
            }
          }, null, 8, ["time"]), [[_vShow, countTime.value && !sendLoading.value]]), _withDirectives(_createVNode(_component_van_loading, {
            color: "rgb(171,84,219)",
            size: "18"
          }, null, 512), [[_vShow, sendLoading.value]])]),
          _: 1
        }, 8, ["value", "placeholder"])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_van_button, {
          color: "#007AFC",
          block: "",
          onClick: next,
          loading: props.nextLoading,
          disabled: checkCode.value === '',
          style: {
            "--van-button-default-height": "42px",
            "--van-button-border-radius": "4px"
          }
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(props.text), 1)]),
          _: 1
        }, 8, ["loading", "disabled"])])])]),
        _: 1
      }, 8, ["show"]);
    };
  }

};