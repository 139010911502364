export const exType = {
  ex: 'ex',
  contract: 'contract',
  second: 'second'
}

export const orderStatus = {
  cancel: 0,
  entrust: 1,
  complete: 2
}

export const orderSide = {
  sell: 1,
  buy: 2
}

export const orderType = {
  limit: 1, // 限价
  market: 2 // 市价
}

export const contractType = {
  cancel: 0,
  entrust: 1,
  open: 2,
  flat: 3,
}