import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-41c87455"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "second-item"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = {
  class: "header"
};
const _hoisted_4 = {
  class: "title"
};
const _hoisted_5 = {
  class: "multiple"
};
const _hoisted_6 = {
  key: 0,
  style: {
    "color": "#04AC92",
    "font-size": "12px"
  }
};
const _hoisted_7 = {
  key: 1,
  style: {
    "color": "#969799",
    "font-size": "12px"
  }
};
const _hoisted_8 = {
  key: 2,
  style: {
    "color": "#D04C64",
    "font-size": "12px"
  }
};
const _hoisted_9 = {
  key: 1,
  style: {
    "padding": "0 6px",
    "border": "1px solid rgb(171,84,219)",
    "border-radius": "4px"
  }
};
const _hoisted_10 = {
  class: "info"
};
const _hoisted_11 = {
  style: {
    "display": "flex"
  }
};
const _hoisted_12 = {
  key: 0,
  class: "tag",
  style: {
    "--color": "#04AC92",
    "--bColor": "#E4F7F1"
  }
};
const _hoisted_13 = {
  key: 1,
  class: "tag",
  style: {
    "--color": "#D04C64",
    "--bColor": "#FBF3F1"
  }
};
const _hoisted_14 = {
  class: "date"
};
const _hoisted_15 = {
  style: {
    "font-size": "12px",
    "color": "gray"
  }
};
const _hoisted_16 = {
  class: "label"
};
const _hoisted_17 = {
  class: "num"
};
const _hoisted_18 = {
  class: "label"
};
const _hoisted_19 = {
  class: "num"
};
const _hoisted_20 = {
  class: "label"
};
const _hoisted_21 = {
  class: "num"
};
const _hoisted_22 = {
  class: "label"
};
const _hoisted_23 = {
  class: "num"
};
const _hoisted_24 = {
  class: "label"
};
const _hoisted_25 = {
  class: "num"
};
import { ref, onMounted } from 'vue';
import store from '@/store';
import { useI18n } from "vue-i18n";
import { fmtNumber } from '@/util';
export default {
  __name: 'SecondItem',
  props: {
    id: {
      type: Number
    },
    current: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      default: '--'
    },
    multiple: {
      type: Number
    },
    open: {
      type: String,
      default: '--'
    },
    last: {
      type: String,
      default: '--'
    },
    second: {
      type: Number,
      default: 0
    },
    amount: {
      type: String,
      default: '--'
    },
    profit: {
      type: String,
      default: '1'
    },
    dateDiff: {
      type: Number,
      default: 0
    },
    time: {
      type: String,
      default: '--'
    },
    direction: {
      type: Number,
      default: 1
    },
    status: {
      type: Number,
      default: 3
    },
    idt: {
      type: String,
      default: '--'
    }
  },
  emits: ['finish'],

  setup(__props, {
    emit
  }) {
    const props = __props;
    const {
      t
    } = useI18n();
    const countShow = ref(false);
    const count = ref(0);
    onMounted(() => {
      if (props.dateDiff > 0) {
        count.value = Math.floor(props.dateDiff * 1000);
        countShow.value = true;
      }
    });

    const finish = () => {
      countShow.value = false;
      emit('finish', props.id);
    };

    return (_ctx, _cache) => {
      const _component_van_count_down = _resolveComponent("van-count-down");

      const _component_van_col = _resolveComponent("van-col");

      const _component_van_row = _resolveComponent("van-row");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createTextVNode(_toDisplayString(_unref(store).state.marketInfo[props.name]?.name), 1), _createElementVNode("span", _hoisted_5, _toDisplayString(props.multiple) + "X", 1)]), props.status === 3 || props.status === 1 ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [Number(props.profit) > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_unref(t)('fill')) + "：" + _toDisplayString(_unref(fmtNumber)(props.profit)), 1)) : Number(props.profit) === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_unref(fmtNumber)(props.profit)), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_unref(t)('loss')) + "：" + _toDisplayString(_unref(fmtNumber)(props.profit)), 1))], 64)) : countShow.value ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_van_count_down, {
        ref: "countDown",
        time: count.value,
        format: "ss",
        onFinish: finish,
        style: {
          "--van-count-down-font-size": "12px",
          "color": "rgb(171,84,219)"
        }
      }, null, 8, ["time"])])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [props.direction === 1 ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_unref(t)('toBuy')), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_unref(t)('toSell')), 1)), _createElementVNode("div", _hoisted_14, _toDisplayString(props.time), 1)]), _createElementVNode("div", _hoisted_15, _toDisplayString(props.idt), 1)]), _createVNode(_component_van_row, null, {
        default: _withCtx(() => [_createVNode(_component_van_col, {
          span: "7",
          class: "data-item"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_16, _toDisplayString(_unref(t)('executePrice')), 1), _createElementVNode("div", _hoisted_17, _toDisplayString(_unref(fmtNumber)(props.open)), 1)]),
          _: 1
        }), _createVNode(_component_van_col, {
          span: "7",
          class: "data-item",
          style: {
            "text-align": "center"
          }
        }, {
          default: _withCtx(() => [countShow.value ? (_openBlock(), _createElementBlock(_Fragment, {
            key: 0
          }, [_createElementVNode("div", _hoisted_18, _toDisplayString(_unref(t)('currentPrice')), 1), _createElementVNode("div", _hoisted_19, _toDisplayString(_unref(fmtNumber)(props.last)), 1)], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
            key: 1
          }, [_createElementVNode("div", _hoisted_20, _toDisplayString(_unref(t)('closePrice')), 1), _createElementVNode("div", _hoisted_21, _toDisplayString(_unref(fmtNumber)(props.last)), 1)], 64))]),
          _: 1
        }), _createVNode(_component_van_col, {
          span: "4",
          class: "data-item",
          style: {
            "text-align": "center"
          }
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_22, _toDisplayString(_unref(t)('secondNum')), 1), _createElementVNode("div", _hoisted_23, _toDisplayString(props.second) + "s", 1)]),
          _: 1
        }), _createVNode(_component_van_col, {
          span: "6",
          class: "data-item",
          style: {
            "text-align": "right"
          }
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_24, _toDisplayString(_unref(t)('number')), 1), _createElementVNode("div", _hoisted_25, _toDisplayString(_unref(fmtNumber)(props.amount)), 1)]),
          _: 1
        })]),
        _: 1
      })])]);
    };
  }

};