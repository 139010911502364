import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '../assets/ercode.png';

const _withScopeId = n => (_pushScopeId("data-v-a3c8e610"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "recommend-container"
};
const _hoisted_2 = {
  class: "box"
};
const _hoisted_3 = {
  class: "content"
};
const _hoisted_4 = {
  class: "item"
};
const _hoisted_5 = {
  class: "top"
};
const _hoisted_6 = {
  class: "bottom"
};
const _hoisted_7 = {
  class: "item",
  style: {
    "text-align": "center"
  }
};
const _hoisted_8 = {
  class: "top"
};
const _hoisted_9 = {
  class: "bottom",
  style: {
    "justify-content": "center"
  }
};
const _hoisted_10 = {
  class: "item",
  style: {
    "text-align": "right"
  }
};
const _hoisted_11 = {
  class: "top"
};
const _hoisted_12 = {
  class: "bottom",
  style: {
    "justify-content": "end"
  }
};
const _hoisted_13 = {
  class: "item",
  style: {
    "width": "100%",
    "margin-top": "10px"
  }
};
const _hoisted_14 = {
  class: "top"
};
const _hoisted_15 = {
  class: "bottom"
};
const _hoisted_16 = {
  class: "list"
};
const _hoisted_17 = {
  class: "list-box"
};
const _hoisted_18 = {
  class: "list-header"
};
const _hoisted_19 = {
  class: "title"
};
const _hoisted_20 = {
  class: "content"
};
const _hoisted_21 = {
  class: "label"
};
const _hoisted_22 = {
  class: "num"
};
const _hoisted_23 = {
  class: "label"
};
const _hoisted_24 = {
  class: "num"
};
const _hoisted_25 = {
  class: "label"
};
const _hoisted_26 = {
  class: "num"
};
const _hoisted_27 = {
  class: "time"
};
const _hoisted_28 = {
  class: "list-box"
};
const _hoisted_29 = {
  class: "list-header"
};
const _hoisted_30 = {
  class: "title"
};
const _hoisted_31 = {
  class: "label"
};
const _hoisted_32 = {
  class: "num"
};
const _hoisted_33 = {
  class: "label"
};
const _hoisted_34 = {
  class: "num"
};
const _hoisted_35 = {
  class: "label"
};
const _hoisted_36 = {
  class: "num"
};
const _hoisted_37 = {
  class: "time"
};
const _hoisted_38 = ["innerHTML"];
const _hoisted_39 = {
  style: {
    "display": "flex",
    "justify-content": "center",
    "align-items": "center",
    "padding": "20px 0"
  }
};
import { onMounted, ref } from 'vue'; //   import { useRoute, useRouter } from 'vue-router'
//   import store from '@/store'
//   import Clipboard from 'clipboard'
//   import { message } from 'ant-design-vue'

import { Toast } from 'vant';
import NavGradient from '@/components/NavGradient.vue';
import 'vant/es/toast/style';
import { CopyOutlined } from '@ant-design/icons-vue';
import { useI18n } from "vue-i18n";
import { accountStat, accountInvite, accountCommission, homeInfo } from '@/apis';
import store from '@/store';
import QrcodeVue from 'qrcode.vue';
import { fmtNumber } from '@/util';
export default {
  __name: 'MyRecommend',

  setup(__props) {
    const {
      t
    } = useI18n(); //   const router = useRouter()
    //   const route = useRoute()

    const totalCommission = ref(0);
    const clientCount = ref(0);
    const modalShow = ref(false);
    const activeKey = ref('1');
    const recommendList = ref([]);
    const recommendPage = ref(0);
    const recommendLoading = ref(false);
    const recommendFinished = ref(true);
    const recommendSearch = ref('');
    const commissionList = ref([]);
    const commissionPage = ref(0);
    const commissionLoading = ref(false);
    const commissionFinished = ref(true);
    const commissionSearch = ref('');
    const content = ref('');
    onMounted(() => {
      accountStat({
        stat: 'total_commission,client_count'
      }).then(res => {
        if (res.code === 200 && res.data) {
          totalCommission.value = res.data.total_commission;
          clientCount.value = res.data.client_count;
        }
      });
      recommendLoad();
      commissionLoad();
      contentLoad();
    });

    const copyText = value => {
      // let copyValue = text
      // const clipboard = new Clipboard('.copy', {
      //     text: () => copyValue
      // })
      // clipboard.on('success', () => {
      //     message.success(t('copySuccess'))
      //     clipboard.destroy()
      // })
      // clipboard.on('error', () => {
      //     message.success(t('copyError'))
      //     clipboard.destroy()
      // })
      var oInput = document.createElement("input"); //创建一个input标签

      oInput.value = value; //将要复制的值赋值给input

      document.body.appendChild(oInput); //在页面中插入

      oInput.select(); // 模拟鼠标选中

      document.execCommand("Copy"); // 执行浏览器复制命令（相当于ctrl+c）

      oInput.style.display = "none"; //只是用一下input标签的特性，实际并不需要显示，所以这里要隐藏掉

      Toast({
        message: t('copySuccess'),
        position: 'bottom'
      });
    };

    const recommendLoad = async () => {
      const page = recommendPage.value + 1;
      recommendLoading.value = true;
      const res = await accountInvite({
        page: page,
        email: recommendSearch.value
      });
      recommendLoading.value = false;

      if (res.code === 200 && res.data && res.data.data) {
        // const list = fmtListArray(res.data.list)
        recommendList.value = [...recommendList.value, ...res.data.data.list];

        if (recommendList.value.length >= res.data.data.count) {
          recommendFinished.value = true;
        }

        recommendPage.value += 1;
      }
    };

    const commissionLoad = async () => {
      const page = commissionPage.value + 1;
      commissionLoading.value = true;
      const res = await accountCommission({
        page: page,
        email: commissionSearch.value
      });
      commissionLoading.value = false;

      if (res.code === 200 && res.data && res.data.data) {
        // const list = fmtListArray(res.data.list)
        commissionList.value = [...commissionList.value, ...res.data.data.list];

        if (commissionList.value.length >= res.data.data.count) {
          commissionFinished.value = true;
        }

        commissionPage.value += 1;
      }
    };

    const contentLoad = async () => {
      const res = await homeInfo({
        type: 7
      });

      if (res.code === 200 && res.data) {
        content.value = res.data.article_content;
      }
    };

    return (_ctx, _cache) => {
      const _component_a_input_search = _resolveComponent("a-input-search");

      const _component_van_col = _resolveComponent("van-col");

      const _component_van_row = _resolveComponent("van-row");

      const _component_van_list = _resolveComponent("van-list");

      const _component_van_empty = _resolveComponent("van-empty");

      const _component_a_tab_pane = _resolveComponent("a-tab-pane");

      const _component_a_tabs = _resolveComponent("a-tabs");

      const _component_a_modal = _resolveComponent("a-modal");

      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(NavGradient, {
        title: _ctx.name ? _ctx.name : ''
      }, null, 8, ["title"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(_unref(t)('allCommission')), 1), _createElementVNode("div", _hoisted_6, _toDisplayString(totalCommission.value), 1)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString(_unref(t)('myClient')), 1), _createElementVNode("div", _hoisted_9, _toDisplayString(clientCount.value), 1)]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, _toDisplayString(_unref(t)('inviteCode1')), 1), _createElementVNode("div", _hoisted_12, [_createElementVNode("div", null, [_createTextVNode(_toDisplayString(_unref(store).state.inviteCode) + " ", 1), _createVNode(_unref(CopyOutlined), {
        style: {
          "cursor": "pointer",
          "margin-left": "5px",
          "font-size": "14px"
        },
        onClick: _cache[0] || (_cache[0] = $event => copyText(_unref(store).state.inviteCode))
      })])])]), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, _toDisplayString(_unref(t)('inviteUrl1')), 1), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", null, [_createTextVNode(_toDisplayString(_unref(store).state.inviteUrl) + " ", 1), _createVNode(_unref(CopyOutlined), {
        style: {
          "cursor": "pointer",
          "margin-left": "5px",
          "font-size": "14px"
        },
        onClick: _cache[1] || (_cache[1] = $event => copyText(_unref(store).state.inviteUrl))
      })]), _createElementVNode("img", {
        src: _imports_0,
        alt: "",
        style: {
          "width": "20px"
        },
        onClick: _cache[2] || (_cache[2] = $event => modalShow.value = true)
      })])])])]), _createElementVNode("div", _hoisted_16, [_createVNode(_component_a_tabs, {
        activeKey: activeKey.value,
        "onUpdate:activeKey": _cache[9] || (_cache[9] = $event => activeKey.value = $event),
        centered: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_a_tab_pane, {
          key: "1",
          tab: _unref(t)('recommend')
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_17, [recommendList.value.length > 0 ? (_openBlock(), _createBlock(_component_van_list, {
            key: 0,
            "finished-text": _unref(t)('finished'),
            "loading-text": _unref(t)('loading'),
            loading: recommendLoading.value,
            "onUpdate:loading": _cache[5] || (_cache[5] = $event => recommendLoading.value = $event),
            finished: recommendFinished.value,
            onLoad: recommendLoad
          }, {
            default: _withCtx(() => [_createVNode(_component_a_input_search, {
              value: recommendSearch.value,
              "onUpdate:value": _cache[3] || (_cache[3] = $event => recommendSearch.value = $event),
              placeholder: _unref(t)('emailInput'),
              "enter-button": "",
              onSearch: _cache[4] || (_cache[4] = $event => {
                recommendPage.value = 0;
                recommendList.value = [];
                recommendLoad();
              })
            }, null, 8, ["value", "placeholder"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(recommendList.value, (item, index) => {
              return _openBlock(), _createElementBlock("div", {
                class: "list-content",
                key: index
              }, [_createElementVNode("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, _toDisplayString(item.user_email), 1), _createElementVNode("div", _hoisted_20, _toDisplayString(_unref(t)('authName')) + "：" + _toDisplayString(item.user_auth == 3 ? _unref(t)('yes') : _unref(t)('no')), 1)]), _createVNode(_component_van_row, null, {
                default: _withCtx(() => [_createVNode(_component_van_col, {
                  span: "8",
                  class: "data-item",
                  style: {
                    "text-align": "center"
                  }
                }, {
                  default: _withCtx(() => [_createElementVNode("div", _hoisted_21, _toDisplayString(_unref(t)('name')), 1), _createElementVNode("div", _hoisted_22, _toDisplayString(item.user_auth_name), 1)]),
                  _: 2
                }, 1024), _createVNode(_component_van_col, {
                  span: "8",
                  class: "data-item",
                  style: {
                    "text-align": "center"
                  }
                }, {
                  default: _withCtx(() => [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_23, _toDisplayString(_unref(t)('countryName')), 1), _createElementVNode("div", _hoisted_24, _toDisplayString(item.user_auth_country), 1)])]),
                  _: 2
                }, 1024), _createVNode(_component_van_col, {
                  span: "8",
                  class: "data-item",
                  style: {
                    "text-align": "center"
                  }
                }, {
                  default: _withCtx(() => [_createElementVNode("div", _hoisted_25, _toDisplayString(_unref(t)('commissioned')), 1), _createElementVNode("div", _hoisted_26, _toDisplayString(item.commission ? _unref(fmtNumber)(item.commission) : 0), 1)]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024), _createElementVNode("div", _hoisted_27, _toDisplayString(item.user_create_time), 1)]);
            }), 128))]),
            _: 1
          }, 8, ["finished-text", "loading-text", "loading", "finished"])) : (_openBlock(), _createBlock(_component_van_empty, {
            key: 1,
            description: _unref(t)('nothing')
          }, null, 8, ["description"]))])]),
          _: 1
        }, 8, ["tab"]), _createVNode(_component_a_tab_pane, {
          key: "2",
          tab: _unref(t)('myRebate')
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_28, [commissionList.value.length > 0 ? (_openBlock(), _createBlock(_component_van_list, {
            key: 0,
            "finished-text": _unref(t)('finished'),
            "loading-text": _unref(t)('loading'),
            loading: commissionLoading.value,
            "onUpdate:loading": _cache[8] || (_cache[8] = $event => commissionLoading.value = $event),
            finished: commissionFinished.value,
            onLoad: commissionLoad
          }, {
            default: _withCtx(() => [_createVNode(_component_a_input_search, {
              value: commissionSearch.value,
              "onUpdate:value": _cache[6] || (_cache[6] = $event => commissionSearch.value = $event),
              placeholder: _unref(t)('emailInput'),
              "enter-button": "",
              onSearch: _cache[7] || (_cache[7] = $event => {
                commissionPage.value = 0;
                commissionList.value = [];
                commissionLoad();
              })
            }, null, 8, ["value", "placeholder"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(commissionList.value, (item, index) => {
              return _openBlock(), _createElementBlock("div", {
                class: "list-content",
                key: index
              }, [_createElementVNode("div", _hoisted_29, [_createElementVNode("div", _hoisted_30, _toDisplayString(item.user.user_email), 1)]), _createVNode(_component_van_row, null, {
                default: _withCtx(() => [_createVNode(_component_van_col, {
                  span: "8",
                  class: "data-item"
                }, {
                  default: _withCtx(() => [_createElementVNode("div", _hoisted_31, _toDisplayString(_unref(t)('currencyType')), 1), _createElementVNode("div", _hoisted_32, _toDisplayString(item.coin.coin_symbol), 1)]),
                  _: 2
                }, 1024), _createVNode(_component_van_col, {
                  span: "8",
                  class: "data-item",
                  style: {
                    "text-align": "center"
                  }
                }, {
                  default: _withCtx(() => [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_33, _toDisplayString(_unref(t)('money')), 1), _createElementVNode("div", _hoisted_34, _toDisplayString(item.fund_amount), 1)])]),
                  _: 2
                }, 1024), _createVNode(_component_van_col, {
                  span: "8",
                  class: "data-item",
                  style: {
                    "text-align": "right"
                  }
                }, {
                  default: _withCtx(() => [_createElementVNode("div", _hoisted_35, _toDisplayString(_unref(t)('type')), 1), _createElementVNode("div", _hoisted_36, _toDisplayString(item.info.contract_type == 1 ? _unref(t)('contract') : _unref(t)('fastTrade')), 1)]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024), _createElementVNode("div", _hoisted_37, _toDisplayString(item.fund_create_time), 1)]);
            }), 128))]),
            _: 1
          }, 8, ["finished-text", "loading-text", "loading", "finished"])) : (_openBlock(), _createBlock(_component_van_empty, {
            key: 1,
            description: _unref(t)('nothing')
          }, null, 8, ["description"]))])]),
          _: 1
        }, 8, ["tab"]), _createVNode(_component_a_tab_pane, {
          key: "3",
          tab: _unref(t)('rebateRule')
        }, {
          default: _withCtx(() => [_createElementVNode("div", {
            style: {
              "padding": "10px"
            },
            innerHTML: content.value
          }, null, 8, _hoisted_38)]),
          _: 1
        }, 8, ["tab"])]),
        _: 1
      }, 8, ["activeKey"])])]), _createVNode(_component_a_modal, {
        visible: modalShow.value,
        "onUpdate:visible": _cache[10] || (_cache[10] = $event => modalShow.value = $event),
        footer: null,
        centered: ""
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_39, [_createVNode(QrcodeVue, {
          value: _unref(store).state.inviteUrl,
          size: 200
        }, null, 8, ["value"])])]),
        _: 1
      }, 8, ["visible"])], 64);
    };
  }

};