import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '../../assets/icon/menu_unfold.png';
import _imports_1 from '../../assets/icon/trend.png';

const _withScopeId = n => (_pushScopeId("data-v-2650d14c"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "trade-container"
};
const _hoisted_2 = {
  class: "trade-navbar-l"
};
const _hoisted_3 = {
  class: "header-name"
};
const _hoisted_4 = {
  class: "trade-navbar-r"
};
const _hoisted_5 = {
  class: "trade-btc-option"
};
const _hoisted_6 = {
  class: "trade-btc-content"
};
const _hoisted_7 = {
  class: "content-l"
};
const _hoisted_8 = {
  class: "trade-btn-area"
};
const _hoisted_9 = {
  key: 0,
  class: "entrust-best"
};
const _hoisted_10 = {
  style: {
    "color": "#919497",
    "font-weight": "700",
    "padding": "0 10px"
  }
};
const _hoisted_11 = {
  class: "trade-usable"
};
const _hoisted_12 = {
  style: {
    "color": "black",
    "font-weight": "700"
  }
};
const _hoisted_13 = {
  style: {
    "color": "#919497",
    "font-weight": "700",
    "padding": "0 10px"
  }
};
const _hoisted_14 = {
  style: {
    "padding": "5px 0 12px 0"
  }
};
const _hoisted_15 = {
  class: "content-r"
};
const _hoisted_16 = {
  class: "trade-table-item",
  style: {
    "color": "#acafb3",
    "margin-bottom": "8px",
    "font-size": "12px"
  }
};
const _hoisted_17 = {
  class: "table-item-l"
};
const _hoisted_18 = {
  class: "table-item-r"
};
const _hoisted_19 = {
  class: "table-price-title"
};
const _hoisted_20 = {
  style: {
    "font-size": "11px",
    "color": "#ACAFB3"
  }
};
const _hoisted_21 = {
  style: {
    "min-height": "calc(100vh - 138px)"
  }
};
const _hoisted_22 = {
  class: "emhsn",
  style: {
    "min-height": "calc(100vh - 138px)"
  }
};
const _hoisted_23 = {
  style: {
    "display": "flex",
    "justify-content": "space-between",
    "padding": "15px 16px 0 16px",
    "font-size": "13px",
    "color": "#919497"
  }
};
import DataBlock from '@/components/DataBlock.vue';
import TradeProductItem from '@/components/TradeProductItem.vue';
import EntrustSelect from '@/components/EntrustSelect.vue';
import CustomTab from '@/components/CustomTab.vue';
import CustomPopupList from '@/components/CustomPopupList.vue';
import EmptyLogin from '@/components/EmptyLogin.vue';
import EntrustItem from '@/components/EntrustItem.vue';
import CurrencyItem from '@/components/CurrencyItem.vue';
import { exchangeRobot, getUserAsset, exchangeTrade, orderList } from '@/apis';
import BigNumber from "bignumber.js";
import { exType } from '@/util/type';
import { useI18n } from "vue-i18n";
import { Toast } from 'vant';
import 'vant/es/toast/style';
import { Dialog } from 'vant';
import 'vant/es/dialog/style';
import { ref, watch, onActivated, onDeactivated } from 'vue';
import { useRoute } from 'vue-router';
import { useWindowSize } from '@vant/use';
import store from '@/store';
import router from '@/router';
export default {
  __name: 'TradeTab',
  emits: ["tabbarShowChange"],

  setup(__props, {
    emit
  }) {
    const route = useRoute();
    const {
      t
    } = useI18n();
    const productKey = ref('');
    const dataList = ref({});
    const totalRate = ref(0);
    const isRate = ref(false);
    const marks = ref({
      0: '0%',
      25: '25%',
      50: '50%',
      75: '75%',
      100: '100%'
    });
    const menuShow = ref(false);
    const tradeType = ref(0); // 0 买入 1 卖出

    const entrustType = ref(1); // 委托类型 0 限价委托 1 市场委托

    const entrustPrice = ref(null); // 限价委托

    const entrustNum = ref(null); // 委托数量

    const totalPrice = ref(null);
    const numInput = ref(false);
    const totalInput = ref(false);
    const productShow = ref(false);
    const {
      height
    } = useWindowSize();
    const oldHeight = height.value;
    const maxNum = ref(0);
    const minNum = ref(0);
    const decimal = ref(2);
    const assetUsdt = ref(0);
    const typeAsset = ref(0);
    const assetList = ref([]);
    const order = ref([]);
    let heightWatch = null;
    const initLoading = ref(false);
    const listLoading = ref(false);
    const listFinished = ref(true);
    let listPage = 1;
    let dataTimer = null;
    watch(() => productKey.value, async () => {
      const {
        id,
        decimal: decimalNum,
        stock
      } = store.state.marketInfo[productKey.value];

      if (id) {
        entrustNum.value = null;
        totalPrice.value = null;
        entrustPrice.value = store.state.market[productKey.value].last;
        decimal.value = decimalNum;
        const res = await exchangeRobot({
          market_id: id
        });

        if (res.code === 200) {
          maxNum.value = res.data[0] ? Number(res.data[0].robot_max_amount) : 0;
          minNum.value = res.data[0] ? Number(res.data[0].robot_min_amount) : 0;
        }
      }

      if (store.state.token !== '') {
        const findItem = assetList.value.find(item => {
          return item.name === stock;
        });
        if (findItem) typeAsset.value = findItem.active;
      }
    });

    const keyChange = key => {
      if (key === 'LTJUSDT') {
        router.push({
          name: 'ieo'
        });
        return;
      }

      productKey.value = key;
      productShow.value = false;
    };

    const refresh = watch([store.state.marketInfo], () => {
      if (productKey.value === '' && store.state.homeKey[0]) {
        productKey.value = store.state.homeKey[0];
      }

      refresh();
    });

    const routerName = name => {
      if (store.state.token === '') {
        router.push({
          name: 'login'
        });
        return;
      }

      router.push({
        name: name
      });
    };

    const tradeTypeChange = index => {
      tradeType.value = index;
      entrustNum.value = null;
      totalPrice.value = null;
      totalRate.value = 0;
    };

    const fmtListArray = list => {
      return list.map(item => {
        return {
          id: item.order_id,
          name: item.stock.coin_name,
          coin: item.money.coin_name,
          time: item.order_create_time,
          status: item.order_status,
          side: item.order_side,
          type: item.order_type,
          price: item.order_price,
          count: item.order_count,
          deal: item.order_deal_money
        };
      });
    };

    const init = async () => {
      if (store.state.token !== '') {
        const res = await getUserAsset({
          plate: 'asset',
          token: store.state.token
        });

        if (res.code === 200 && res.data) {
          assetList.value = res.data.map(item => {
            if (item.coin.coin_symbol === 'USDT') {
              assetUsdt.value = item.asset ? Number(item.asset.asset_active).toFixed(4) : 0;
            }

            return {
              name: item.coin.coin_symbol,
              icon: item.coin.coin_icon,
              total: item.asset ? item.asset.asset_total : 0,
              active: item.asset ? Number(item.asset.asset_active).toFixed(4) : 0
            };
          });
        }

        orderInit();
      }

      if (store.state.homeKey[0]) {
        if (productKey.value === '') {
          productKey.value = store.state.homeKey[0];
        } else {
          const findItem = assetList.value.find(item => {
            return item.name === store.state.marketInfo[productKey.value].stock;
          });
          if (findItem) typeAsset.value = findItem.active;
        }
      }
    };

    const orderInit = async () => {
      listPage = 1;
      const orderRes = await orderList({
        token: store.state.token,
        status: 1
      });

      if (orderRes.code === 200 && orderRes.data && orderRes.data.list) {
        order.value = fmtListArray(orderRes.data.list);

        if (orderRes.data.count > order.value.length) {
          listFinished.value = false;
        }
      }
    };

    const listLoad = async () => {
      const page = listPage + 1;
      const res = await orderList({
        token: store.state.token,
        status: 1,
        page: page
      });

      if (res.code === 200 && res.data && res.data.list) {
        const list = fmtListArray(res.data.list);
        order.value = [...order.value, ...list];

        if (order.value.length >= res.data.count) {
          listFinished.value = true;
        }

        listPage += 1;
      }
    };

    const dataChange = () => {
      dataTimer = setInterval(() => {
        dataList.value = { ...store.state.market
        };
      }, 1200);
    };

    onActivated(() => {
      if (route.query.type) {
        switch (route.query.type) {
          case '1':
            tradeType.value = 0;
            break;

          case '2':
            tradeType.value = 1;
            break;
        }
      }

      heightWatch = watch(() => height.value, () => {
        let show = true;

        if (oldHeight - height.value > 120) {
          show = false;
        }

        emit("tabbarShowChange", show);
      });
      init();
      dataList.value = { ...store.state.market
      };
      dataChange();
    });
    onDeactivated(() => {
      heightWatch();
      clearInterval(dataTimer);
    });
    watch([entrustNum], () => {
      if (totalInput.value || numInput.value) {
        if (tradeType.value === 1) {
          if (entrustNum.value < 0) {
            totalRate.value = 0;
          } else if (entrustNum.value > typeAsset.value) {
            totalRate.value = 100;
          } else {
            totalRate.value = Number(BigNumber(entrustNum.value).dividedBy(typeAsset.value).multipliedBy(100).toFixed(0));
          }
        }
      }

      if (numInput.value) {
        let dNum = 8;

        if (store.state.marketInfo[productKey.value] && store.state.marketInfo[productKey.value].decimal > 8) {
          dNum = store.state.marketInfo[productKey.value].decimal;
        }

        if (entrustType.value === 0 && entrustPrice.value) {
          totalPrice.value = Number(BigNumber(entrustPrice.value).multipliedBy(entrustNum.value).toFixed(dNum));
        }

        if (entrustType.value) {
          const price = store.state.market[productKey.value] ? Number(store.state.market[productKey.value].last) : 0;
          totalPrice.value = Number(BigNumber(price).multipliedBy(entrustNum.value).toFixed(dNum));
        }
      }

      if (entrustNum.value === null && totalPrice.value) {
        totalPrice.value = null;
      }
    });
    watch([entrustPrice], () => {
      let dNum = 8;

      if (store.state.marketInfo[productKey.value] && store.state.marketInfo[productKey.value].decimal > 8) {
        dNum = store.state.marketInfo[productKey.value].decimal;
      }

      if (entrustType.value === 0 && entrustPrice.value > 0 && totalPrice.value > 0) {
        entrustNum.value = Number(BigNumber(totalPrice.value).dividedBy(entrustPrice.value).toFixed(dNum));
      }
    });
    watch([totalPrice], () => {
      if (totalInput.value || numInput.value) {
        if (tradeType.value === 0) {
          if (totalPrice.value < 0) {
            totalRate.value = 0;
          } else if (totalPrice.value > assetUsdt.value) {
            totalRate.value = 100;
          } else {
            totalRate.value = Number(BigNumber(totalPrice.value).dividedBy(assetUsdt.value).multipliedBy(100).toFixed(0));
          }
        }
      }

      if (totalInput.value) {
        let dNum = 8;

        if (store.state.marketInfo[productKey.value] && store.state.marketInfo[productKey.value].decimal > 8) {
          dNum = store.state.marketInfo[productKey.value].decimal;
        }

        if (entrustPrice.value && entrustType.value === 0) {
          entrustNum.value = Number(BigNumber(totalPrice.value).dividedBy(entrustPrice.value).toFixed(dNum));
        }

        if (entrustType.value) {
          const price = store.state.market[productKey.value] ? Number(store.state.market[productKey.value].last) : 0;

          if (price > 0) {
            entrustNum.value = Number(BigNumber(totalPrice.value).dividedBy(price).toFixed(dNum));
          }
        }
      }

      if (totalPrice.value === null && entrustNum.value) {
        entrustNum.value = null;
      }
    });

    const rateChange = value => {
      if (tradeType.value === 0) {
        totalPrice.value = Number(BigNumber(assetUsdt.value).multipliedBy(value).dividedBy(100).toFixed(8));

        if (totalPrice.value > 0) {
          if (entrustType.value === 1) {
            const price = store.state.market[productKey.value] ? Number(store.state.market[productKey.value].last) : 0;
            entrustNum.value = Number(BigNumber(totalPrice.value).dividedBy(price).toFixed(8));
          } else {
            if (entrustPrice.value > 0) {
              entrustNum.value = Number(BigNumber(totalPrice.value).dividedBy(entrustPrice.value).toFixed(8));
            }
          }
        }
      } else {
        entrustNum.value = Number(BigNumber(typeAsset.value).multipliedBy(value).dividedBy(100).toFixed(8));

        if (entrustNum.value > 0) {
          if (entrustType.value === 1) {
            const price = store.state.market[productKey.value] ? Number(store.state.market[productKey.value].last) : 0;
            totalPrice.value = Number(BigNumber(price).multipliedBy(entrustNum.value).toFixed(8));
          } else {
            if (entrustPrice.value > 0) {
              totalPrice.value = Number(BigNumber(entrustPrice.value).multipliedBy(entrustNum.value).toFixed(8));
            }
          }
        }
      }
    };

    const headerMenuSelect = (action, index) => {
      switch (index) {
        case 0:
          router.push({
            name: 'recharge'
          });
          break;

        case 1:
          router.push({
            name: 'transfer'
          });
          break;
      }
    };

    const entrustChange = index => {
      if (index === 0) {
        entrustPrice.value = store.state.market[productKey.value] ? Number(store.state.market[productKey.value].last) : 0;
      }

      entrustType.value = index;
    };

    const goToKline = () => {
      if (productKey.value === '') {
        Toast({
          message: t('tryAgain'),
          position: 'bottom'
        });
        return;
      }

      router.push({
        name: 'kline',
        query: {
          key: productKey.value,
          type: 'trade'
        }
      });
    };

    const tradeCommit = () => {
      if (store.state.token === '') {
        router.push({
          name: 'login'
        });
        return;
      }

      if (entrustType.value === 0) {
        if (!entrustPrice.value) {
          Toast({
            message: t('entrustPriceTip'),
            position: 'bottom'
          });
          return;
        }
      }

      if (tradeType.value) {
        if (!entrustNum.value) {
          Toast({
            message: t('tradeNumTip'),
            position: 'bottom'
          });
          return;
        }

        if (entrustNum.value > typeAsset.value) {
          Toast({
            message: t('numLessTip'),
            position: 'bottom'
          });
          return;
        }
      } else {
        if (!totalPrice.value) {
          Toast({
            message: t('tradePriceTip'),
            position: 'bottom'
          });
          return;
        }

        if (assetUsdt.value < totalPrice.value) {
          Toast({
            message: t('balanceTip'),
            position: 'bottom'
          });
          return;
        }
      }

      const data = {
        token: store.state.token,
        market_id: store.state.marketInfo[productKey.value].id,
        count: entrustNum.value,
        deal_money: totalPrice.value,
        type: tradeType.value ? 'sell' : 'buy',
        ex_type: 'ex',
        ex_password: '123123'
      };

      if (entrustType.value) {
        data['trade_type'] = 'market';
      } else {
        data['trade_type'] = 'limit';
        data['price'] = entrustPrice.value;
      }

      const beforeClose = action => {
        return new Promise(resolve => {
          if (action === 'confirm') {
            exchangeTrade(exType.ex, data).then(res => {
              if (res.code !== 200) {
                resolve(false);
                Toast({
                  message: res.message !== '' ? res.message : t('tradeError'),
                  position: 'bottom'
                });
                return;
              }

              Toast({
                message: t('tradeSuccess'),
                position: 'bottom'
              });
              resolve(true);
              init().finally(() => {
                entrustPrice.value = null;
                entrustNum.value = null;
                totalPrice.value = null;
              });
            });
          } else {
            resolve(true);
          }
        });
      };

      Dialog.confirm({
        title: t('tip'),
        message: t('tradeConfirm'),
        cancelButtonText: t('cancelText'),
        confirmButtonText: t('confirmText'),
        beforeClose
      }).catch(() => {});
    };

    const onRefresh = () => {
      initLoading.value = true;

      if (!store.state.wsConnect) {
        store.commit('wsInit');
      }

      init().finally(() => {
        initLoading.value = false;
      });
    };

    const getProductUp = () => {
      const key = productKey.value;
      return dataList.value[key] ? dataList.value[key].up : true;
    };

    const getProductNum = name => {
      const key = productKey.value;
      return dataList.value[key] ? dataList.value[key][name] : '0.00';
    };

    const getProductStr = name => {
      const key = productKey.value;
      return store.state.marketInfo[key] ? store.state.marketInfo[key][name] : '-';
    };

    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");

      const _component_van_popover = _resolveComponent("van-popover");

      const _component_van_nav_bar = _resolveComponent("van-nav-bar");

      const _component_van_button = _resolveComponent("van-button");

      const _component_a_input_number = _resolveComponent("a-input-number");

      const _component_a_slider = _resolveComponent("a-slider");

      const _component_van_list = _resolveComponent("van-list");

      const _component_van_empty = _resolveComponent("van-empty");

      const _component_van_pull_refresh = _resolveComponent("van-pull-refresh");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
        class: "trade-nav-bar",
        "safe-area-inset-top": true,
        fixed: true,
        placeholder: true,
        "z-index": "200",
        border: false
      }, {
        left: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
          onClick: _cache[0] || (_cache[0] = $event => productShow.value = true),
          class: "menu-fold",
          src: _imports_0
        }), _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(store).state.marketInfo[productKey.value]?.name), 1), _createElementVNode("div", {
          class: "header-trend",
          style: _normalizeStyle({
            backgroundColor: getProductUp() ? '#F0F5F5' : '#FBF3F1',
            color: getProductUp() ? '#04AC92' : '#D04C64'
          })
        }, _toDisplayString(getProductNum('growth')) + "% ", 5)])]),
        right: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
          class: "trend-icon",
          src: _imports_1,
          onClick: goToKline
        }), _createVNode(_component_van_popover, {
          show: menuShow.value,
          "onUpdate:show": _cache[1] || (_cache[1] = $event => menuShow.value = $event),
          actions: [{
            text: _unref(t)('recharge')
          }, {
            text: _unref(t)('transfer')
          }],
          onSelect: headerMenuSelect,
          placement: "bottom-end",
          "show-arrow": false,
          overlay: ""
        }, {
          reference: _withCtx(() => [_createVNode(_component_van_icon, {
            class: "menu-btn",
            name: "weapp-nav"
          })]),
          _: 1
        }, 8, ["show", "actions"])])]),
        _: 1
      }), _createVNode(_component_van_pull_refresh, {
        modelValue: initLoading.value,
        "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => initLoading.value = $event),
        onRefresh: onRefresh,
        "pulling-text": _unref(t)('pulling'),
        "loosing-text": _unref(t)('loosing'),
        "head-height": "80",
        "loading-text": _unref(t)('loading'),
        disabled: isRate.value
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_8, [_createVNode(_component_van_button, {
          color: tradeType.value ? '#F7F6FB' : '#04AC92',
          block: "",
          onClick: _cache[2] || (_cache[2] = $event => tradeTypeChange(0)),
          style: _normalizeStyle({
            '--van-button-border-radius': '2px',
            color: tradeType.value ? '#919497' : 'white',
            width: '48%',
            fontWeight: 700,
            fontSize: '16px',
            '--van-button-default-height': '38px'
          })
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(t)('buy')), 1)]),
          _: 1
        }, 8, ["color", "style"]), _createVNode(_component_van_button, {
          color: tradeType.value ? '#D04C64' : '#F7F6FB',
          block: "",
          onClick: _cache[3] || (_cache[3] = $event => tradeTypeChange(1)),
          style: _normalizeStyle({
            '--van-button-border-radius': '2px',
            color: tradeType.value ? 'white' : '#919497',
            width: '48%',
            fontWeight: 700,
            fontSize: '16px',
            '--van-button-default-height': '38px'
          })
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(t)('sell')), 1)]),
          _: 1
        }, 8, ["color", "style"])]), _createVNode(EntrustSelect, {
          "entrust-type": entrustType.value,
          onEntrustChange: entrustChange,
          "popover-w": "calc((100vw - 32px) * 0.55)",
          style: {
            "margin": "10px 0 12px 0"
          }
        }, null, 8, ["entrust-type"]), entrustType.value ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_unref(t)('optimal')), 1)) : (_openBlock(), _createBlock(_component_a_input_number, {
          key: 1,
          class: "entrust-price-input",
          type: "number",
          value: entrustPrice.value,
          "onUpdate:value": _cache[4] || (_cache[4] = $event => entrustPrice.value = $event),
          min: "0",
          placeholder: _unref(t)('price'),
          style: _normalizeStyle({
            '--entrust-focus': tradeType.value ? '#D04C64' : '#04AC92'
          }),
          precision: 8
        }, null, 8, ["value", "placeholder", "style"])), _createElementVNode("div", {
          class: "trade-num",
          style: _normalizeStyle({
            '--trade-num-focus': tradeType.value ? '#D04C64' : '#04AC92',
            marginTop: '10px'
          })
        }, [_createVNode(_component_a_input_number, {
          value: entrustNum.value,
          "onUpdate:value": _cache[5] || (_cache[5] = $event => entrustNum.value = $event),
          bordered: false,
          style: {
            "width": "100%",
            "font-size": "14px"
          },
          onBlur: _cache[6] || (_cache[6] = $event => numInput.value = false),
          placeholder: _unref(t)('number'),
          type: "number",
          precision: 8,
          min: "0",
          onFocus: _cache[7] || (_cache[7] = $event => numInput.value = true)
        }, null, 8, ["value", "placeholder"]), _createElementVNode("div", _hoisted_10, _toDisplayString(getProductStr('stock')), 1)], 4), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", null, _toDisplayString(_unref(t)('useable')), 1), _createElementVNode("div", _hoisted_12, [_withDirectives(_createElementVNode("span", null, _toDisplayString(assetUsdt.value) + " USDT", 513), [[_vShow, tradeType.value === 0]]), _withDirectives(_createElementVNode("span", null, _toDisplayString(typeAsset.value) + " " + _toDisplayString(getProductStr('stock')), 513), [[_vShow, tradeType.value === 1]])])]), _createElementVNode("div", {
          class: "trade-num",
          style: _normalizeStyle({
            '--trade-num-focus': tradeType.value ? '#D04C64' : '#04AC92'
          })
        }, [_createVNode(_component_a_input_number, {
          value: totalPrice.value,
          "onUpdate:value": _cache[8] || (_cache[8] = $event => totalPrice.value = $event),
          bordered: false,
          style: {
            "width": "100%",
            "font-size": "14px"
          },
          placeholder: _unref(t)('tradeVol'),
          type: "number",
          precision: 8,
          min: "0",
          onBlur: _cache[9] || (_cache[9] = $event => totalInput.value = false),
          onFocus: _cache[10] || (_cache[10] = $event => totalInput.value = true)
        }, null, 8, ["value", "placeholder"]), _createElementVNode("div", _hoisted_13, _toDisplayString(getProductStr('coin')), 1)], 4)]), _createElementVNode("div", _hoisted_14, [_createVNode(_component_a_slider, {
          value: totalRate.value,
          "onUpdate:value": _cache[11] || (_cache[11] = $event => totalRate.value = $event),
          marks: marks.value,
          onChange: rateChange,
          onFocus: _cache[12] || (_cache[12] = $event => isRate.value = true),
          onBlur: _cache[13] || (_cache[13] = $event => isRate.value = false)
        }, null, 8, ["value", "marks"])]), _createVNode(_component_van_button, {
          color: tradeType.value ? '#D04C64' : '#04AC92',
          block: "",
          onClick: tradeCommit,
          style: {
            '--van-button-border-radius': '2px',
            fontWeight: 700,
            fontSize: '16px',
            '--van-button-default-height': '40px'
          }
        }, {
          default: _withCtx(() => [tradeType.value ? (_openBlock(), _createElementBlock(_Fragment, {
            key: 0
          }, [_createTextVNode(_toDisplayString(_unref(t)('sell')), 1)], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
            key: 1
          }, [_createTextVNode(_toDisplayString(_unref(t)('buy')), 1)], 64))]),
          _: 1
        }, 8, ["color"])]), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [_createElementVNode("div", _hoisted_17, _toDisplayString(_unref(t)('price')), 1), _createElementVNode("div", _hoisted_18, _toDisplayString(_unref(t)('number')), 1)]), (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, index => {
          return _createVNode(DataBlock, {
            key: index,
            class: "trade-table-item",
            bg: "rgba(251, 243, 241, .6)",
            decimal: decimal.value,
            "color-l": "#EC234D",
            max: maxNum.value,
            min: minNum.value,
            price: getProductNum('last'),
            bottom: false,
            index: index - 1
          }, null, 8, ["decimal", "max", "min", "price", "index"]);
        }), 64)), _createElementVNode("div", _hoisted_19, [_createElementVNode("div", {
          class: "big-num",
          style: _normalizeStyle({
            color: getProductUp() ? '#04AC92' : '#EC234D'
          })
        }, _toDisplayString(getProductNum('last')), 5), _createElementVNode("div", _hoisted_20, " ≈ " + _toDisplayString(getProductNum('last')) + " " + _toDisplayString(getProductStr('coin')), 1)]), (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, index => {
          return _createVNode(DataBlock, {
            key: index,
            class: "trade-table-item",
            bg: "rgba(234, 249, 246, .6)",
            decimal: decimal.value,
            "color-l": "#269B8B",
            max: maxNum.value,
            min: minNum.value,
            price: getProductNum('last'),
            bottom: true,
            index: index - 1
          }, null, 8, ["decimal", "max", "min", "price", "index"]);
        }), 64))])])]), _createVNode(CustomTab, {
          "tab-list": [_unref(t)('entrust'), _unref(t)('wallet')],
          onAllClick: _cache[15] || (_cache[15] = $event => routerName('CTOrder'))
        }, {
          tab1: _withCtx(() => [_createElementVNode("div", _hoisted_21, [_unref(store).state.token === '' ? (_openBlock(), _createBlock(EmptyLogin, {
            key: 0
          })) : order.value.length > 0 ? (_openBlock(), _createBlock(_component_van_list, {
            key: 1,
            "finished-text": _unref(t)('finished'),
            "loading-text": _unref(t)('loading'),
            loading: listLoading.value,
            "onUpdate:loading": _cache[14] || (_cache[14] = $event => listLoading.value = $event),
            finished: listFinished.value,
            onLoad: listLoad
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(order.value, (item, index) => {
              return _openBlock(), _createBlock(EntrustItem, {
                key: index,
                name: item.name,
                coin: item.coin,
                time: item.time,
                id: item.id,
                type: item.type,
                status: item.status,
                side: item.side,
                price: item.price,
                count: item.count,
                deal: item.deal,
                onInit: init,
                "all-text": `${_unref(t)('entrustTotal')}(USDT)`
              }, null, 8, ["name", "coin", "time", "id", "type", "status", "side", "price", "count", "deal", "all-text"]);
            }), 128))]),
            _: 1
          }, 8, ["finished-text", "loading-text", "loading", "finished"])) : (_openBlock(), _createBlock(_component_van_empty, {
            key: 2,
            description: _unref(t)('nothing')
          }, null, 8, ["description"]))])]),
          tab2: _withCtx(() => [_createElementVNode("div", _hoisted_22, [_unref(store).state.token === '' ? (_openBlock(), _createBlock(EmptyLogin, {
            key: 0
          })) : assetList.value.length > 0 ? (_openBlock(), _createElementBlock(_Fragment, {
            key: 1
          }, [_createElementVNode("div", _hoisted_23, [_createElementVNode("div", null, _toDisplayString(_unref(t)('headerCurrencyType')), 1), _createElementVNode("div", null, _toDisplayString(`${_unref(t)('number')}/${_unref(t)('useable')}`), 1)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(assetList.value, (item, index) => {
            return _openBlock(), _createBlock(CurrencyItem, {
              key: index,
              icon: item.icon,
              name: item.name,
              active: `${item.active}`,
              total: `${item.total}`
            }, null, 8, ["icon", "name", "active", "total"]);
          }), 128))], 64)) : (_openBlock(), _createBlock(_component_van_empty, {
            key: 2,
            description: _unref(t)('nothing')
          }, null, 8, ["description"]))])]),
          _: 1
        }, 8, ["tab-list"])]),
        _: 1
      }, 8, ["modelValue", "pulling-text", "loosing-text", "loading-text", "disabled"]), _createVNode(CustomPopupList, {
        "c-show": productShow.value,
        title: _unref(t)('currency'),
        onHide: _cache[17] || (_cache[17] = () => {
          productShow.value = false;
        })
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).state.marketInfo, (item, key) => {
          return _openBlock(), _createBlock(TradeProductItem, {
            key: key,
            name: key,
            last: dataList.value[key]?.last,
            up: dataList.value[key].up,
            onClick: $event => keyChange(key),
            icon: item?.icon
          }, null, 8, ["name", "last", "up", "onClick", "icon"]);
        }), 128))]),
        _: 1
      }, 8, ["c-show", "title"])]);
    };
  }

};