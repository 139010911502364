import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-62ea4d6a"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "contract-item"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = {
  class: "header"
};
const _hoisted_4 = {
  class: "title"
};
const _hoisted_5 = {
  class: "multiple"
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  key: 1,
  style: {
    "display": "flex"
  }
};
const _hoisted_8 = ["onClick"];
const _hoisted_9 = {
  key: 2,
  style: {
    "font-size": "13px",
    "color": "#919497"
  }
};
const _hoisted_10 = {
  key: 3,
  style: {
    "font-size": "13px",
    "color": "#D04C64"
  }
};
const _hoisted_11 = {
  class: "info"
};
const _hoisted_12 = {
  key: 0,
  class: "tag",
  style: {
    "--color": "#04AC92",
    "--bColor": "#E4F7F1"
  }
};
const _hoisted_13 = {
  key: 1,
  class: "tag",
  style: {
    "--color": "#D04C64",
    "--bColor": "#FBF3F1"
  }
};
const _hoisted_14 = {
  key: 2,
  class: "tag",
  style: {
    "--color": "#04AC92",
    "--bColor": "#E4F7F1"
  }
};
const _hoisted_15 = {
  key: 3,
  class: "tag",
  style: {
    "--color": "#D04C64",
    "--bColor": "#FBF3F1"
  }
};
const _hoisted_16 = {
  class: "date"
};
const _hoisted_17 = {
  key: 0,
  class: "info",
  style: {
    "padding": "0 0 10px 0"
  }
};
const _hoisted_18 = {
  class: "date"
};
const _hoisted_19 = {
  style: {
    "font-size": "13px",
    "padding-bottom": "5px",
    "color": "#969799",
    "display": "flex",
    "justify-content": "space-between"
  }
};
const _hoisted_20 = {
  style: {
    "margin-left": "20px"
  }
};
const _hoisted_21 = {
  key: 1
};
const _hoisted_22 = {
  key: 2
};
const _hoisted_23 = {
  class: "label"
};
const _hoisted_24 = {
  class: "num"
};
const _hoisted_25 = {
  class: "label mt"
};
const _hoisted_26 = {
  class: "num"
};
const _hoisted_27 = {
  class: "label"
};
const _hoisted_28 = {
  class: "num"
};
const _hoisted_29 = {
  class: "label mt"
};
const _hoisted_30 = {
  class: "num"
};
const _hoisted_31 = {
  class: "label"
};
const _hoisted_32 = {
  class: "num"
};
const _hoisted_33 = {
  class: "label mt"
};
import { ref } from 'vue';
import store from '@/store';
import { Dialog, Toast } from 'vant';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import { contractType, exType } from '@/util/type';
import { exchangeCancel, closeContract } from '@/apis';
import BigNumber from "bignumber.js";
import { useI18n } from "vue-i18n";
import { fmtNumber } from '@/util';
export default {
  __name: 'ContractItem',
  props: {
    id: {
      type: Number
    },
    name: {
      type: String,
      default: '--'
    },
    multiple: {
      type: Number
    },
    side: {
      type: Number,
      default: 0
    },
    type: {
      type: Number,
      default: 0
    },
    time: {
      type: String,
      default: '--'
    },
    openNum: {
      type: String,
      default: '0'
    },
    holdNum: {
      type: String,
      default: '0'
    },
    warrant: {
      type: String,
      default: '0'
    },
    fee: {
      type: String,
      default: '0'
    },
    profit: {
      type: String,
      default: '0'
    },
    status: {
      type: Number,
      default: 0
    },
    last: {
      type: String,
      default: '0'
    },
    margin: {
      type: String,
      default: '0'
    },
    openPrice: {
      type: String,
      default: '0'
    },
    sl: {
      type: String,
      default: '0'
    },
    tp: {
      type: String,
      default: '0'
    },
    close: {
      type: Number,
      default: 0
    },
    lastText: {
      type: String,
      default: '--'
    },
    closeTime: {
      type: String,
      default: ''
    }
  },
  emits: ['init', 'set'],

  setup(__props, {
    emit
  }) {
    const props = __props;
    const {
      t
    } = useI18n();
    const color = ref('#04AC92');

    const cancelOrder = () => {
      const beforeClose = action => {
        return new Promise(resolve => {
          if (action === 'confirm') {
            exchangeCancel(exType.contract, {
              id: props.id,
              token: store.state.token,
              ex_type: 'contract'
            }).then(res => {
              if (res.code !== 200) {
                Toast({
                  message: res.message ? res.message : t('cancelError'),
                  position: "bottom"
                });
                resolve(false);
                return;
              }

              Toast({
                message: t('cancelSuccess'),
                position: "bottom"
              });
              emit('init');
              resolve(true);
            });
          } else {
            resolve(true);
          }
        });
      };

      Dialog.confirm({
        title: t('tip'),
        message: t('cancelTip'),
        cancelButtonText: t('cancelText'),
        confirmButtonText: t('confirmText'),
        beforeClose
      }).catch(() => {});
    };

    const getProfit = () => {
      if (props.last !== '--') {
        const count = BigNumber(props.last).minus(props.openPrice).toNumber();
        const decimal = 4;
        let price = BigNumber(props.margin).dividedBy(props.openPrice).multipliedBy(count).multipliedBy(props.multiple).toFixed(decimal);
        if (props.side) price = Number(price);else price = 0 - price;
        if (price >= 0) color.value = '#04AC92';else color.value = '#D04C64';
        return fmtNumber(price);
      }

      return 0;
    };

    const getProfitRate = () => {
      if (props.last !== '--') {
        const count = BigNumber(props.last).minus(props.openPrice).toNumber();
        const price = BigNumber(props.margin).dividedBy(props.openPrice).multipliedBy(count).multipliedBy(props.multiple).dividedBy(props.warrant).multipliedBy(100).toFixed(2);

        if (props.side) {
          return Number(price);
        } else {
          return 0 - price;
        }
      }

      return 0;
    };

    const closeOrder = async () => {
      closeContract({
        contract_id: props.id
      }).then(res => {
        if (res.code !== 200) {
          Toast({
            message: res.message ? res.message : t('unwindError'),
            position: "bottom"
          });
          emit('init');
          return;
        }

        Toast({
          message: t('unwindSuccess'),
          position: "bottom"
        });
        emit('init');
      });
    };

    return (_ctx, _cache) => {
      const _component_van_col = _resolveComponent("van-col");

      const _component_van_row = _resolveComponent("van-row");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createTextVNode(_toDisplayString(_unref(store).state.marketInfo[props.name]?.name), 1), _createElementVNode("span", _hoisted_5, _toDisplayString(props.multiple) + "X", 1)]), props.status === _unref(contractType).entrust ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "btn",
        onClick: _withModifiers(cancelOrder, ["stop"])
      }, _toDisplayString(_unref(t)('cancelOrder')), 9, _hoisted_6)) : props.status === _unref(contractType).open ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("div", {
        class: "btn",
        style: {
          "margin-right": "6px"
        },
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => emit('set', props.id), ["stop"]))
      }, _toDisplayString(_unref(t)('setting')), 1), _createElementVNode("div", {
        class: "btn",
        onClick: _withModifiers(closeOrder, ["stop"])
      }, _toDisplayString(_unref(t)('unwind')), 9, _hoisted_8)])) : props.status === _unref(contractType).flat ? (_openBlock(), _createElementBlock("div", _hoisted_9, [props.close === 1 ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createTextVNode(_toDisplayString(_unref(t)('handUnwind')), 1)], 64)) : props.close === 2 ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createTextVNode(_toDisplayString(_unref(t)('futuresPay')), 1)], 64)) : props.close === 3 ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 2
      }, [_createTextVNode(_toDisplayString(_unref(t)('forceUnwind')), 1)], 64)) : props.close === 4 ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 3
      }, [_createTextVNode(_toDisplayString(_unref(t)('slTp')), 1)], 64)) : props.close === 5 ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 4
      }, [_createTextVNode(_toDisplayString(_unref(t)('fastTradeUnwind')), 1)], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
        key: 5
      }, [_createTextVNode(_toDisplayString(_unref(t)('blowUp')), 1)], 64))])) : (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_unref(t)('canceled')), 1))]), _createElementVNode("div", _hoisted_11, [props.side ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_unref(t)('toBuy')), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_unref(t)('toSell')), 1)), props.type === 1 ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_unref(t)('marketPrice')), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_unref(t)('limitPrice')), 1)), _createElementVNode("div", _hoisted_16, _toDisplayString(props.time), 1)]), props.closeTime !== '' ? (_openBlock(), _createElementBlock("div", _hoisted_17, [_createElementVNode("div", _hoisted_18, _toDisplayString(_unref(t)('closedTime')) + ": " + _toDisplayString(props.closeTime), 1)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_19, [_createElementVNode("div", null, [_createTextVNode(_toDisplayString(_unref(t)('tp')) + "：" + _toDisplayString(props.tp ? props.tp : 0), 1), _createElementVNode("span", _hoisted_20, _toDisplayString(_unref(t)('sl')) + "：" + _toDisplayString(props.sl ? props.sl : 0), 1)]), _createElementVNode("div", null, [_createTextVNode(_toDisplayString(_unref(t)('profitP')) + "： ", 1), props.status === 2 ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        style: _normalizeStyle({
          color: color.value
        })
      }, _toDisplayString(getProfitRate()) + "%", 5)) : props.status === 3 ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString((props.profit / props.warrant * 100).toFixed(2)) + "%", 1)) : (_openBlock(), _createElementBlock("span", _hoisted_22, "--"))])]), _createVNode(_component_van_row, null, {
        default: _withCtx(() => [_createVNode(_component_van_col, {
          span: "8",
          class: "data-item"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_23, _toDisplayString(_unref(t)('openNum')), 1), _createElementVNode("div", _hoisted_24, _toDisplayString(_unref(fmtNumber)(props.openNum)), 1), _createElementVNode("div", _hoisted_25, _toDisplayString(_unref(t)('warrant')), 1), _createElementVNode("div", _hoisted_26, _toDisplayString(_unref(fmtNumber)(props.warrant)), 1)]),
          _: 1
        }), _createVNode(_component_van_col, {
          span: "8",
          class: "data-item",
          style: {
            "text-align": "center"
          }
        }, {
          default: _withCtx(() => [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_27, _toDisplayString(_unref(t)('openPrice')), 1), _createElementVNode("div", _hoisted_28, _toDisplayString(_unref(fmtNumber)(props.openPrice)), 1), _createElementVNode("div", _hoisted_29, _toDisplayString(_unref(t)('fee')), 1), _createElementVNode("div", _hoisted_30, _toDisplayString(_unref(fmtNumber)(props.fee)), 1)])]),
          _: 1
        }), _createVNode(_component_van_col, {
          span: "8",
          class: "data-item",
          style: {
            "text-align": "right"
          }
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_31, _toDisplayString(props.lastText), 1), _createElementVNode("div", _hoisted_32, _toDisplayString(_unref(fmtNumber)(props.last)), 1), _createElementVNode("div", _hoisted_33, _toDisplayString(_unref(t)('profit')), 1), props.status === 2 ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            style: _normalizeStyle({
              color: color.value
            })
          }, _toDisplayString(getProfit()), 5)) : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "num",
            style: _normalizeStyle({
              color: props.profit >= 0 ? '#04AC92' : '#D04C64'
            })
          }, _toDisplayString(_unref(fmtNumber)(props.profit)), 5))]),
          _: 1
        })]),
        _: 1
      })])]);
    };
  }

};