export default {
  required: '필수의',
  home: '시작',
  market: '시장',
  currency: '거래',
  walletTrade: '점',
  contract: '계약',
  wallet: '지갑',
  pulling: '새로고침하려면 아래로 당기세요...',
  loosing: '새로고침하려면 손을 뗍니다...',
  loading: '로드 중...',
  loadingError: '불러 오지 못했습니다',
  nothing: '데이터 없음',
  finished: '',
  price: '가격',
  number: '수량',
  recharge: '보증금',
  transfer: '옮기다',
  invite: '초대',
  inviteDes: '친구 초대하기',
  contact: '문의하기',
  contactDes: '7*24 온라인',
  fastTrade: '옵션',
  name: '이름',
  fastPrice: '가격',
  trend: '24H %',
  all: '모두',
  growthList: '승자',
  loserList: '패자',
  vol: '24H Vol',
  buy: '구입',
  sell: '팔다',
  optimal: '시장 가격',
  marketOrder: '시장 무역',
  limitOrder: '거래 제한',
  useable: '사용 가능',
  tradeVol: '양',
  entrust: '지정가 주문',
  entrustTotal: '양',
  noLogin: '로그 아웃, ',
  toLogin: '지금 로그인하십시오',
  cancelOrder: '취소',
  cancelError: '취소하지 못했습니다.',
  cancelSuccess: '성공적으로 취소',
  cancelTip: '취소하시겠습니까?',
  completed: '완전한',
  canceled: '취소 된',
  marketPrice: '시장',
  limitPrice: '한계',
  entrustPrice: '가격',
  entrustNum: '수량',
  currencyType: '상징',
  headerCurrencyType: '상징',
  currencyOrder: '무역 기록',
  history: '역사',
  tryAgain: '다시 시도하십시오',
  entrustPriceTip: '입력 가격',
  tradeNumTip: '입력 수량',
  tradeNumLess: '수량은 0보다 커야 합니다.',
  numLessTip: '잔액 불충분',
  tradePriceTip: '투입금액',
  balanceTip: '잔액 불충분',
  tradeError: '거래에 실패했습니다',
  tradeSuccess: '성공적으로 거래',
  tip: '알아채다',
  tradeConfirm: '거래를 확인하시겠습니까?',
  cancelText: '취소',
  confirmText: '확인하다',
  toBuy: '사다',
  toSell: '공매도',
  confirmToBuy: '사다',
  confirmToSell: '공매도',
  slPrice: 'SL 가격',
  sl: 'SL',
  tp: 'TP',
  tpPrice: 'TP 가격',
  currentOrder: '현재 주문',
  historyOrder: '역사',
  setting: '세트',
  unwind: '닫다',
  handUnwind: '수동으로 닫기',
  futuresPay: '',
  forceUnwind: '변제',
  slTp: 'SL/TP',
  fastTradeUnwind: '옵션',
  blowUp: '변제',
  openNum: '수량',
  warrant: '여유',
  openPrice: '가격',
  fee: '요금',
  profit: '이익',
  lastPrice: '현재 가격',
  unwindPrice: '종가',
  unwindError: '닫기 실패',
  unwindSuccess: '성공적으로 종료',
  setSuccess: '성공적으로 설정',
  setError: '설정 실패',
  setSlTp: '세트 SL/TP',
  second: 's',
  multiple: '다수의',
  direction: '유형',
  tradeCurrency: '상징',
  loss: '상실',
  fill: '이기다',
  executePrice: '가격',
  currentPrice: '현재 가격',
  closePrice: '종가',
  secondNum: '기간',
  contractOrder: '선물 주문',
  fastTradeOrder: '옵션 주문',
  overview: '개요',
  ieoBuy: 'IEO',
  balanceTotal: '총자산',
  withdraw: '철회하다',
  tradeOrder: '무역 주문',
  rechargeHistory: '입금 기록',
  withdrawHistory: '철회 기록',
  freeze: '겨울 왕국',
  amountTo: '',
  tipText: '알아채다',
  ieoTip: '현재 IEO가 열리지 않았습니다. 나중에 다시 시도하십시오.',
  financeList: '재무 기록',
  currencyAccount: '스팟 계정',
  contractAccount: '선물 계정',
  time: '시간',
  status: '상태',
  balanceTyle: '계정',
  pay: '결과',
  income: '소득',
  lastBalance: '균형',
  orderNo: 'ID',
  money: '양',
  date: '날짜',
  finallyIncome: '받는 금액',
  commitMoney: '양',
  orderNum: 'ID',
  waitReview: '검토 중',
  reviewPass: '합격',
  reviewReject: '거부됨',
  waitWallet: '검토 중',
  walletPass: '합격',
  walletReject: '거부됨',
  withdrawSuccess: '출금 성공',
  withdrawError: '전송 실패',
  high24H: '24H 높은',
  low24H: '24H 낮은',
  num24H: '24H Vol',
  minute: '분',
  hour: '시간',
  day: '요일',
  week: '주',
  markets: '시장',
  open: '열려 있는',
  close: '닫다',
  high: '높은',
  low: '낮은',
  volume: 'Vol',
  fastLogin: '지금 로그인하십시오',
  register: '등록하다',
  auth: '확인하다',
  authSuccess: '검증됨',
  noPass: '거부됨',
  authCommit: '검토 중',
  onAuth: '미확인',
  onLogin: '로그 아웃',
  securitySet: '보안',
  userAgree: '사용자 지원',
  aboutUs: '회사 소개',
  logout: '로그 아웃',
  logoutSuccess: '성공적으로 로그아웃',
  lookAuthInfo: '내 정보 확인',
  authAgain: '재확인',
  authInfo: '정보',
  account: '계정',
  countryArea: '국가/지역',
  idNo: 'ID#',
  idNoTip: '주민등록번호 입력',
  email: '우편',
  emailInput: '이메일 주소 입력',
  setLoginPwd: '로그인 비밀번호 설정',
  loginPwdInput: '로그인 비밀번호 입력(8~20자)',
  loginPwdTip: '로그인 비밀번호 입력',
  loginPwdLen: '로그인 비밀번호는 8~20자여야 합니다.',
  setFinancePwd: '펀드 비밀번호 설정',
  financePwdInput: '펀드비밀번호 입력(6~20자)',
  financePwdTip: '펀드 비밀번호 입력',
  financePwdLen: '펀드 비밀번호는 6~20자여야 합니다.',
  inviteCode: '초대 코드',
  inviteCodeTip: 'ใส่รหัสคำเชิญ',
  inviteInput: '초대 코드가 필요합니다',
  imageCode: 'Captcha',
  imageCodeInput: '보안문자 입력',
  registerTip: '등록은 동의함을 의미합니다. ',
  agree: '《사용자 계약》',
  agreeText: '사용자 계약',
  next: '다음',
  registerSuccess: '완전히 등록',
  registerError: '등록 실패',
  securityCheck: '보안',
  checkCodeInput: '인증코드 입력',
  sendCheckCode: '보내다',
  sendSuccess: '전송된',
  sendError: '전송 실패',
  welcome: '에 오신 것을 환영합니다',
  loginEmail: '우편',
  loginEmailInput: '이메일 주소 입력',
  loginPwd: '로그인 비밀번호',
  login: '로그인',
  forgetPwd: '비밀번호를 잊으 셨나요',
  loginTip: '로그인은 동의함을 의미합니다.',
  loginSuccess: '완전히 로그인',
  loginError: '로그인 실패',
  fullname: '이름',
  fullnameTip: '이름을 입력하세요',
  country: '국가/지역',
  countryTip: '국가/지역 입력',
  imgFront: '신분증 앞면 사진 업로드',
  imgReverse: '신분증 뒷면 사진 업로드',
  commit: '제출하다',
  commitSuccess: '완전히 제출',
  commitError: '제출 실패',
  selectCurrency: '암호화폐 선택',
  rechargeNet: '회로망',
  rechargeAddress: '입금 주소',
  copyAddress: '주소 복사',
  minRecharge: '최소 입금액',
  copySuccess: '완전히 복사',
  copyError: '복사 실패',
  withdrawTip: '계좌 확인 후 출금 가능',
  withdrawAddress: '출금 주소',
  withdrawAddressInput: '출금 주소 입력',
  withdrawNet: '회로망',
  withdrawNum: '양',
  withdrawNumInput: '투입금액',
  withdrawNumLess: '잔액 불충분',
  withdrawMin: '최소 인출 금액',
  balanceUse: '사용 가능',
  actualMoney: '받는 금액',
  actualMoneyText: '받는 금액',
  financePwd: '자금 비밀번호',
  trade: '',
  withdrawFail: '출금 실패',
  commitTime: '날짜',
  from: '에서',
  to: '에게',
  transferMoney: '투입금액',
  transferSuccess: '완전히 이전',
  transferError: '전송 실패',
  transferHistory: '이적기록',
  updateFinancePwd: '펀드 비밀번호 변경',
  confirmPwd: '비밀번호 확인',
  confirmPwdInput: '비밀번호 확인 입력',
  updatePwd: '비밀번호 변경',
  updatePwdSuccess: '완전히 변경',
  updatePwdError: '변경 실패',
  noAlike: '비밀번호가 일치하지 않습니다',
  emailUpdate: '이메일 주소 변경',
  loginPwdUpdate: '로그인 비밀번호 변경',
  financePwdUpdate: '펀드 비밀번호 변경',
  newEmail: '새로운 이메일 주소',
  emailUpdateTip: '새 이메일 주소 입력',
  checkEmail: '이메일 주소 확인',
  updateEmail: '이메일 주소 변경',
  updateEmailError: '변경 실패',
  updateEmailSuccess: '완전히 변경',
  checkError: '확인 실패',
  pwdLen: '8-20자',
  inviteUrl: '관련 링크',
  myInviteCode: '초대 코드',
  fastInvite: '지금 초대하세요',
  myInvite: '초대 목록',
  registerTime: '날짜',
  bulletin: '발표',
  inviteFriend: '친구를 초대',
  saveAccount: '계정 및 비밀번호 저장',
  indicator: '지표',
  type: '종류',
  amount: '양',
  scanError: '이미지 인식 실패',
  scanInitErr: '초기화 실패',
  noCamera: '카메라 권한 없음',
  secondTip: '금액은 {tip} 이상이어야 합니다.',
  completePrice: '가격',
  profitText: '이익',
  profitAndLoss: '예상 손익',
  timeUp: '시간 최대',
  balanceText: '역사',
  closeAll: '모두 닫기',
  assetRate: '마진율',
  cancel: '취소',
  cancelInfo: '취소하시겠습니까?',
  buyCurrency: '상징',
  closeCurrency: '통화',
  buyPrice: '가격',
  valuation: '평가',
  profitP: '생산하다',
  buySuccess: '성공적으로',
  swiperTitle1: '세계 최고의 디지털 암호화폐 거래소',
  swiperSub1: '100,000명 이상의 사용자가 신뢰하는',

  ieoBuying: '전진',
  ieoOpen: '시작일 (UTC-5)',
  ieoClose: '완료 날짜(UTC-5)',
  about: '에 대한',
  ieoPlan: '분포',
  ieoInfo: '정보',
  ieoContact: '소셜 미디어',
  ieoStart: '초기화',
  ieoBuyStart: 'IEO 시작',
  ieoBuyEnd: 'IEO 마치다',
  ieoIsTrade: '시작하다',
  introduce: '소개',
  ieoSubBuy: 'IEO',
  subBuyHistory: 'IEO 역사',
  rechargeTip1: '1. 이것은 귀하의 최신 고유 주소이며, 플랫폼은 귀하에게 다른 계정으로 자금을 이체하거나 인증 코드를 요청하지 않습니다.',
  rechargeTip2: '2. 보증금은 확인을 위해 최소 10개의 네트워크 블록이 필요합니다. 확인 후 귀하의 계정에 자금이 자동으로 입금됩니다.',
  withdrawTip1: '1. 본 출금은 입금 측과 동일한 네트워크를 선택하고 수신 플랫폼이 네트워크를 지원하는지 확인하십시오. 그렇지 않으면 자산을 잃게 됩니다.',
  withdrawTip2: '2. 네트워크 차단은 플랫폼이 귀하의 신청을 승인한 후 귀하의 출금을 확인합니다.',
  withdrawTip3: '3. 출금은 확인을 위해 최소 10개의 네트워크 블록이 필요합니다. 확인 후 귀하의 계정에 자금이 자동으로 입금됩니다.',
  coming: '곧 출시될 예정입니다.',
  createTeam: '준비금',
  angelFund: '엔젤펀드',
  ecology: '기관 및 생태계',
  presale: '사전 판매 릴리스',
  mechanism: '채굴 메커니즘',
  trading: '교환 거래',

  issuePrice: '발행가',
  minBuyPrice: '최소 가입',
  buyNum: '일일 구독',
  buyTotal: '총 일일 구독',
  Increment: '구독 증가',
  blockchain: '블록체인',

  sellTotal: '총 발행량',
  currentBuy: '현재 구독',
  lessMinTip: '최소 금액보다 작을 수 없습니다.',
  stakeAccount: '스테이킹 계정',
  serviceAccount: '서비스 계정',
  lock: '잠그다',
  lockPrice: '잠긴 가격',
  ieoHistory: 'IEO 역사',
  lockHistory: '잠금 기록',
  sellHistory: '판매 내역',
  stake: '스테이킹',
  service: '서비스',
  ieoBuyTip: '*IEO 알림: LTJ의 수는 제한되어 있습니다. 사용자의 IEO 신청을 검토하고 3시간마다 무작위로 추첨합니다. 상대적으로 금액이 많은 사용자는 LTJ를 구매할 확률이 더 높아집니다. 1인당 1일 최대 3회까지 구매할 수 있습니다.',
  ieoLockTip: '*잠금 가격: 사용자는 LTJ의 가격을 잠그기 위해 USDT를 담보 통화로 사용해야 하며, 담보 비율은 LTJ 가치의 30%입니다. LTJ가 출시된 후 사용자는 당시 고정된 가격으로 LTJ를 판매할 수 있습니다.',
  ieoSellTip: '*IEO 판매: LTJ 판매는 LTJ 가치의 10%를 서비스 수수료로 요구합니다. LTJ 매도 후 교환한 USDT는 20일 후 동결 해제되며, 약정한 USDT는 20일 후 동결 해제됩니다.',
  whitePaper: '백지',
  cost: '비용',
  currentEstimate: '현재 평가',
  stakeNum: '스테이킹 금액',
  estimate: '평가',
  consume: '소비하다',

  seedSell: '시드 라운드',
  privateSell: '개인 라운드',
  openSell: 'KOL 라운드',
  ieoAward: '공개 라운드',
  ieoGrowUp: '팀',
  partner: '고문',
  consultant: '마케팅',
  ieoName: '유동성',
  conAward: 'AI 수익 창출',
  staking: '스테이킹',

  release: '풀어 주다',
  closedTime: '폐관시간',
  remark: '주목',

  totalProfit: '총 이윤',
  transferIn: '전입',
  transferOut: '송금',
  equity: '순자산',
  
  homeremind1: '인기 있는 암호화폐',
  homeremind2: '더 많은 시장 보기',
  homeremind3: '마지막 가격(USD)',
  homeremind4: '24시간 변경',
  homeremind5: '행동',
  homeremind6: '더보기',
  homeremind7: "사용자의 최고의 선택",
  homeremind8: '24시간 거래량',
  homeremind9: '프라임 가상자산',
  homeremind10: '우리의 핵심 제품',
  homeremind11: '선택할 수 있는 다양한 거래 도구',
  homeremind12: '점',
  homeremind13: '700개 이상의 암호화폐 거래 가능',
  homeremind14: '선물 거래로 경험을 업그레이드하세요',
  homeremind15: 'NYECoins Earn',
  homeremind16: '업계 최고의 APY',
  homeremind17: '우리의 다양한 서비스',
  homeremind18: '다양한 서비스로 무한한 가능성을 탐험해보세요',
  homeremind19: 'IEO 구독',
  homeremind20: "최고의 사전 판매 암호화폐",
  homeremind21: 'VIP 고객 서비스',
  homeremind22: '7*24시간',
  homeremind23: '지역 사회',
  homeremind24: '우리와 함께 부의 기회를 찾아보세요',
  homeremind25: '이동 중에도 거래하세요. 언제 어디서나.',
  homeremind26: '우리 앱과 데스크톱 클라이언트로 최신 정보를 받아보세요.',
  homeremind27: '소셜 미디어에서 우리를 팔로우하세요',
  homeremind28: '최신 뉴스를 받아보세요',
  homeremind29: '거래를 유지하세요. 언제 어디서나.',
  homeremind30: '모바일 앱과 웹사이트 터미널을 통해 최신 소식을 받아보세요.',

  msb: '코인리는 미국 MSB에서 규제됩니다.',
  msb1: '신뢰할 수 있고 안전하며 빠릅니다.',
  checkNow: '지금 확인하세요',

  foundingTeam: '창립팀',
  advisorTeam: '자문팀',
  community: '지역 사회',

  phone: '전화 번호',
  phoneTip: '전화번호를 입력하세요',


  rebateAccount: '수수료 계정',
  rebateType: '유형',
  recommend: '추천',
  myRebate: '내 커미션',
  rebateRule: '커미션 규칙',
  yes: '예',
  no: '아니요',
  authName: '확인하다',
  countryName: '국가/지역',
  allCommission: '총 커미션',
  myClient: '내 고객',
  inviteUrl1: '관련 링크',
  inviteCode1: '추천 코드',
  commissioned: '수수료',

  swiperTitle2: '온라인 암호화폐 거래의 글로벌 리더. 암호화폐를 구매, 판매 및 보유하세요.',
  swiperSub2: '우리는 수백만 명의 글로벌 사용자의 디지털 자산 여정을 안내해 왔습니다.',

  homeremind31: '우리는 무엇을해야합니까?',
  homeremind32: '우리는 암호화폐 판매자와 일반 소비자 사이의 경계를 모호하게 만들고 싶습니다. 우리는 무료 입장을 제공하고 우리가 돈의 미래라고 믿는 것을 사람들이 이해하고 공유하도록 도움으로써 암호화폐를 대중화할 것입니다.',
  homeremind33: 'NYECOINS의 특징',
  homeremind34: 'NYECOINS는 중앙은행이나 단일 관리자 없이 공급업체와 구매자를 모아 명확하고 공정한 거래를 보장합니다.',
  homeremind35: '거래 비용 절감',
  homeremind36: '거래 지불 및 운영 비용이 낮은 고객이 사용할 수 있는 분산형 플랫폼입니다.',
  homeremind37: 'C2C 거래',
  homeremind38: 'NYECOINS를 사용하면 C2C 작업이 쉽고 빠르며 안전합니다. 플랫폼의 모든 클라이언트에게 BTC 또는 선택한 통화를 보냅니다.',
  homeremind39: '브랜드를 위한 프리미엄 툴킷',
  homeremind40: '우리는 기업에 고객을 알 수 있는 도구와 통찰력을 제공하여 소비자 타겟팅을 최적화했습니다.',
  homeremind41: '글로벌 파트너의 지원',
  homeremind42: 'NYECOINS 팀',
  homeremind43: '이 모든 일을 실현할 팀을 소개하겠습니다.',
  homeremind44: 'Mark Nichols',
  homeremind45: '공동 창업자',
  homeremind46: 'Edward Blankenship',
  homeremind47: '수석 소프트웨어 엔지니어',
  homeremind48: 'Richard Norman',
  homeremind49: '커뮤니티 매니저',
  homeremind50: 'Paul Garrett',
  homeremind51: 'UI/UX 디자이너',


  ieoIntro1: '유동성 인센티브',
  ieoIntro2: '스테이킹 준비금',
  ieoIntro3: '생태계 파트너',
  ieoIntro4: '공개 판매',
  ieoIntro5: '프라이빗 세일 A',
  ieoIntro6: '시드 라운드',
  ieoIntro7: 'LinkToJoin 팀',
  ieoIntro8: '기업 수수료 준비금',
}