import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

import TabView from '@/views/TabView.vue'
import HomeTab from '@/views/tabs/HomeTab.vue'
// import MarketsTab from '@/views/tabs/MarketsTab.vue'
import TradeTab from '@/views/tabs/TradeTab.vue'
import FuturesTab from '@/views/tabs/FuturesTab.vue'
import BalancesTab from '@/views/tabs/BalancesTab.vue'
import LoginView from '@/views/LoginView.vue'
import RegisterView from '@/views/RegisterView.vue'
import UserView from '@/views/UserView.vue'
import RechargeView from '@/views/RechargeView.vue'
import WithdrawView from '@/views/WithdrawView.vue'
import TransferView from '@/views/TransferView.vue'
import KLineView from '@/views/KLineView.vue'
import IEOTab from '@/views/tabs/IEOTab.vue'
import MyRecommend from '@/views/MyRecommend.vue'

import { getUserInfo } from '@/apis'
import { Toast } from 'vant'
import 'vant/es/toast/style'

const userStatus = (next) => {
  getUserInfo().then(res => {
    if (res.code === 200 && res.data) {
      if (Number(res.data.user_auth) === 3) {
        next()
        return
      }
      router.push({ name: 'identityAuth' })
    }
  }).catch(() => {
    Toast({ message: 'server error', position: 'bottom' })
  })
}

const routes = [
  {
    path: '/',
    name: 'tab',
    redirect: '/home',
    component: TabView,
    children: [
      {
        path: '/home',
        name: 'home',
        component: HomeTab
      },
      // {
      //   path: '/markets',
      //   name: 'markets',
      //   component: MarketsTab
      // },
      {
        path: '/trade',
        name: 'trade',
        component: TradeTab
      },
      {
        path: '/futures',
        name: 'futures',
        component: FuturesTab
      },
      {
        path: '/balances',
        name: 'balances',
        component: BalancesTab
      },
      {
        path: '/ieo',
        name: 'ieo',
        component: IEOTab,
        beforeEnter: (to, form, next) => {
          if (store.state.token === '') {
            if(to.fullPath != '/ieo' && to.fullPath != '/futures'){
              router.push({ name: 'login' })
              return
            }
          }
          next()
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/user',
    name: 'user',
    component: UserView
  },
  {
    path: '/auth/info',
    name: 'authInfo',
    component: () => import('@/views/AuthInfo.vue')
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: RechargeView
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    component: WithdrawView,
    beforeEnter: (to, form, next) => {
      userStatus(next)
    }
  },
  {
    path: '/transfer',
    name: 'transfer',
    component: TransferView,
    // beforeEnter: (to, form, next) => {
    //   userStatus(next)
    // }
  },
  {
    path: '/recommend',
    name: 'recommend',
    component: MyRecommend,
    beforeEnter: (to, form, next) => {
      userStatus(next)
    }
  },
  {
    path: '/invite',
    name: 'invite',
    component: () => import('@/views/InviteView.vue')
  },
  {
    path: '/order/CT',
    name: 'CTOrder',
    component: () => import('@/views/order/CTOrder.vue')
  },
  {
    path: '/order/SF',
    name: 'SFOrder',
    component: () => import('@/views/order/SFOrder.vue')
  },
  {
    path: '/order/F',
    name: 'FOrder',
    component: () => import('@/views/order/FOrder.vue')
  },
  {
    path: '/balance/history',
    name: 'balanceHistory',
    component: () => import("@/views/BalanceHistory.vue")
  },
  {
    path: '/recharge/history',
    name: 'rechargeHistory',
    component: () => import("@/views/RechargeHistory.vue")
  },
  {
    path: '/withdraw/result',
    name: 'withdrawRes',
    component: () => import("@/views/WithdrawRes")
  },
  {
    path: '/withdraw/history',
    name: 'withdrawHistory',
    component: () => import("@/views/WithdrawHistory.vue")
  },
  {
    path: '/transfer/history',
    name: 'transferHistory',
    component: () => import("@/views/TransferHistory.vue")
  },
  {
    path: '/identity/auth',
    name: 'identityAuth',
    component: () => import('@/views/IdentityAuth.vue')
  },
  {
    path: '/password/manage',
    name: 'passwordManage',
    component: () => import('@/views/PasswordManage.vue')
  },
  {
    path: '/email/update',
    name: 'emailUpadet',
    component: () => import('@/views/update/LoginEmail.vue')
  },
  {
    path: '/password/update',
    name: 'loginPwd',
    component: () => import('@/views/update/LoginPwd.vue')
  },
  {
    path: '/finance/update',
    name: 'financePwd',
    component: () => import('@/views/update/FinancePwd.vue')
  },
  {
    path: '/kline',
    name: 'kline',
    component: KLineView,
    beforeEnter: (to, form, next) => {
      if (to.query.key === 'WTCUSDT' && store.state.token === '') {
        router.push({ name: 'login' })
        return
      }
      next()
    }
  },
  {
    path: '/bulletin/detail',
    name: 'bulletinDetail',
    component: () => import('@/views/BulletinDetail.vue')
  },
  {
    path: '/bulletin/list',
    name: 'bulletinList',
    component: () => import('@/views/BulletinList.vue')
  },
  {
    path: '/about/us',
    name: 'aboutUs',
    component: () => import('@/views/AboutUs.vue')
  },
  {
    path: '/agree/detail',
    name: 'agree',
    component: () => import('@/views/AgreeView.vue')
  },
  {
    path: '/agree/list',
    name: 'agreeList',
    component: () => import('@/views/AgreeList.vue')
  },
  {
    path: '/ios/download',
    name: 'iosDownload',
    component: () => import('@/views/IosDownload.vue')
  },
  { path: '/:pathMatch(.*)*', redirect: '/home' },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
