import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-c308adde"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "pwd-popup"
};
const _hoisted_2 = {
  class: "header"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  class: "content"
};
const _hoisted_5 = {
  class: "btn-area"
};
import { ref, watch } from 'vue';
import { useI18n } from "vue-i18n";
export default {
  __name: 'TradePwd',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: '--'
    }
  },
  emits: ['hide', 'tradeCommit'],

  setup(__props, {
    emit
  }) {
    const props = __props;
    const {
      t
    } = useI18n();
    const tradePwd = ref('');
    watch(() => props.show, () => {
      if (!props.show) {
        tradePwd.value = '';
      }
    });
    return (_ctx, _cache) => {
      const _component_a_input_password = _resolveComponent("a-input-password");

      const _component_van_button = _resolveComponent("van-button");

      const _component_van_popup = _resolveComponent("van-popup");

      return _openBlock(), _createBlock(_component_van_popup, {
        show: props.show,
        position: "bottom",
        round: "",
        onClickOverlay: _cache[3] || (_cache[3] = $event => emit('hide'))
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_unref(t)('financePwd')), 1), _createElementVNode("div", {
          class: "close",
          onClick: _cache[0] || (_cache[0] = $event => emit('hide'))
        }, _toDisplayString(_unref(t)('cancelText')), 1)]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_a_input_password, {
          class: "pwd-input",
          value: tradePwd.value,
          "onUpdate:value": _cache[1] || (_cache[1] = $event => tradePwd.value = $event),
          placeholder: _unref(t)('financePwdTip'),
          bordered: false
        }, null, 8, ["value", "placeholder"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_van_button, {
          color: "#007AFC",
          block: "",
          onClick: _cache[2] || (_cache[2] = $event => emit('tradeCommit', tradePwd.value)),
          loading: props.loading,
          disabled: tradePwd.value === '',
          style: {
            "--van-button-default-height": "42px",
            "--van-button-border-radius": "4px"
          }
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(props.text), 1)]),
          _: 1
        }, 8, ["loading", "disabled"])])])]),
        _: 1
      }, 8, ["show"]);
    };
  }

};