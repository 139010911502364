import "core-js/modules/es.array.push.js";
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "home"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  key: 0,
  style: {
    "text-align": "center"
  }
};
const _hoisted_4 = {
  key: 1,
  style: {
    "text-align": "center"
  }
};
import { ref, onActivated, onUpdated } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import store from '@/store';
import { useI18n } from "vue-i18n";
export default {
  __name: 'TabView',

  setup(__props) {
    const router = useRouter();
    const route = useRoute();
    const {
      t
    } = useI18n();
    const active = ref();
    const tabbarShow = ref(true);
    const tabMenu = [{
      icon: require('../assets/icon/home.png'),
      active: require('../assets/icon/home_active.png'),
      key: 'home'
    }, // {
    //   icon: require('../assets/icon/markets.png'),
    //   active: require('../assets/icon/markets_active.png'),
    //   key: 'market'
    // },
    {
      icon: require('../assets/icon/trades.png'),
      active: require('../assets/icon/trades_active.png'),
      key: 'currency'
    }, // {
    //   icon: require('../assets/icon/ieo.png'),
    //   active: require('../assets/icon/ieo_active.png'),
    //   key: 'ieo'
    // },
    {
      icon: require('../assets/icon/futures.png'),
      active: require('../assets/icon/futures_active.png'),
      key: 'contract'
    }, {
      icon: require('../assets/icon/wallets.png'),
      active: require('../assets/icon/wallets_active.png'),
      key: 'wallet'
    }];

    const changeTab = index => {
      switch (index) {
        case 0:
          router.push({
            name: 'home'
          });
          break;
        // case 1:
        //   router.push({ name: 'markets' })
        //   break

        case 1:
          router.push({
            name: 'trade'
          });
          break;
        // case 2:
        //   router.push({ name: 'ieo' })
        //   break

        case 2:
          router.push({
            name: 'futures'
          });
          break;

        case 3:
          if (store.state.token === '') {
            router.push({
              name: 'login'
            });
            break;
          } else {
            router.push({
              name: 'balances'
            });
            break;
          }

      }
    };

    onActivated(() => {
      switch (route.name) {
        case 'home':
          active.value = 0;
          break;
        // case 'markets':
        //   active.value = 1
        //   break

        case 'trade':
          active.value = 1;
          break;
        // case 'ieo':
        //   active.value = 2
        //   break

        case 'futures':
          active.value = 2;
          break;

        case 'balances':
          if (store.state.token === '') {
            router.replace({
              name: 'login'
            });
            break;
          } else {
            active.value = 3;
            break;
          }

      }
    });
    onUpdated(() => {
      switch (route.name) {
        case 'home':
          active.value = 0;
          break;
        // case 'markets':
        //   active.value = 1
        //   break

        case 'trade':
          active.value = 1;
          break;
        // case 'ieo':
        //   active.value = 2
        //   break

        case 'futures':
          active.value = 2;
          break;

        case 'balances':
          if (store.state.token === '') {
            router.replace({
              name: 'login'
            });
            break;
          } else {
            active.value = 3;
            break;
          }

      }
    });

    const tabbarShowChange = show => {
      tabbarShow.value = show;
    };

    const tabbarIndexChange = index => {
      active.value = index;
    };

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      const _component_van_tabbar_item = _resolveComponent("van-tabbar-item");

      const _component_van_tabbar = _resolveComponent("van-tabbar");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view, {
        onTabbarShowChange: tabbarShowChange,
        onTabbarIndexChange: tabbarIndexChange
      }, {
        default: _withCtx(({
          Component
        }) => [(_openBlock(), _createBlock(_KeepAlive, null, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1024))]),
        _: 1
      }), _withDirectives(_createVNode(_component_van_tabbar, {
        modelValue: active.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => active.value = $event),
        onChange: changeTab,
        "safe-area-inset-bottom": true,
        "z-index": "100",
        placeholder: "",
        style: {
          "--van-tabbar-item-active-color": "#1A5DA1"
        }
      }, {
        default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(tabMenu, (item, index) => {
          return _createVNode(_component_van_tabbar_item, {
            key: index
          }, {
            icon: _withCtx(props => [_createElementVNode("img", {
              src: props.active ? item.active : item.icon
            }, null, 8, _hoisted_2)]),
            default: _withCtx(() => [item.key === 'ieo' ? (_openBlock(), _createElementBlock("div", _hoisted_3, "IEO")) : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_unref(t)(item.key)), 1))]),
            _: 2
          }, 1024);
        }), 64))]),
        _: 1
      }, 8, ["modelValue"]), [[_vShow, tabbarShow.value]])]);
    };
  }

};