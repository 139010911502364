import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-0bb11cd8"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "login-container"
};
const _hoisted_2 = {
  style: {
    "background-color": "#F6F6F6"
  }
};
const _hoisted_3 = {
  class: "login-content"
};
const _hoisted_4 = {
  class: "title"
};
const _hoisted_5 = {
  style: {
    "margin-left": "6px",
    "font-weight": "700"
  }
};
const _hoisted_6 = {
  class: "form-label"
};
const _hoisted_7 = {
  class: "form-label",
  style: {
    "margin-top": "23px"
  }
};
const _hoisted_8 = {
  class: "forget-password"
};
const _hoisted_9 = {
  class: "agreement"
};
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import NavGradient from '@/components/NavGradient.vue';
import LangChange from '@/components/LangChange.vue';
import { Toast } from 'vant';
import 'vant/es/toast/style';
import { getCaptcha, loginApi } from '@/apis';
import { useI18n } from "vue-i18n";
import moment from 'moment';
import { encrypt, decrypt } from '@/util/saveAccount';
export default {
  __name: 'LoginView',

  setup(__props) {
    const {
      t,
      setLocaleMessage,
      locale
    } = useI18n();
    const router = useRouter();
    const store = useStore();
    const email = ref('');
    const password = ref('');
    const captcha = ref('123123');
    const loading = ref(false);
    const captchaId = ref('');
    const captchaImg = ref('');
    const saveAccount = ref(false);

    const langSelect = lang => {
      localStorage.setItem('websetlang', lang);
      store.commit('langChange', lang);

      try {
        const msg = require(`@/language/lang/${lang}`).default;

        setLocaleMessage(lang, msg);
        locale.value = lang;
        init();
      } catch (err) {
        console.warn(err);
      }
    };

    const init = async () => {
      const res = await getCaptcha();

      if (res.code === 200) {
        captchaId.value = res.data.id;
        captchaImg.value = res.data.img;
      }
    };

    onMounted(() => {
      const str = localStorage.getItem('astore_save');

      if (str && str !== '') {
        const data = decrypt(str);

        try {
          const msg = JSON.parse(data);
          email.value = msg.a ? msg.a : '';
          password.value = msg.m ? msg.m : '';
          saveAccount.value = true;
        } catch (err) {
          console.warn(err);
        }
      }

      init();
    });

    const login = async () => {
      loading.value = true;
      const res = await loginApi({
        captcha_id: captchaId.value,
        captcha_code: captcha.value,
        account: email.value,
        password: password.value
      });
      loading.value = false;

      if (res.code !== 200) {
        Toast({
          message: res.message !== '' ? res.message : t('loginError'),
          position: 'bottom'
        });
        init();
        return;
      }

      if (res.data.token) {
        store.commit('tokenChange', res.data.token);
        store.commit('emailChange', email.value);
        store.commit('getUser');
        localStorage.setItem('astore_info', JSON.stringify({
          token: res.data.token,
          time: moment().unix(),
          email: email.value
        }));

        if (saveAccount.value) {
          const str = encrypt({
            a: email.value,
            m: password.value
          });
          localStorage.setItem('astore_save', str);
        } else {
          localStorage.removeItem('astore_save');
        }
      }

      Toast({
        message: t('loginSuccess'),
        position: 'bottom'
      });
      router.replace({
        name: 'home'
      });
    };

    return (_ctx, _cache) => {
      const _component_a_input = _resolveComponent("a-input");

      const _component_a_input_password = _resolveComponent("a-input-password");

      const _component_van_checkbox = _resolveComponent("van-checkbox");

      const _component_van_button = _resolveComponent("van-button");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(NavGradient, null, {
        default: _withCtx(() => [_createVNode(LangChange, {
          onSelect: langSelect
        })]),
        _: 1
      }), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", null, [_createElementVNode("h2", _hoisted_4, [_createTextVNode(_toDisplayString(_unref(t)('welcome')), 1), _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(store).state.webName), 1)]), _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(t)('loginEmail')), 1), _createVNode(_component_a_input, {
        style: {
          "height": "39px",
          "font-size": "15px"
        },
        allowClear: "",
        placeholder: _unref(t)('loginEmailInput'),
        value: email.value,
        "onUpdate:value": _cache[0] || (_cache[0] = $event => email.value = $event)
      }, null, 8, ["placeholder", "value"]), _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(t)('loginPwd')), 1), _createVNode(_component_a_input_password, {
        style: {
          "height": "39px",
          "font-size": "15px"
        },
        allowClear: "",
        placeholder: _unref(t)('loginPwdTip'),
        value: password.value,
        "onUpdate:value": _cache[1] || (_cache[1] = $event => password.value = $event)
      }, null, 8, ["placeholder", "value"]), _createVNode(_component_van_checkbox, {
        modelValue: saveAccount.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => saveAccount.value = $event),
        shape: "square",
        "icon-size": "15",
        style: {
          "margin-top": "15px"
        },
        "checked-color": "rgb(171,84,219)"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(t)('saveAccount')), 1)]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_van_button, {
        color: "rgb(171,84,219)",
        block: "",
        onClick: login,
        disabled: email.value === '' || password.value === '' || captcha.value === '',
        style: {
          "margin": "30px 0 20px 0",
          "--van-button-default-height": "44px",
          "font-size": "17px"
        },
        loading: loading.value
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(t)('login')), 1)]),
        _: 1
      }, 8, ["disabled", "loading"]), _createElementVNode("div", _hoisted_8, [_createElementVNode("span", {
        onClick: _cache[3] || (_cache[3] = $event => _unref(router).replace({
          name: 'register'
        }))
      }, _toDisplayString(_unref(t)('register')), 1), _createElementVNode("span", {
        onClick: _cache[4] || (_cache[4] = $event => _unref(router).push({
          name: 'loginPwd'
        }))
      }, _toDisplayString(_unref(t)("forgetPwd")), 1)])]), _createElementVNode("p", _hoisted_9, [_createTextVNode(_toDisplayString(_unref(t)('loginTip')) + " ", 1), _createElementVNode("span", {
        style: {
          "color": "rgb(171,84,219)",
          "letter-spacing": "0px"
        },
        onClick: _cache[5] || (_cache[5] = $event => _unref(router).push({
          name: 'agreeList'
        }))
      }, _toDisplayString(_unref(t)('agree')), 1)])])])]);
    };
  }

};