import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '../assets/icon/menu_unfold.png';
import _imports_1 from '../assets/icon/trend.png';

const _withScopeId = n => (_pushScopeId("data-v-4f1a7aba"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "trade-option-header"
};
const _hoisted_2 = {
  class: "option-header-l"
};
const _hoisted_3 = {
  class: "header-name"
};
const _hoisted_4 = {
  class: "option-header-r"
};
import { ref } from 'vue';
export default {
  __name: 'TradeOptionHeader',
  props: {
    tradeName: {
      type: String
    },
    numShow: {
      type: Boolean,
      default: true
    },
    listShow: {
      type: Boolean,
      default: true
    },
    trendNum: {
      type: String
    },
    menuShow: {
      type: Boolean,
      default: false
    },
    actionList: {
      type: Array
    },
    up: {
      type: Boolean,
      default: false
    }
  },
  emits: ["menuSelect", "showProudct", "kline"],

  setup(__props, {
    emit
  }) {
    const props = __props;
    const showPopover = ref(false);

    const onSelect = (action, index) => {
      emit("menuSelect", index);
    };

    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");

      const _component_van_popover = _resolveComponent("van-popover");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [__props.listShow ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        onClick: _cache[0] || (_cache[0] = $event => emit('showProudct')),
        class: "menu-fold",
        src: _imports_0
      })) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, _toDisplayString(props.tradeName), 1), props.numShow ? (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "header-trend",
        style: _normalizeStyle({
          backgroundColor: props.up ? '#F0F5F5' : '#FBF3F1',
          color: props.up ? '#04AC92' : '#D04C64'
        })
      }, _toDisplayString(props.trendNum) + "% ", 5)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
        class: "trend-icon",
        src: _imports_1,
        onClick: _cache[1] || (_cache[1] = $event => emit('kline'))
      }), __props.menuShow ? (_openBlock(), _createBlock(_component_van_popover, {
        key: 0,
        show: showPopover.value,
        "onUpdate:show": _cache[2] || (_cache[2] = $event => showPopover.value = $event),
        actions: props.actionList,
        onSelect: onSelect,
        placement: "bottom-end",
        "show-arrow": false,
        overlay: ""
      }, {
        reference: _withCtx(() => [props.menuShow ? (_openBlock(), _createBlock(_component_van_icon, {
          key: 0,
          class: "menu-btn",
          name: "weapp-nav"
        })) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["show", "actions"])) : _createCommentVNode("", true)])]);
    };
  }

};