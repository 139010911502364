import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-0db6205e"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = {
  class: "header"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  class: "info"
};
const _hoisted_5 = {
  class: "tag",
  style: {
    "--color": "#D04C64",
    "--bColor": "#FBF3F1"
  }
};
const _hoisted_6 = {
  class: "date"
};
const _hoisted_7 = {
  class: "label"
};
const _hoisted_8 = {
  class: "num"
};
const _hoisted_9 = {
  class: "label"
};
const _hoisted_10 = {
  class: "num"
};
const _hoisted_11 = {
  class: "label"
};
const _hoisted_12 = {
  class: "num"
};
const _hoisted_13 = {
  class: "label"
};
const _hoisted_14 = {
  class: "num"
};
import { ref, onMounted } from 'vue';
import store from '@/store';
import { ieoSellList } from '@/apis';
import BigNumber from 'bignumber.js';
import { fmtNumber } from '@/util';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'IEOSellList',
  props: {
    coinId: {
      type: Number,
      default: 0
    }
  },

  setup(__props, {
    expose
  }) {
    const props = __props;
    const {
      t
    } = useI18n();
    const finished = ref(true);
    const loading = ref(false);
    let page = 1;
    const list = ref([]);

    const init = async (index = 0) => {
      if (store.state.token === '') {
        return;
      }

      loading.value = true;
      let loadPage = index;

      if (index < 1) {
        loadPage = page + 1;
      }

      ieoSellList({
        coin_id: props.coinId,
        page: loadPage
      }).then(res => {
        if (res.code === 200 && res.data && res.data.data) {
          if (index < 1) {
            page += 1;
            list.value = [...list.value, ...fmtTableData(res.data.data.list)];
          } else {
            page = 1;
            list.value = fmtTableData(res.data.data.list);
          }

          if (res.data.data.count > list.value.length) {
            finished.value = false;
          } else {
            finished.value = true;
          }
        }
      }).finally(() => {
        loading.value = false;
      });
    };

    const fmtTableData = arr => {
      return arr?.map(item => {
        return {
          id: item.sell_id,
          date: item.sell_create_time,
          sellType: item.coin.coin_symbol,
          fee: fmtNumber(item.sell_service_fee),
          price: fmtNumber(item.sell_price),
          num: fmtNumber(item.sell_amount),
          estimate: BigNumber(item.sell_price).multipliedBy(item.sell_amount).toNumber(),
          status: item.sell_status
        };
      });
    };

    onMounted(() => {
      init(1);
    });
    expose({
      init
    });
    return (_ctx, _cache) => {
      const _component_van_col = _resolveComponent("van-col");

      const _component_van_row = _resolveComponent("van-row");

      const _component_van_empty = _resolveComponent("van-empty");

      const _component_van_list = _resolveComponent("van-list");

      return _openBlock(), _createBlock(_component_van_list, {
        class: "ieo-sell-list",
        finished: finished.value,
        loading: loading.value,
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => loading.value = $event),
        "finished-text": _unref(t)('finished'),
        "loading-text": _unref(t)('loading'),
        onLoad: init
      }, {
        default: _withCtx(() => [list.value.length > 0 ? (_openBlock(true), _createElementBlock(_Fragment, {
          key: 0
        }, _renderList(list.value, item => {
          return _openBlock(), _createElementBlock("div", {
            key: item.id,
            class: "sell-item"
          }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(item.sellType), 1)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(_unref(t)('sell')), 1), _createElementVNode("div", _hoisted_6, _toDisplayString(item.date), 1)]), _createVNode(_component_van_row, {
            gutter: "8"
          }, {
            default: _withCtx(() => [_createVNode(_component_van_col, {
              span: "6",
              class: "data-item"
            }, {
              default: _withCtx(() => [_createElementVNode("div", _hoisted_7, _toDisplayString(_unref(t)('fee')) + "(USDT)", 1), _createElementVNode("div", _hoisted_8, _toDisplayString(item.fee), 1)]),
              _: 2
            }, 1024), _createVNode(_component_van_col, {
              span: "6",
              class: "data-item",
              style: {
                "text-align": "center"
              }
            }, {
              default: _withCtx(() => [_createElementVNode("div", _hoisted_9, _toDisplayString(_unref(t)('price')), 1), _createElementVNode("div", _hoisted_10, _toDisplayString(item.price), 1)]),
              _: 2
            }, 1024), _createVNode(_component_van_col, {
              span: "6",
              class: "data-item",
              style: {
                "text-align": "center"
              }
            }, {
              default: _withCtx(() => [_createElementVNode("div", _hoisted_11, _toDisplayString(_unref(t)('number')) + "(LTJ)", 1), _createElementVNode("div", _hoisted_12, _toDisplayString(item.num), 1)]),
              _: 2
            }, 1024), _createVNode(_component_van_col, {
              span: "6",
              class: "data-item",
              style: {
                "text-align": "right"
              }
            }, {
              default: _withCtx(() => [_createElementVNode("div", _hoisted_13, _toDisplayString(_unref(t)('estimate')) + "(USDT)", 1), _createElementVNode("div", _hoisted_14, _toDisplayString(item.estimate), 1)]),
              _: 2
            }, 1024)]),
            _: 2
          }, 1024)])]);
        }), 128)) : (_openBlock(), _createBlock(_component_van_empty, {
          key: 1,
          description: _unref(t)('nothing')
        }, null, 8, ["description"]))]),
        _: 1
      }, 8, ["finished", "loading", "finished-text", "loading-text"]);
    };
  }

};