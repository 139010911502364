import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './language/i18n'

if (window.innerWidth > 800) {
  if (window.location.pathname === '/register') {
    const strArr = window.location.href.split('?')
    let url = 'https://pc.nyecoins.com/register'
    if (strArr[1]) {
      url = `${url}?${strArr[1]}`
    }
    window.open(url, '_self')
  } else if (window.location.pathname === '/') {
    const strArr = window.location.href.split('?')
    let url = 'https://pc.nyecoins.com/home'
    if (strArr[1]) {
      url = `${url}?${strArr[1]}`
    }
    window.open(url, '_self')
  } else {
    window.open('https://pc.nyecoins.com/home', '_self')
  }
} else {
    createApp(App).use(store).use(router).use(i18n).mount('#app')
}

