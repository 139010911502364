export default {
  required: '必須',
  home: '始める',
  market: 'マーケット',
  currency: 'トレード',
  walletTrade: 'スポット',
  contract: '契約する',
  wallet: '財布',
  pulling: 'プルダウンして更新...',
  loosing: 'リリースして更新...',
  loading: '読み込んでいます...',
  loadingError: '読み込みに失敗しました',
  nothing: 'データなし',
  finished: '',
  price: '価格',
  number: '量',
  recharge: 'デポジット',
  transfer: '移行',
  invite: '招待',
  inviteDes: '友達を招待して参加してください',
  contact: 'お問い合わせ',
  contactDes: '7*24 オンライン',
  fastTrade: 'オプション',
  name: '名前',
  fastPrice: '価格',
  trend: '24H %',
  all: '全て',
  growthList: '値上がり者',
  loserList: '敗者',
  vol: '24H Vol',
  buy: '買う',
  sell: '売る',
  optimal: '市場価格',
  marketOrder: '市場取引',
  limitOrder: 'リミットトレード',
  useable: '利用可能',
  tradeVol: '額',
  entrust: '指値注文',
  entrustTotal: '額',
  noLogin: 'ログアウト, ',
  toLogin: 'ここでログイン',
  cancelOrder: 'キャンセル',
  cancelError: 'キャンセルできませんでした',
  cancelSuccess: 'キャンセルしました',
  cancelTip: 'キャンセルしますか？',
  completed: '完了',
  canceled: 'キャンセル',
  marketPrice: '市場',
  limitPrice: 'リミット',
  entrustPrice: '価格',
  entrustNum: '量',
  currencyType: 'シンボル',
  headerCurrencyType: 'シンボル',
  currencyOrder: '取引実績',
  history: '歴史',
  tryAgain: '再試行',
  entrustPriceTip: '投入価格',
  tradeNumTip: '投入量',
  tradeNumLess: '数量は 0 より大きくする必要があります',
  numLessTip: '残高不足です',
  tradePriceTip: '投入量',
  balanceTip: '残高不足です',
  tradeError: '取引に失敗しました',
  tradeSuccess: '取引成功',
  tip: '知らせ',
  tradeConfirm: '取引を確定しますか？',
  cancelText: 'キャンセル',
  confirmText: '確認',
  toBuy: '買い占めます',
  toSell: '空売り',
  confirmToBuy: '買い占めます',
  confirmToSell: '空売り',
  slPrice: 'SL 価格',
  sl: 'SL',
  tp: 'TP',
  tpPrice: 'TP 価格',
  currentOrder: '現在の注文',
  historyOrder: '歴史',
  setting: '設定',
  unwind: '近い',
  handUnwind: '手動で閉じる',
  futuresPay: '',
  forceUnwind: '清算',
  slTp: 'SL/TP',
  fastTradeUnwind: 'オプション',
  blowUp: '清算',
  openNum: '量',
  warrant: 'マージン',
  openPrice: '価格',
  fee: '費用',
  profit: '利益',
  lastPrice: '現在の価格',
  unwindPrice: '終値',
  unwindError: '閉じることができませんでした',
  unwindSuccess: '正常に閉じます',
  setSuccess: '正常に設定されました',
  setError: '設定に失敗しました',
  setSlTp: '設定 SL/TP',
  second: 's',
  multiple: '多数',
  direction: 'タイプ',
  tradeCurrency: 'シンボル',
  loss: '損失',
  fill: '勝つ',
  executePrice: '価格',
  currentPrice: '現在の価格',
  closePrice: '終値',
  secondNum: '限目',
  contractOrder: '先物注文',
  fastTradeOrder: 'オプション注文',
  overview: '概要',
  ieoBuy: 'IEO',
  balanceTotal: '総資産',
  withdraw: '撤回する',
  tradeOrder: 'トレードオーダー',
  rechargeHistory: '預金記録',
  withdrawHistory: '出金記録',
  freeze: 'フローズン',
  amountTo: '',
  tipText: '知らせ',
  ieoTip: 'IEO は現在開いていません。後でもう一度お試しください',
  financeList: '財務記録',
  currencyAccount: 'スポット口座',
  contractAccount: '先物口座',
  time: '時間',
  status: 'スターテス',
  balanceTyle: 'アカウント',
  pay: '結果',
  income: '所得',
  lastBalance: '残高',
  orderNo: 'ID',
  money: '額',
  date: '日にち',
  finallyIncome: '受け取り金額',
  commitMoney: '額',
  orderNum: 'ID',
  waitReview: 'レビュー中',
  reviewPass: '合格した',
  reviewReject: '却下',
  waitWallet: 'レビュー中',
  walletPass: '合格した',
  walletReject: '却下',
  withdrawSuccess: '出金成功',
  withdrawError: '転送に失敗しました',
  high24H: '24H 高い',
  low24H: '24H 低い',
  num24H: '24H Vol',
  minute: '分',
  hour: '時間',
  day: '日',
  week: '週',
  markets: '市場',
  open: '開ける',
  close: '近い',
  high: '高い',
  low: '低い',
  volume: 'Vol',
  fastLogin: 'ここでログイン',
  register: '登録',
  auth: '確認',
  authSuccess: '検証済み',
  noPass: '却下',
  authCommit: 'レビュー中',
  onAuth: '未確認',
  onLogin: 'ログアウト',
  securitySet: '安全',
  userAgree: 'ユーザーサポート',
  aboutUs: '私たちに関しては',
  logout: 'ログアウト',
  logoutSuccess: 'ログアウト成功',
  lookAuthInfo: '私の情報を確認する',
  authAgain: '再確認',
  authInfo: '情報',
  account: 'アカウント',
  countryArea: '国/地域',
  idNo: 'ID#',
  idNoTip: 'ID番号入力',
  email: '郵便',
  emailInput: 'メールアドレスを入力',
  setLoginPwd: 'ログインパスワードの設定',
  loginPwdInput: 'ログインパスワードを入力（8～20文字）',
  loginPwdTip: 'ログインパスワードを入力',
  loginPwdLen: 'ログインパスワードは 8 ～ 20 文字にする必要があります',
  setFinancePwd: 'ファンドのパスワードを設定する',
  financePwdInput: 'ファンドパスワードを入力（6～20文字）',
  financePwdTip: '資金パスワードを入力',
  financePwdLen: 'ファンドのパスワードは 6 ～ 20 文字にする必要があります',
  inviteCode: '招待コード',
  inviteCodeTip: '招待コードを入力',
  inviteInput: '招待コードが必要です',
  imageCode: 'Captcha',
  imageCodeInput: '入力キャプチャ',
  registerTip: '登録は同意することを意味します ',
  agree: '《ユーザー規約》',
  agreeText: 'ユーザー規約',
  next: '次',
  registerSuccess: '完全に登録する',
  registerError: '登録に失敗しました',
  securityCheck: '安全',
  checkCodeInput: '確認コードを入力',
  sendCheckCode: '送信',
  sendSuccess: '送信済',
  sendError: '送信できませんでした',
  welcome: 'へようこそ',
  loginEmail: '郵便',
  loginEmailInput: 'メールアドレスを入力',
  loginPwd: 'ログインパスワード',
  login: 'ログイン',
  forgetPwd: 'パスワードをお忘れですか',
  loginTip: 'ログインは同意したことを意味します',
  loginSuccess: '完全にログイン',
  loginError: 'ログインに失敗しました',
  fullname: '名前',
  fullnameTip: 'あなたの名前を入力してください',
  country: '国/地域',
  countryTip: '国・地域を入力',
  imgFront: '身分証明書の正面の写真をアップロードしてください',
  imgReverse: '身分証明書の裏面の写真をアップロードしてください',
  commit: '送信',
  commitSuccess: '完全に送信',
  commitError: '送信できませんでした',
  selectCurrency: '暗号を選択',
  rechargeNet: '通信網',
  rechargeAddress: '入金先',
  copyAddress: 'アドレスをコピー',
  minRecharge: '最低入金額',
  copySuccess: '完全にコピー',
  copyError: 'コピーできませんでした',
  withdrawTip: 'アカウント認証後、出金可能',
  withdrawAddress: '出金先住所',
  withdrawAddressInput: '出金アドレス入力',
  withdrawNet: '通信網',
  withdrawNum: '額',
  withdrawNumInput: '投入量',
  withdrawNumLess: '残高不足です',
  withdrawMin: '最低出金額',
  balanceUse: '利用可能',
  actualMoney: '受け取り金額',
  actualMoneyText: '受け取り金額',
  financePwd: 'ファンドのパスワード',
  trade: '',
  withdrawFail: '出金できませんでした',
  commitTime: '日にち',
  from: 'から',
  to: 'に',
  transferMoney: '投入量',
  transferSuccess: '完全転送',
  transferError: '転送に失敗しました',
  transferHistory: '譲渡記録',
  updateFinancePwd: 'ファンドのパスワードを変更する',
  confirmPwd: 'パスワードを認証する',
  confirmPwdInput: '確認パスワードを入力',
  updatePwd: 'パスワードを変更する',
  updatePwdSuccess: '完全に変更',
  updatePwdError: '変更できませんでした',
  noAlike: 'パスワードが一致しません',
  emailUpdate: 'メールアドレスを変更する',
  loginPwdUpdate: 'ログインパスワードの変更',
  financePwdUpdate: 'ファンドのパスワードを変更する',
  newEmail: '新しいメールアドレス',
  emailUpdateTip: '新しいメールアドレスを入力',
  checkEmail: 'メールアドレスの確認',
  updateEmail: 'メールアドレスを変更する',
  updateEmailError: '変更できませんでした',
  updateEmailSuccess: '完全に変更',
  checkError: '確認できませんでした',
  pwdLen: '8～20文字',
  inviteUrl: '紹介リンク',
  myInviteCode: '招待コード',
  fastInvite: '今すぐ招待',
  myInvite: '招待リスト',
  registerTime: '日にち',
  bulletin: '発表',
  inviteFriend: '友達を招待',
  saveAccount: 'アカウントとパスワードを保存する',
  indicator: '指標',
  type: 'の種類',
  amount: '額',
  scanError: '画像認識に失敗しました',
  scanInitErr: '初期化に失敗しました',
  noCamera: 'カメラの許可なし',
  secondTip: '金額は {tip} 以上である必要があります',
  completePrice: '価格',
  profitText: '利益',
  profitAndLoss: '推定損益',
  timeUp: 'タイムアップ',
  balanceText: '歴史',
  closeAll: 'すべて閉じる',
  assetRate: '証拠金率',
  cancel: 'キャンセル',
  cancelInfo: 'キャンセルを確認しますか？',
  buyCurrency: 'シンボル',
  closeCurrency: '通貨',
  buyPrice: '価格',
  valuation: '評価',
  profitP: '収率',
  buySuccess: '無事',
  swiperTitle1: '世界トップのデジタル暗号交換',
  swiperSub1: '100,000 人以上のユーザーから信頼されています',

  ieoBuying: '進行中',
  ieoOpen: '開始日 (UTC-5)',
  ieoClose: '終了日(UTC-5)',
  about: 'だいたい',
  ieoPlan: '分布',
  ieoInfo: '情報',
  ieoContact: 'ソーシャルメディア',
  ieoStart: '初期化',
  ieoBuyStart: 'IEO 始める',
  ieoBuyEnd: 'IEO 終了',
  ieoIsTrade: '発売',
  introduce: '序章',
  ieoSubBuy: 'IEO',
  subBuyHistory: 'IEO 歴史',
  rechargeTip1: '1. これはあなたの最新の一意のアドレスです。プラットフォームが資金を別のアカウントに転送したり、確認コードを要求したりすることは決してありません。',
  rechargeTip2: '2. デポジットには、確認のために少なくとも 10 個のネットワーク ブロックが必要です。 確認後、アカウントは自動的に資金を受け取ります。',
  withdrawTip1: '1. 入金側と同じネットワークをこの出金用に選択していることを確認し、受信プラットフォームがネットワークをサポートしていることを確認してください。 そうしないと、資産を失います。',
  withdrawTip2: '2. プラットフォームが申請を承認した後、ネットワークブロックはあなたの撤回を確認します.',
  withdrawTip3: '3. 引き出しには、確認のために少なくとも 10 個のネットワーク ブロックが必要です。 確認後、アカウントは自動的に資金を受け取ります。',
  coming: 'もうすぐです。',
  createTeam: '積立金',
  angelFund: 'エンジェルファンド',
  ecology: '制度と生態系',
  presale: '先行発売',
  mechanism: 'マイニングの仕組み',
  trading: '為替取引',

  issuePrice: '発行価格',
  minBuyPrice: '最小サブスクリプション',
  buyNum: '1 日あたりのサブスクリプション',
  buyTotal: '1 日の合計サブスクリプション',
  Increment: 'サブスクリプションの増分',
  blockchain: 'ブロックチェーン',

  sellTotal: '発行総額',
  currentBuy: '現在のサブスクリプション',
  lessMinTip: '最小金額未満にすることはできません。',
  stakeAccount: 'ステーキングアカウント',
  serviceAccount: 'サービス アカウント',
  lock: 'ロック',
  lockPrice: 'ロックされた価格',
  ieoHistory: 'IEOの歴史',
  lockHistory: 'ロック履歴',
  sellHistory: '売却履歴',
  stake: 'ステーキング',
  service: 'サービス',
  ieoBuyTip: '*IEO 注意: LTJ の数には制限があります。 ユーザーのIEO申請を審査し、3時間ごとにランダムに抽選を行います。 比較的多額の金額を持っているユーザーは LTJ を購入する可能性が高くなります。 各ユーザーは 1 日に 3 回まで購入できます。',
  ieoLockTip: '*価格のロック: LTJ の価格をロックするには、USDT を担保通貨として使用する必要があり、担保率は LTJ の価値の 30% です。 LTJ のローンチ後、ユーザーはその時点でロックされた価格で LTJ を販売できます。',
  ieoSellTip: '*IEO 販売: LTJ の販売には、サービス手数料として LTJ 価格の 10% が必要です。 LTJの売却後、交換したUSDTは20日で凍結解除され、差し入れたUSDTは20日で凍結解除されます。',
  whitePaper: '白書',
  cost: '料金',
  currentEstimate: '現在の評価',
  stakeNum: 'ステーキング量',
  estimate: '評価',
  consume: '消費',

  seedSell: 'シードラウンド',
  privateSell: 'プライベートラウンド',
  openSell: 'KOLラウンド',
  ieoAward: 'パブリックラウンド',
  ieoGrowUp: 'チーム',
  partner: 'アドバイザー',
  consultant: 'マーケティング',
  ieoName: '流動性',
  conAward: '稼ぐAI',
  staking: 'ステーキング',

  release: 'リリース',
  closedTime: '閉店時間',
  remark: '述べる',

  totalProfit: '利益総額',
  transferIn: '転入',
  transferOut: '転出',
  equity: '純資産',
  
  homeremind1: '人気の暗号通貨',
  homeremind2: 'さらに多くの市場を見る',
  homeremind3: '最終価格(USD)',
  homeremind4: '24時間変更可能',
  homeremind5: 'アクション',
  homeremind6: 'もっと見る',
  homeremind7: "ユーザーのトップの選択",
  homeremind8: '24時間取引高',
  homeremind9: 'プライム仮想資産',
  homeremind10: '当社の主力製品',
  homeremind11: '幅広い取引ツールから選択可能',
  homeremind12: 'スポット',
  homeremind13: '700以上の暗号通貨を取引可能',
  homeremind14: '先物取引で体験をアップグレード',
  homeremind15: 'NYECoins Earn',
  homeremind16: '業界最高の APY',
  homeremind17: '当社の多様なサービス',
  homeremind18: '多彩なサービスで無限の可能性を探る',
  homeremind19: 'IEO サブスクリプション',
  homeremind20: "最高のプレセール暗号通貨",
  homeremind21: 'VIPカスタマーサービス',
  homeremind22: '7*24時間',
  homeremind23: 'コミュニティ',
  homeremind24: 'ここで私たちと一緒に富のチャンスを見つけてください',
  homeremind25: '外出先でも取引できます。 いつでもどこでも。',
  homeremind26: 'アプリとデスクトップ クライアントで最新情報を入手してください。',
  homeremind27: 'ソーシャルメディアでフォローしてください',
  homeremind28: '最新ニュースを入手してください',
  homeremind29: '取引を続けてください。 いつでもどこでも。',
  homeremind30: '当社のモバイルアプリとウェブサイト端末で最新情報を入手してください。',

  msb: 'NYECoins は米国 MSB で規制されています。',
  msb1: '信頼できる、安全、速い',
  checkNow: '今すぐチェック',

  foundingTeam: '創設チーム',
  advisorTeam: 'アドバイザーチーム',
  community: 'コミュニティ',

  phone: '電話番号',
  phoneTip: '電話番号を入力してください',



  rebateAccount: 'コミッションアカウント',
  rebateType: 'タイプ',
  recommend: '照会',
  myRebate: '私の任務',
  rebateRule: '手数料規則',
  yes: 'はい',
  no: 'いいえ',
  authName: '確認する',
  countryName: '国・地域',
  allCommission: '合計手数料',
  myClient: '私のクライアント',
  inviteUrl1: '紹介リンク',
  inviteCode1: '紹介コード',
  commissioned: '手数料',

  swiperTitle2: 'オンライン仮想通貨取引の世界的リーダー。 暗号通貨を購入、販売、保持します。',
  swiperSub2: '私たちは何百万人もの世界中のユーザーをデジタル資産への道に導いてきました。',

  homeremind31: '私たちは何をしますか？',
  homeremind32: '私たちは、仮想通貨販売業者と一般消費者の間の境界線を曖昧にしたいと考えています。 私たちは入場を無料にし、私たちが信じているお金の未来について人々が理解し、打ち明けられるよう支援することで、暗号通貨を普及させていきます。',
  homeremind33: 'NYECOINSの特徴',
  homeremind34: 'NYECOINS は、中央銀行や単一の管理者なしでベンダーとバイヤーを結び付け、明確で公正な取引を保証します',
  homeremind35: '取引コストの削減',
  homeremind36: '取引支払いと運用コストを抑えて顧客が利用できる分散型プラットフォーム。',
  homeremind37: 'C2Cトランザクション',
  homeremind38: 'NYECOINS を使用すると、C2C 操作が簡単、スピーディ、そして安全になります。 BTC または選択した通貨をプラットフォーム上のクライアントに送信します。',
  homeremind39: 'ブランド向けのプレミアム ツールキット',
  homeremind40: '私たちは企業に顧客を知るためのツールと洞察を提供することで、消費者のターゲティングを最適化しました。',
  homeremind41: 'グローバルパートナーによるサポート',
  homeremind42: 'NYECOINSチーム',
  homeremind43: 'すべてを実現するチームをご紹介します。',
  homeremind44: 'Mark Nichols',
  homeremind45: '共同 - 創設者',
  homeremind46: 'Edward Blankenship',
  homeremind47: 'シニア ソフトウェア エンジニア',
  homeremind48: 'Richard Norman',
  homeremind49: 'コミュニティ管理者',
  homeremind50: 'Paul Garrett',
  homeremind51: 'UI/UXデザイナー',


  ieoIntro1: '流動性インセンティブ',
  ieoIntro2: 'ステーキングリザーブ',
  ieoIntro3: 'エコシステムパートナー',
  ieoIntro4: '一般販売',
  ieoIntro5: 'プライベートセールA',
  ieoIntro6: 'シードラウンド',
  ieoIntro7: 'LinkToJoinチーム',
  ieoIntro8: 'エンタープライズ手数料準備金',
}