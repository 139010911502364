import CryptoJS from "crypto-js"

const SECRET_KEY = CryptoJS.enc.Utf8.parse("2huy3s8as45sfaf8")
const SECRET_IV = CryptoJS.enc.Utf8.parse("4ca41a3y6e30hha7")

export function encrypt(data) {
  const str = JSON.stringify(data)
  const strHex = CryptoJS.enc.Utf8.parse(str)
  const encrypted = CryptoJS.AES.encrypt(strHex, SECRET_KEY, {
    iv: SECRET_IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.ciphertext.toString()
}

export function decrypt(data) {
  const encryptedHexStr = CryptoJS.enc.Hex.parse(data)
  const str = CryptoJS.enc.Base64.stringify(encryptedHexStr)
  const decrypt = CryptoJS.AES.decrypt(str, SECRET_KEY, {
    iv: SECRET_IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
  return decryptedStr.toString()
}