export default {
  required: 'Required',
  home: 'Home',
  market: 'Markets',
  currency: 'Trade',
  walletTrade: 'Spot',
  contract: 'Futures',
  wallet: 'Wallets',
  pulling: 'Pull down to refresh...',
  loosing: 'Release to refresh...',
  loading: 'Loading...',
  loadingError: 'Failed to load',
  nothing: 'No data',
  finished: '',
  price: 'Price',
  number: 'Quantity',
  recharge: 'Deposit',
  transfer: 'Transfer',
  invite: 'Invite',
  inviteDes: 'Invite friends to join us',
  contact: 'Contact Us',
  contactDes: '7*24 Online',
  fastTrade: 'Options',
  name: 'Name',
  fastPrice: 'Price',
  trend: '24H %',
  all: 'All',
  growthList: 'Gainers',
  loserList: 'Losers',
  vol: '24H Vol',
  buy: 'Buy',
  sell: 'Sell',
  optimal: 'Market Price',
  marketOrder: 'Market Trade',
  limitOrder: 'Limit Trade',
  useable: 'Available',
  tradeVol: 'Amount',
  entrust: 'Limit Order',
  entrustTotal: 'Amount',
  noLogin: 'Log out, ',
  toLogin: 'Login now',
  cancelOrder: 'Cancel',
  cancelError: 'Failed to cancel',
  cancelSuccess: 'Cancel successfully',
  cancelTip: 'Sure to cancel？',
  completed: 'Completed',
  canceled: 'Cancelled',
  marketPrice: 'Market',
  limitPrice: 'Limit',
  entrustPrice: 'Price',
  entrustNum: 'Quantity',
  currencyType: 'Symbol',
  headerCurrencyType: 'Symbol',
  currencyOrder: 'Trade Record',
  history: 'History',
  tryAgain: 'Try again',
  entrustPriceTip: 'Input price',
  tradeNumTip: 'Input quantity',
  tradeNumLess: 'Quantity should be more than 0',
  numLessTip: 'Insufficient balance',
  tradePriceTip: 'Input amount',
  balanceTip: 'Insufficient balance',
  tradeError: 'Failed to trade',
  tradeSuccess: 'Trade successfully',
  tip: 'Notice',
  tradeConfirm: 'Confirm to trade？',
  cancelText: 'Cancel',
  confirmText: 'Confirm',
  toBuy: 'Long',
  toSell: 'Short',
  confirmToBuy: 'Long',
  confirmToSell: 'Short',
  slPrice: 'SL Price',
  sl: 'SL',
  tp: 'TP',
  tpPrice: 'TP Price',
  currentOrder: 'Current Order',
  historyOrder: 'History',
  setting: 'Set',
  unwind: 'Close',
  handUnwind: 'Close manually',
  futuresPay: '',
  forceUnwind: 'Liquidation',
  slTp: 'SL/TP',
  fastTradeUnwind: 'Options',
  blowUp: 'Liquidation',
  openNum: 'Quantity',
  warrant: 'Margin',
  openPrice: 'Price',
  fee: 'Fee',
  profit: 'Profit',
  lastPrice: 'Current Price',
  unwindPrice: 'Closed Price',
  unwindError: 'Failed to close',
  unwindSuccess: 'Close successfully',
  setSuccess: 'Set successfully',
  setError: 'Failed to set',
  setSlTp: 'Set SL/TP',
  second: 's',
  multiple: 'Multiple',
  direction: 'Type',
  tradeCurrency: 'Symbol',
  loss: 'Loss',
  fill: 'Win',
  executePrice: 'Price',
  currentPrice: 'Current Price',
  closePrice: 'Closed Price',
  secondNum: 'Period',
  contractOrder: 'Futures Orders',
  fastTradeOrder: 'Options Orders',
  overview: 'Overview',
  ieoBuy: 'IEO',
  balanceTotal: 'Total Asset',
  withdraw: 'Withdraw',
  tradeOrder: 'Trade Order',
  rechargeHistory: 'Deposit Record',
  withdrawHistory: 'Withdraw Record',
  freeze: 'Frozen',
  amountTo: '',
  tipText: 'Notice',
  ieoTip: 'IEO is not opened now, please try again later',
  financeList: 'Financial Record',
  currencyAccount: 'Spot Account',
  contractAccount: 'Futures Account',
  time: 'Time',
  status: 'Status',
  balanceTyle: 'Account',
  pay: 'Outcome',
  income: 'Income',
  lastBalance: 'Balance',
  orderNo: 'ID',
  money: 'Amount',
  date: 'Date',
  finallyIncome: 'Receive amount',
  commitMoney: 'Amount',
  orderNum: 'ID',
  waitReview: 'Reviewing',
  reviewPass: 'Passed',
  reviewReject: 'Rejected',
  waitWallet: 'Reviewing',
  walletPass: 'Passed',
  walletReject: 'Rejected',
  withdrawSuccess: 'Withdraw Successfully',
  withdrawError: 'Failed to transfer',
  high24H: '24H High',
  low24H: '24H Low',
  num24H: '24H Vol',
  minute: 'Mins',
  hour: 'Hours',
  day: 'Day',
  week: 'Week',
  markets: 'Market',
  open: 'Open',
  close: 'Close',
  high: 'High',
  low: 'Low',
  volume: 'Vol',
  fastLogin: 'Login now',
  register: 'Register',
  auth: 'Verify',
  authSuccess: 'Verified',
  noPass: 'Rejected',
  authCommit: 'Reviewing',
  onAuth: 'Unverified',
  onLogin: 'Log out',
  securitySet: 'Security',
  userAgree: 'Users Support',
  aboutUs: 'About Us',
  logout: 'Log Out',
  logoutSuccess: 'Logout Successfully',
  lookAuthInfo: 'Check my information',
  authAgain: 'Re-verify',
  authInfo: 'Information',
  account: 'Account',
  countryArea: 'Country/Region',
  idNo: 'ID#',
  idNoTip: 'Input ID number',
  email: 'Email',
  emailInput: 'Input email',
  setLoginPwd: 'Set login password',
  loginPwdInput: 'Input login password(8-20 characters)',
  loginPwdTip: 'Input login password',
  loginPwdLen: 'Login password should be 8-20 characters',
  setFinancePwd: 'Set fund password',
  financePwdInput: 'Input fund password(6-20 characters)',
  financePwdTip: 'Input fund password',
  financePwdLen: 'Fund password should be 6-20 characters',
  inviteCode: 'Invitation code',
  inviteCodeTip: 'Input invitation code',
  inviteInput: 'Invitation code is required',
  imageCode: 'Captcha',
  imageCodeInput: 'Input captcha',
  registerTip: 'Register means you agree ',
  agree: '《Users Agreement》',
  agreeText: 'Users Agreement',
  next: 'Next',
  registerSuccess: 'Register Completely',
  registerError: 'Failed to register',
  securityCheck: 'Security',
  checkCodeInput: 'Input verification code',
  sendCheckCode: 'Send',
  sendSuccess: 'Sent',
  sendError: 'Failed to send',
  welcome: 'Welcome to',
  loginEmail: 'Email',
  loginEmailInput: 'Input email address',
  loginPwd: 'Login password',
  login: 'Login',
  forgetPwd: 'Forgot Password',
  loginTip: 'Login means you agree',
  loginSuccess: 'Login completely',
  loginError: 'Failed to login',
  fullname: 'Name',
  fullnameTip: 'Input your name',
  country: 'Country/Region',
  countryTip: 'Input country',
  imgFront: 'Upload a photo of the front of your ID',
  imgReverse: 'Upload a photo of the back of your ID',
  commit: 'Submit',
  commitSuccess: 'Submit completely',
  commitError: 'Failed to submit',
  selectCurrency: 'Choose crypto',
  rechargeNet: 'Network',
  rechargeAddress: 'Deposit Address',
  copyAddress: 'Copy address',
  minRecharge: 'Minimum deposit amount',
  copySuccess: 'Copy completely',
  copyError: 'Failed to copy',
  withdrawTip: 'You can withdraw after verify your account',
  withdrawAddress: 'Withdrawal address',
  withdrawAddressInput: 'Input withdrawal address',
  withdrawNet: 'Network',
  withdrawNum: 'Amount',
  withdrawNumInput: 'Input amount',
  withdrawNumLess: 'Insufficient balance',
  withdrawMin: 'Minimum withdrawal amount',
  balanceUse: 'Available',
  actualMoney: 'Receive amount',
  actualMoneyText: 'Receive amount',
  financePwd: 'Fund password',
  trade: '',
  withdrawFail: 'Failed to withdraw',
  commitTime: 'Date',
  from: 'From',
  to: 'To',
  transferMoney: 'Input amount',
  transferSuccess: 'Transfer completely',
  transferError: 'Failed to transfer',
  transferHistory: 'Transfer Record',
  updateFinancePwd: 'Change fund password',
  confirmPwd: 'Confirm password',
  confirmPwdInput: 'Input confirm password',
  updatePwd: 'Change password',
  updatePwdSuccess: 'Change completely',
  updatePwdError: 'Failed to change',
  noAlike: 'Password is inconsistent',
  emailUpdate: 'Change email',
  loginPwdUpdate: 'Change login password',
  financePwdUpdate: 'Change fund password',
  newEmail: 'New email',
  emailUpdateTip: 'Input new email',
  checkEmail: 'Verify email',
  updateEmail: 'Change email',
  updateEmailError: 'Failed to change',
  updateEmailSuccess: 'Change completely',
  checkError: 'Failed to verify',
  pwdLen: '8-20 characters',
  inviteUrl: 'Referral link',
  myInviteCode: 'Invitation code',
  fastInvite: 'Invite Now',
  myInvite: 'Invitation List',
  registerTime: 'Date',
  bulletin: 'Announcement',
  inviteFriend: 'Invite friends',
  saveAccount: 'Save account and password',
  indicator: 'Indicators',
  type: 'Type',
  amount: 'Amount',
  scanError: 'Image recognition failed',
  scanInitErr: 'Initialization failed',
  noCamera: 'No camera permission',
  secondTip: 'Amount should not less than {tip}',
  completePrice: 'Price',
  profitText: 'Profit',
  profitAndLoss: 'Estimated profit and loss',
  timeUp: 'Time Up',
  balanceText: 'History',
  closeAll: 'Close All',
  assetRate: 'Margin Rate',
  cancel: 'Cancel',
  cancelInfo: 'Confirm to cancel?',
  buyCurrency: 'Symbol',
  closeCurrency: 'Currency',
  buyPrice: 'Price',
  valuation: 'Valuation',
  profitP: 'Yield',
  buySuccess: 'Successfully',

  swiperTitle1: 'The World\'s Top Digital Crypto Exchange',
  swiperSub1: 'Trusted by More Than 100,000 Users',

  ieoBuying: 'Ongoing',
  ieoOpen: 'Start Date (UTC-5)',
  ieoClose: 'Finish Date(UTC-5)',
  about: 'About',
  ieoPlan: 'Distribution',
  ieoInfo: 'Information',
  ieoContact: 'Social Media',
  ieoStart: 'Initialization',
  ieoBuyStart: 'IEO Start',
  ieoBuyEnd: 'IEO Finish',
  ieoIsTrade: 'Lanuch',
  introduce: 'Introduction',
  ieoSubBuy: 'IEO',
  subBuyHistory: 'IEO History',
  rechargeTip1: '1. This is your newest and unique address, platform will never ask you to transfer funds to another account or ask for your verification code.',
  rechargeTip2: '2. The deposit requires at least 10 network blocks for confirmation. After confirmation, your account will automatically receive the funds.',
  withdrawTip1: '1. Make sure you select the same network for this withdrawal as the one on deposit side, and check that receiving platform supports the network. Otherwise, you will lose your assets.',
  withdrawTip2: '2. Network blocks will confirm your withdrawal after platform approves your apply.',
  withdrawTip3: '3. The withdraw requires at least 10 network blocks for confirmation. After confirmation, your account will automatically receive the funds.',
  coming: 'It is coming soon.',
  createTeam: 'Reserve Fund',
  angelFund: 'Angel Fund',
  ecology: 'Institutions and Ecosystems',
  presale: 'Pre-sale Release',
  mechanism: 'Mining Mechanism',
  trading: 'Exchange Trading',

  issuePrice: 'Issue Price',
  minBuyPrice: 'Minimum Subscription',
  buyNum: 'Subscriptions per Day',
  buyTotal: 'Total Daily Subscription',
  Increment: 'Subscription Increment',
  blockchain: 'Blockchain',

  sellTotal: 'Total Issuance',
  currentBuy: 'Current Subscription',
  lessMinTip: 'Cannot be less than the minimum amount.',
  stakeAccount: 'Staking Account',
  serviceAccount: 'Service Account',
  lock: 'Lock',
  lockPrice: 'Locked Price',
  ieoHistory: 'IEO History',
  lockHistory: 'Lock History',
  sellHistory: 'Sell History',
  stake: 'Staking',
  service: 'Service',
  ieoBuyTip: "*IEO Notice: The number of LTJ is limited. We will review the user's IEO application and draw lots randomly every 3 hours. Users with a relatively large amount will have a higher probability of buying LTJ. Each user can buy up to 3 times per day.",
  ieoLockTip: '*Lock Price: Users need to use USDT as the pledge currency to lock the price of LTJ, and the pledge ratio is 30% of the value of LTJ. After LTJ is launched, users can sell LTJ at the price locked at that time.',
  ieoSellTip: '*IEO Selling: Selling LTJ requires 10% of the LTJ value as a service fee. After selling LTJ, the USDT you exchanged will be unfrozen in 20 days, and the pledged USDT will be unfrozen in 20 days.',
  whitePaper: 'WHITEPAPER',
  cost: 'Cost',
  currentEstimate: 'Current Valuation',
  stakeNum: 'Staking Amount',
  estimate: 'Valuation',
  consume: 'Consume',

  seedSell: 'Seed Round',
  privateSell: 'Private Round',
  openSell: 'KOL Round',
  ieoAward: 'Public Round',
  ieoGrowUp: 'Team',
  partner: 'Advisors',
  consultant: 'Marketing',
  ieoName: 'Liquidity',
  conAward: 'AI To Earn',
  staking: 'Staking',
  
  release: 'Release',
  closedTime: 'Closed Time',
  remark: 'Remark',

  totalProfit: 'Total Profit',
  transferIn: 'Transfer In',
  transferOut: 'Transfer Out',
  equity: 'Equity',

  homeremind1: 'Popular Cryptocurrencies',
  homeremind2: 'View more markets',
  homeremind3: 'Last Price(USD)',
  homeremind4: '24H Change',
  homeremind5: 'Action',
  homeremind6: 'View more',
  homeremind7: "Users’ Top Choices",
  homeremind8: '24h Trading Volume',
  homeremind9: 'Prime Virtual Assets',
  homeremind10: 'Our Core Products',
  homeremind11: 'A wide variety of trading tools to choose from',
  homeremind12: 'Spot',
  homeremind13: 'Over 700 cryptos available for trading',
  homeremind14: 'Upgrade your experience by trading futures',
  homeremind15: 'NYECoins Earn',
  homeremind16: 'Best APYs in the industry',
  homeremind17: 'Our Diversified Services',
  homeremind18: 'Explore unlimited possibilities with our diverse services',
  homeremind19: 'IEO Subscription',
  homeremind20: "The Best Pre-sale cryptocurrencies",
  homeremind21: 'VIP Customer Services',
  homeremind22: '7*24 Hours',
  homeremind23: 'Community',
  homeremind24: 'Uncover wealth opportunities here with us',
  homeremind25: 'Trade on the go. Anywhere, Anytime.',
  homeremind26: 'Stay in the know with our app and desktop client.',
  homeremind27: 'Follow Us on Social Media',
  homeremind28: 'Stay up-to-date with our latest news',
  homeremind29: 'Stay in Trading. Anywhere, Anytime.',
  homeremind30: 'Stay in the know with our mobile APP and website terminal.',

  msb: 'NYECoins is Regulated in MSB, USA.',
  msb1: 'Trustworthy, Safe, Fast',
  checkNow: 'Check Now',

  foundingTeam: 'Founding Team',
  advisorTeam: 'Advisor Team',
  community: 'Community',

  phone: 'Phone Number',
  phoneTip: 'Input your phone number',


  rebateAccount: 'Commission Account',
  rebateType: 'Type',
  recommend: 'Referral',
  myRebate: 'My Commission',
  rebateRule: 'Commission Rules',
  yes: 'Yes',
  no: 'No',
  authName: 'Verify',
  countryName: 'Country/Area',
  allCommission: 'Total Commission',
  myClient: 'My Clients',
  inviteUrl1: 'Referral Link',
  inviteCode1: 'Referral Code',
  commissioned: 'Commission',

  swiperTitle2: 'The Global Leader in Online Crypto Trading. Buy, Sell and Hold Cryptocurrencies.',
  swiperSub2: 'We Have Guided Millions of Global Users on Their Digital Assets Journey.',

  homeremind31: 'What Do We Do?',
  homeremind32: 'We want to blur the line between the cryptocurrency merchants and ordinary consumers. We will popularize the cryptocurrency by giving free admission, and by helping people understand and confide what we believe to be the future of money.',
  homeremind33: 'Features of NYECoins',
  homeremind34: 'NYECoins brings together vendors and buyers without a central bank or single administrator to ensure clear and fair deals',
  homeremind35: 'Lower Transaction Costs',
  homeremind36: 'A decentralized platform available to customers with lower transaction payments and operational expenses.',
  homeremind37: 'C2C Transactions',
  homeremind38: 'C2C operations are easy, speedy and safe with NYECoins. Send HVRs or your choice of currency to any client on the platform.',
  homeremind39: 'Premium Toolkit for Brands',
  homeremind40: 'We have optimized consumer targeting by giving companies the tools and insight to know their clients.',
  homeremind41: 'Supported by Global Partners',
  homeremind42: 'NYECoins Team',
  homeremind43: 'Let us introduce you to the team that will make it all happen.',
  homeremind44: 'Mark Nichols',
  homeremind45: 'Co-Founder',
  homeremind46: 'Edward Blankenship',
  homeremind47: 'Senior Software Engineer',
  homeremind48: 'Richard Norman',
  homeremind49: 'Community Manager',
  homeremind50: 'Paul Garrett',
  homeremind51: 'UI/UX Designer',


  ieoIntro1: 'Liquidity Incentives',
  ieoIntro2: 'Staking Reserve',
  ieoIntro3: 'Ecosystem Partners',
  ieoIntro4: 'Public Sale',
  ieoIntro5: 'Private Sale A',
  ieoIntro6: 'Seed Round',
  ieoIntro7: 'LinkToJoin Team',
  ieoIntro8: 'Enterprise Fee Reserve',
}