import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { useRouter } from 'vue-router';
export default {
  __name: 'NavGradient',
  props: {
    title: {
      type: String,
      default: ''
    },
    back: {
      type: Function,
      default: null
    }
  },

  setup(__props) {
    const props = __props;
    const router = useRouter();

    const backPage = () => {
      if (props.back) {
        props.back();
      }

      router.back();
    };

    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");

      const _component_van_nav_bar = _resolveComponent("van-nav-bar");

      return _openBlock(), _createBlock(_component_van_nav_bar, {
        class: "gradient-nav",
        "safe-area-inset-top": true,
        fixed: true,
        placeholder: true,
        "z-index": "200",
        title: props.title,
        border: false
      }, {
        left: _withCtx(() => [_createVNode(_component_van_icon, {
          name: "arrow-left",
          size: "20",
          style: {
            "color": "black"
          },
          onClick: backPage
        })]),
        right: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
        _: 3
      }, 8, ["title"]);
    };
  }

};