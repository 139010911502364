import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-00d195be"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "entrust-item-c"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = {
  class: "header"
};
const _hoisted_4 = {
  class: "title"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  key: 1,
  style: {
    "font-size": "13px",
    "color": "#919497"
  }
};
const _hoisted_7 = {
  key: 2,
  style: {
    "font-size": "13px",
    "color": "#D04C64"
  }
};
const _hoisted_8 = {
  class: "info"
};
const _hoisted_9 = {
  key: 0,
  class: "tag",
  style: {
    "--color": "#04AC92",
    "--bColor": "#E4F7F1"
  }
};
const _hoisted_10 = {
  key: 1,
  class: "tag",
  style: {
    "--color": "#D04C64",
    "--bColor": "#FBF3F1"
  }
};
const _hoisted_11 = {
  key: 2,
  class: "tag",
  style: {
    "--color": "#04AC92",
    "--bColor": "#E4F7F1"
  }
};
const _hoisted_12 = {
  key: 3,
  class: "tag",
  style: {
    "--color": "#D04C64",
    "--bColor": "#FBF3F1"
  }
};
const _hoisted_13 = {
  class: "date"
};
const _hoisted_14 = {
  class: "label"
};
const _hoisted_15 = {
  class: "num"
};
const _hoisted_16 = {
  class: "label"
};
const _hoisted_17 = {
  class: "num"
};
const _hoisted_18 = {
  class: "label"
};
const _hoisted_19 = {
  class: "num"
};
import { Toast } from 'vant';
import 'vant/es/toast/style';
import { Dialog } from 'vant';
import 'vant/es/dialog/style';
import { orderStatus, orderSide, orderType, exType } from '@/util/type';
import { useI18n } from "vue-i18n";
import { exchangeCancel } from '@/apis';
import store from '@/store';
import { fmtNumber } from '@/util';
export default {
  __name: 'EntrustItem',
  props: {
    id: {
      type: Number
    },
    name: {
      type: String,
      default: '--'
    },
    coin: {
      type: String,
      default: '--'
    },
    time: {
      type: String,
      default: '--'
    },
    status: {
      type: Number,
      default: null
    },
    side: {
      type: Number,
      default: 2
    },
    type: {
      type: Number,
      default: 2
    },
    price: {
      type: String,
      default: '0'
    },
    count: {
      type: String,
      default: '0'
    },
    deal: {
      type: String,
      default: '0'
    },
    allText: {
      type: String,
      default: '--'
    }
  },
  emits: ['init'],

  setup(__props, {
    emit
  }) {
    const props = __props;
    const {
      t
    } = useI18n();

    const cancelOrder = () => {
      const beforeClose = action => {
        return new Promise(resolve => {
          if (action === 'confirm') {
            exchangeCancel(exType.ex, {
              id: props.id,
              token: store.state.token
            }).then(res => {
              if (res.code !== 200) {
                Toast({
                  message: res.message ? res.message : t('cancelError'),
                  position: "bottom"
                });
                resolve(false);
                return;
              }

              Toast({
                message: t('cancelSuccess'),
                position: "bottom"
              });
              emit('init');
              resolve(true);
            });
          } else {
            resolve(true);
          }
        });
      };

      Dialog.confirm({
        title: t('tip'),
        message: t('cancelTip'),
        cancelButtonText: t('cancelText'),
        confirmButtonText: t('confirmText'),
        beforeClose
      }).catch(() => {});
    };

    return (_ctx, _cache) => {
      const _component_van_col = _resolveComponent("van-col");

      const _component_van_row = _resolveComponent("van-row");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(props.name) + "/" + _toDisplayString(props.coin), 1), props.status === _unref(orderStatus).entrust ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "btn",
        onClick: _withModifiers(cancelOrder, ["stop"])
      }, _toDisplayString(_unref(t)('cancelOrder')), 9, _hoisted_5)) : props.status === _unref(orderStatus).complete ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_unref(t)('completed')), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_unref(t)('canceled')), 1))]), _createElementVNode("div", _hoisted_8, [props.side === _unref(orderSide).buy ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_unref(t)('buy')), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_unref(t)('sell')), 1)), props.type === _unref(orderType).market ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_unref(t)('marketPrice')), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_unref(t)('limitPrice')), 1)), _createElementVNode("div", _hoisted_13, _toDisplayString(props.time), 1)]), _createVNode(_component_van_row, null, {
        default: _withCtx(() => [_createVNode(_component_van_col, {
          span: "8",
          class: "data-item"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_14, _toDisplayString(_unref(t)('entrustPrice')), 1), _createElementVNode("div", _hoisted_15, _toDisplayString(_unref(fmtNumber)(props.price)), 1)]),
          _: 1
        }), _createVNode(_component_van_col, {
          span: "8",
          class: "data-item",
          style: {
            "text-align": "center"
          }
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_16, _toDisplayString(_unref(t)('entrustNum')), 1), _createElementVNode("div", _hoisted_17, _toDisplayString(_unref(fmtNumber)(props.count)), 1)]),
          _: 1
        }), _createVNode(_component_van_col, {
          span: "8",
          class: "data-item",
          style: {
            "text-align": "right"
          }
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_18, _toDisplayString(props.allText), 1), _createElementVNode("div", _hoisted_19, _toDisplayString(_unref(fmtNumber)(props.deal)), 1)]),
          _: 1
        })]),
        _: 1
      })])]);
    };
  }

};