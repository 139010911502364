import "core-js/modules/es.array.push.js";
import { unref as _unref, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '../assets/icon/order.png';

const _withScopeId = n => (_pushScopeId("data-v-437ae0aa"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "base-trade-header"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = {
  style: {
    "font-size": "14.5px",
    "font-weight": "600"
  }
};
const _hoisted_4 = {
  style: {
    "font-size": "15px"
  }
};
const _hoisted_5 = {
  style: {
    "margin-right": "15px",
    "font-weight": "700",
    "color": "rgb(171,84,219)"
  }
};
import router from '@/router';
import NavGradient from '@/components/NavGradient.vue';
import { useI18n } from "vue-i18n";
export default {
  __name: 'BaseTradeHeader',
  props: {
    text: {
      tyle: String,
      default: ''
    },
    title: {
      type: String
    },
    historyRouter: {
      type: String
    }
  },
  emits: ['coinShow'],

  setup(__props, {
    emit
  }) {
    const props = __props;
    const {
      t
    } = useI18n();
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(NavGradient, null, {
        default: _withCtx(() => [_createElementVNode("img", {
          src: _imports_0,
          style: {
            "width": "22px"
          },
          onClick: _cache[0] || (_cache[0] = $event => _unref(router).push({
            name: props.historyRouter
          }))
        })]),
        _: 1
      }), _createElementVNode("div", _hoisted_2, _toDisplayString(props.title), 1), _createElementVNode("div", {
        class: "currency-select",
        onClick: _cache[1] || (_cache[1] = $event => emit('coinShow'))
      }, [_createElementVNode("div", _hoisted_3, _toDisplayString(_unref(t)('currencyType')), 1), _createElementVNode("div", _hoisted_4, [_createElementVNode("span", _hoisted_5, _toDisplayString(props.text !== '' ? props.text : _unref(t)('selectCurrency')), 1), _createVNode(_component_van_icon, {
        name: "arrow",
        style: {
          "font-weight": "600"
        }
      })])])]);
    };
  }

};