import "core-js/modules/es.array.push.js";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  style: {
    "text-align": "center"
  }
};
import router from '@/router';
import { useI18n } from "vue-i18n";
export default {
  __name: 'EmptyLogin',

  setup(__props) {
    const {
      t
    } = useI18n();
    return (_ctx, _cache) => {
      const _component_van_empty = _resolveComponent("van-empty");

      return _openBlock(), _createBlock(_component_van_empty, null, {
        description: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createTextVNode(_toDisplayString(_unref(t)('noLogin')), 1), _createElementVNode("span", {
          style: {
            "color": "#026ee2"
          },
          onClick: _cache[0] || (_cache[0] = $event => _unref(router).push({
            name: 'login'
          }))
        }, _toDisplayString(_unref(t)('toLogin')), 1)])]),
        _: 1
      });
    };
  }

};