export default {
  required: 'Requerida',
  home: 'Inicio',
  market: 'Mercados',
  currency: 'Comercio',
  walletTrade: 'Divisa',
  contract: 'Futuros',
  wallet: 'Carteras',
  pulling: 'Tire hacia abajo para refrescar...',
  loosing: 'Suéltelo para actualizar...',
  loading: 'Cargando...',
  loadingError: 'Falló al cargar',
  nothing: 'Sin datos',
  finished: '',
  price: 'Precio',
  number: 'Cantidad',
  recharge: 'Depósito',
  transfer: 'Transferir',
  invite: 'Invitar',
  inviteDes: 'Invitar a amigos a unirse a nosotros',
  contact: 'Contacto',
  contactDes: '7*24 en línea',
  fastTrade: 'Opciones',
  name: 'Nombre',
  fastPrice: 'Precio',
  trend: '24H %',
  all: 'Todos',
  growthList: 'Ganadoras',
  loserList: 'Perdedoras',
  vol: '24H Vol',
  buy: 'Comprar',
  sell: 'Vender',
  optimal: 'Precio de mercado',
  marketOrder: 'comercio de mercado',
  limitOrder: 'Limitar comercio',
  useable: 'Disponible',
  tradeVol: 'Monto',
  entrust: 'orden de límite',
  entrustTotal: 'Monto',
  noLogin: 'Cerrar sesión, ',
  toLogin: 'Inicia sesión ahora',
  cancelOrder: 'Cancelar',
  cancelError: 'Error al cancelar',
  cancelSuccess: 'Cancelar con éxito',
  cancelTip: 'Seguro para cancelar?',
  completed: 'Terminada',
  canceled: 'Cancelada',
  marketPrice: 'Mercado',
  limitPrice: 'Límite',
  entrustPrice: 'Precio',
  entrustNum: 'Cantidad',
  currencyType: 'Símbolo',
  currencyOrder: 'Registro comercial',
  history: 'Historia',
  tryAgain: 'Intentar otra vez',
  entrustPriceTip: 'Precio de entrada',
  tradeNumTip: 'Cantidad de entrada',
  tradeNumLess: 'La cantidad debe ser mayor que 0',
  numLessTip: 'Saldo insuficiente',
  tradePriceTip: 'Importe de entrada',
  balanceTip: 'Saldo insuficiente',
  tradeError: 'Error al intercambiar',
  tradeSuccess: 'Comercio con éxito',
  tip: 'Aviso',
  tradeConfirm: '¿Confirmar para intercambiar?',
  cancelText: 'Cancelar',
  confirmText: 'Confirmar',
  toBuy: 'comprar',
  toSell: 'vender corto',
  confirmToBuy: 'Comprar',
  confirmToSell: 'Vender corto',
  slPrice: 'SL Precio',
  sl: 'SL',
  tp: 'TP',
  tpPrice: 'TP Precio',
  currentOrder: 'Orden actual',
  historyOrder: 'Historia',
  setting: 'Establecer',
  unwind: 'Cerca',
  handUnwind: 'Cerrar manualmente',
  futuresPay: '',
  forceUnwind: 'Liquidación',
  slTp: 'SL/TP',
  fastTradeUnwind: 'Opciones',
  blowUp: 'Liquidación',
  openNum: 'Cantidad',
  warrant: 'Margen',
  openPrice: 'Precio',
  fee: 'Tarifa',
  profit: 'Lucro',
  lastPrice: 'Precio actual',
  unwindPrice: 'Precio cerrado',
  unwindError: 'no se pudo cerrar',
  unwindSuccess: 'Cerrar con éxito',
  setSuccess: 'Establecer con éxito',
  setError: 'No se pudo establecer',
  setSlTp: 'Establecer SL/TP',
  second: 's',
  multiple: 'Múltiple',
  direction: 'Escribe',
  tradeCurrency: 'Símbolo',
  loss: 'Pérdida',
  fill: 'Victoria',
  executePrice: 'Precio',
  currentPrice: 'Precio actual',
  closePrice: 'Precio cerrado',
  secondNum: 'Período',
  contractOrder: 'Órdenes de Futuros',
  fastTradeOrder: 'Órdenes de Opciones',
  overview: 'Visión general',
  ieoBuy: 'IEO',
  balanceTotal: 'Activos totales',
  withdraw: 'retirar',
  tradeOrder: 'Orden comercial',
  rechargeHistory: 'Registro de depósito',
  withdrawHistory: 'Retirar registro',
  freeze: 'Congelada',
  amountTo: '',
  tipText: 'Aviso',
  ieoTip: 'IEO no está abierto ahora, intente nuevamente más tarde',
  financeList: 'Registros financieros',
  currencyAccount: 'Cuenta corriente',
  contractAccount: 'Cuenta de Futuros',
  time: 'Tiempo',
  status: 'Estado',
  balanceTyle: 'Cuenta',
  pay: 'Salir',
  income: 'Ingreso',
  lastBalance: 'balance',
  orderNo: 'ID',
  money: 'Monto',
  date: 'Fecha',
  finallyIncome: 'Recibir cantidad',
  commitMoney: 'Monto',
  orderNum: 'ID',
  waitReview: 'Revisando',
  reviewPass: 'Pasó',
  reviewReject: 'Rechazada',
  waitWallet: 'Revisando',
  walletPass: 'Pasó',
  walletReject: 'Rechazada',
  withdrawSuccess: 'Retirar con éxito',
  withdrawError: 'Error al transferir',
  high24H: '24H Alta',
  low24H: '24H Baja',
  num24H: '24H Vol',
  minute: 'Minutos',
  hour: 'Horas',
  day: 'Día',
  week: 'Semana',
  markets: 'Mercado',
  open: 'Abierta',
  close: 'Cerca',
  high: 'Alta',
  low: 'Baja',
  volume: 'Vol',
  fastLogin: 'Inicia sesión ahora',
  register: 'Registro',
  auth: 'Verificar',
  authSuccess: 'Verificada',
  noPass: 'Rechazada',
  authCommit: 'Revisando',
  onAuth: 'Inconfirmado',
  onLogin: 'Cerrar sesión',
  securitySet: 'Seguridad',
  userAgree: 'Soporte a usuarios',
  aboutUs: 'Sobre nosotras',
  logout: 'Cerrar sesión',
  logoutSuccess: 'Cerrar sesión con éxito',
  lookAuthInfo: 'Revisa mi información',
  authAgain: 'volver a verificar',
  authInfo: 'Información',
  account: 'Cuenta',
  countryArea: 'País/Región',
  idNo: 'ID#',
  idNoTip: 'Número de identificación de entrada',
  email: 'Correo electrónico',
  emailInput: 'Introducir correo electrónico',
  setLoginPwd: 'Establecer contraseña de inicio de sesión',
  loginPwdInput: 'Ingrese la contraseña de inicio de sesión (8-20 caracteres)',
  loginPwdTip: 'Introduzca la contraseña de inicio de sesión',
  loginPwdLen: 'La contraseña de inicio de sesión debe tener entre 8 y 20 caracteres',
  setFinancePwd: 'Establecer contraseña de fondo',
  financePwdInput: 'Ingrese la contraseña del fondo (6-20 caracteres)',
  financePwdTip: 'Introduzca la contraseña del fondo',
  financePwdLen: 'La contraseña del fondo debe tener entre 6 y 20 caracteres',
  inviteCode: 'Código de invitación',
  inviteCodeTip: 'Introduzca el código de invitación',
  inviteInput: 'Se requiere código de invitación',
  imageCode: 'Captcha',
  imageCodeInput: 'Captcha de entrada',
  registerTip: 'Registrarse significa que está de acuerdo ',
  agree: '《Acuerdo de usuarios》',
  agreeText: 'Acuerdo de usuarios',
  next: 'Próxima',
  registerSuccess: 'Regístrese Completamente',
  registerError: 'Fallo el registro',
  securityCheck: 'Seguridad',
  checkCodeInput: 'Código de verificación de entrada',
  sendCheckCode: 'Enviar',
  sendSuccess: 'Enviada',
  sendError: 'fallo al enviar',
  welcome: 'Bienvenida a',
  loginEmail: 'Correo electrónico',
  loginEmailInput: 'Introduzca la dirección de correo electrónico',
  loginPwd: 'Contraseña de inicio de sesión',
  login: 'Acceso',
  forgetPwd: 'Has olvidado tu contraseña',
  loginTip: 'Iniciar sesión significa que estás de acuerdo',
  loginSuccess: 'Iniciar sesión completamente',
  loginError: 'Error al iniciar sesión',
  fullname: 'Nombre',
  fullnameTip: 'Ingrese su nombre',
  country: 'País/Región',
  countryTip: 'Introduzca el país o la región',
  imgFront: 'Sube una foto del frente de tu DNI',
  imgReverse: 'Sube una foto del reverso de tu DNI',
  commit: 'Enviar',
  commitSuccess: 'Enviar completamente',
  commitError: 'Error al enviar',
  selectCurrency: 'Elija criptografía',
  rechargeNet: 'La red',
  rechargeAddress: 'Dirección de depósito',
  copyAddress: 'Copiar dirección',
  minRecharge: 'Monto mínimo de depósito',
  copySuccess: 'Copiar completamente',
  copyError: 'Error al copiar',
  withdrawTip: 'Puede retirar después de verificar su cuenta',
  withdrawAddress: 'dirección de retiro',
  withdrawAddressInput: 'Introduzca la dirección de retiro',
  withdrawNet: 'La red',
  withdrawNum: 'Monto',
  withdrawNumInput: 'Importe de entrada',
  withdrawNumLess: 'Saldo insuficiente',
  withdrawMin: 'Cantidad mínima de retiro',
  balanceUse: 'Disponible',
  actualMoney: 'Recibir cantidad',
  actualMoneyText: 'Recibir cantidad',
  financePwd: 'Contraseña del fondo',
  trade: '',
  withdrawFail: 'No se pudo retirar',
  commitTime: 'Fecha',
  from: 'De',
  to: 'A',
  transferMoney: 'Importe de entrada',
  transferSuccess: 'Transferir completamente',
  transferError: 'Error al transferir',
  transferHistory: 'Registro de transferencia',
  updateFinancePwd: 'Cambiar la contraseña del fondo',
  confirmPwd: 'Confirmar contraseña',
  confirmPwdInput: 'Introduzca la contraseña de confirmación',
  updatePwd: 'Cambia la contraseña',
  updatePwdSuccess: 'cambiar completamente',
  updatePwdError: 'No se pudo cambiar',
  noAlike: 'La contraseña es inconsistente',
  emailUpdate: 'Cambiar e-mail',
  loginPwdUpdate: 'Cambiar contraseña de inicio de sesión',
  financePwdUpdate: 'Cambiar la contraseña del fondo',
  newEmail: 'Nuevo correo electrónico',
  emailUpdateTip: 'Ingrese un nuevo correo electrónico',
  checkEmail: 'Verificar correo electrónico',
  updateEmail: 'Cambiar e-mail',
  updateEmailError: 'No se pudo cambiar',
  updateEmailSuccess: 'cambiar completamente',
  checkError: 'No se pudo verificar',
  pwdLen: '8-20 caracteres',
  inviteUrl: 'Enlace de referencia',
  myInviteCode: 'Código de invitación',
  fastInvite: 'invitar ahora',
  myInvite: 'Lista de invitaciones',
  registerTime: 'Fecha',
  bulletin: 'Anuncio',
  inviteFriend: 'Invitar a amigas',
  saveAccount: 'Guardar cuenta y contraseña',
  indicator: 'Indicadores',
  type: 'Escribe',
  amount: 'Monto',

  headerCurrencyType: 'Símbolo',
  scanError: "El reconocimiento de imagen falló",
  scanInitErr: 'Inicialización falló',
  noCamera: 'Sin permiso de cámara',
  secondTip: 'La cantidad no debe ser inferior a {tip}',
  completePrice: 'Precio',
  profitText: 'Lucro', 
  profitAndLoss: 'Ganancias y pérdidas estimadas',
  timeUp: 'Se acabó el tiempo',
  balanceText: 'Historia',
  closeAll: 'Cierra todo',
  assetRate: 'Tasa de margen',
  cancel: 'Cancelar',
  cancelInfo: '¿Confirmar para cancelar?',
  buyCurrency: 'Símbolo',
  closeCurrency: 'Moneda',
  buyPrice: 'Precio',
  valuation: 'Valoración',
  profitP: 'Rendimiento',
  buySuccess: 'Exitosamente',

  swiperTitle1: 'El intercambio criptográfico digital más importante del mundo',
  swiperSub1: 'Confiado por más de 100,000 usuarios',

  ieoBuying: 'En cours',
  ieoOpen: 'Date de début(UTC-5)',
  ieoClose: 'Date de fin(UTC-5)',
  about: 'À propos',
  ieoPlan: 'Distribution',
  ieoInfo: 'Renseignements',
  ieoContact: 'Des médias sociaux',
  ieoStart: 'Initialisation',
  ieoBuyStart: 'Début IEO',
  ieoBuyEnd: 'Finition IEO',
  ieoIsTrade: "Lanzamiento",
  introduce: 'Introduction',
  ieoSubBuy: 'IEO',
  subBuyHistory: "L'histoire",
  rechargeTip1: "1. Esta es su dirección más nueva y única, la plataforma nunca le pedirá que transfiera fondos a otra cuenta ni le pedirá su código de verificación.",
  rechargeTip2: "2. El depósito requiere al menos 10 bloques de red para su confirmación. Después de la confirmación, su cuenta recibirá automáticamente los fondos.",
  withdrawTip1: "1. Asegúrese de seleccionar la misma red para este retiro que la del lado del depósito y verifique que la plataforma receptora sea compatible con la red. De lo contrario, perderá sus activos.",
  withdrawTip2: "2. Los bloques de red confirmarán su retiro después de que la plataforma apruebe su solicitud.",
  withdrawTip3: "3. El retiro requiere al menos 10 bloques de red para su confirmación. Después de la confirmación, su cuenta recibirá automáticamente los fondos.",
  coming: 'Viene pronto.',
  createTeam: 'Fondo de Reserva',
  angelFund: 'Fondo Ángel',
  ecology: 'Instituciones y Ecosistemas',
  presale: 'Lanzamiento de preventa',
  mechanism: 'mecanismo minero',
  trading: 'comercio de intercambio',

  issuePrice: 'Precio de Emisión',
  minBuyPrice: 'Suscripción Mínima',
  buyNum: 'Suscripciones por Día',
  buyTotal: 'Suscripción Diaria Total',
  Increment: 'Incremento de Suscripción',
  blockchain: 'Cadena de Bloques',

  sellTotal: 'Emisión Total',
  currentBuy: 'Suscripción Actual',
  lessMinTip: 'No puede ser inferior a la cantidad mínima.',
  stakeAccount: 'Cuenta de participación',
  serviceAccount: 'Cuenta de servicio',
  lock: 'Cerrar',
  lockPrice: 'Precio bloqueado',
  ieoHistory: 'Historia de la OEI',
  lockHistory: 'Historial de bloqueo',
  sellHistory: 'Historial de ventas',
  stake: 'Replanteo',
  service: 'Servicio',
  ieoBuyTip: '*Aviso IEO: El número de LTJ es limitado. Revisaremos la solicitud de IEO del usuario y sortearemos aleatoriamente cada 3 horas. Los usuarios con una cantidad relativamente grande tendrán una mayor probabilidad de comprar LTJ. Cada usuario puede comprar hasta 3 veces al día.',
  ieoLockTip: '*Precio de bloqueo: los usuarios deben usar USDT como moneda de compromiso para bloquear el precio de LTJ, y la relación de compromiso es del 30% del valor de LTJ. Después del lanzamiento de LTJ, los usuarios pueden vender LTJ al precio fijado en ese momento.',
  ieoSellTip: '*Venta de IEO: la venta de LTJ requiere el 10% del valor de LTJ como tarifa de servicio. Después de vender LTJ, el USDT que intercambió se descongelará en 20 días y el USDT prometido se descongelará en 20 días.',
  whitePaper: 'PAPEL BLANCO',
  cost: 'Costo',
  currentEstimate: 'Valoración actual',
  stakeNum: 'Monto apostado',
  estimate: 'Valuación',
  consume: 'Consumir',

  seedSell: 'ronda de semillas',
  privateSell: 'Ronda Privada',
  openSell: 'Ronda KOL',
  ieoAward: 'Ronda Pública',
  ieoGrowUp: 'Equipo',
  partner: 'Asesores',
  consultant: 'Marketing',
  ieoName: 'Liquidez',
  conAward: 'IA para ganar',
  staking: 'Replanteo',

  release: 'Liberar',
  closedTime: 'Hora de cierre',
  remark: 'Observación',

  totalProfit: 'Beneficio total',
  transferIn: 'Transferencia',
  transferOut: 'Trasferencia',
  equity: 'valor neto',

  
  homeremind1: 'Criptomonedas populares',
  homeremind2: 'Ver más mercados',
  homeremind3: 'Ultimo precio(USD)',
  homeremind4: 'Cambio 24H',
  homeremind5: 'Acción',
  homeremind6: 'Ver más',
  homeremind7: "Las mejores opciones de las usuarias",
  homeremind8: 'Volumen 24h',
  homeremind9: 'Activos virtuales principales',
  homeremind10: 'Nuestros productos principales',
  homeremind11: 'Una amplia variedad de herramientas comerciales para elegir',
  homeremind12: 'Lugar',
  homeremind13: 'Más de 700 criptomonedas disponibles para operar',
  homeremind14: 'Mejore su experiencia operando con futuros',
  homeremind15: 'NYECoins Earn',
  homeremind16: 'Los mejores APY de la industria',
  homeremind17: 'Nuestros servicios diversificados',
  homeremind18: 'Explora posibilidades ilimitadas con nuestros diversos servicios',
  homeremind19: 'Suscripción IEO',
  homeremind20: "Las Mejores Criptomonedas en Preventa",
  homeremind21: 'Atención al cliente VIP',
  homeremind22: '7*24 Horas',
  homeremind23: 'Comunidad',
  homeremind24: 'Descubra oportunidades de riqueza aquí con nosotros',
  homeremind25: 'Opere sobre la marcha. En cualquier lugar en cualquier momento.',
  homeremind26: 'Manténgase informado con nuestra aplicación y cliente de escritorio.',
  homeremind27: 'Síguenos en las redes sociales',
  homeremind28: 'Manténgase actualizado con nuestras últimas noticias',
  homeremind29: 'Permanezca en el comercio. En cualquier lugar en cualquier momento.',
  homeremind30: 'Manténgase informado con nuestra aplicación móvil y terminal de sitio web.',

  msb: 'NYECoins está regulado en MSB, EE. UU.',
  msb1: 'Confiable, Seguro, Rápido',
  checkNow: 'Revisalo ahora',

  foundingTeam: 'Equipo fundador',
  advisorTeam: 'Equipo asesor',
  community: 'Comunidad',

  phone: 'Número de teléfono',
  phoneTip: 'Introduce tu número de teléfono',


  rebateAccount: 'cuenta de comisión',
  rebateType: 'tipo',
  recommend: 'Remisión',
  myRebate: 'mi comisión',
  rebateRule: 'Normas de la Comisión',
  yes: 'Sí',
  no: 'No',
  authName: 'Verificar',
  countryName: 'Área del país',
  allCommission: 'comisión total',
  myClient: 'Mis clientas',
  inviteUrl1: 'Enlace de referencia',
  inviteCode1: 'código de referencia',
  commissioned: 'comisión',

  swiperTitle2: 'El líder mundial en comercio de criptomonedas en línea. Compre, venda y mantenga criptomonedas.',
  swiperSub2: 'Hemos guiado a millones de usuarios globales en su viaje hacia los activos digitales.',

  homeremind31: '¿Qué hacemos?',
  homeremind32: 'Queremos desdibujar la línea entre los comerciantes de criptomonedas y los consumidores comunes y corrientes. Popularizaremos la criptomoneda ofreciendo entrada gratuita y ayudando a las personas a comprender y confiar en lo que creemos que será el futuro del dinero.',
  homeremind33: 'Características de NYECOINS',
  homeremind34: 'NYECOINS reúne a proveedores y compradores sin un banco central o administrador único para garantizar acuerdos claros y justos.',
  homeremind35: 'Costos de transacción más bajos',
  homeremind36: 'Una plataforma descentralizada disponible para clientes con pagos de transacciones y gastos operativos más bajos.',
  homeremind37: 'Transacciones C2C',
  homeremind38: 'Las operaciones C2C son fáciles, rápidas y seguras con NYECOINS. Envíe BTC o la moneda de su elección a cualquier cliente de la plataforma.',
  homeremind39: 'Kit de herramientas premium para marcas',
  homeremind40: 'Hemos optimizado la orientación al consumidor brindando a las empresas las herramientas y la información para conocer a sus clientes.',
  homeremind41: 'Respaldado por socios globales',
  homeremind42: 'Equipo NYECOINS',
  homeremind43: 'Permítanos presentarle al equipo que hará que todo suceda.',
  homeremind44: 'Mark Nichols',
  homeremind45: 'Cofundadora',
  homeremind46: 'Edward Blankenship',
  homeremind47: 'Ingeniero de programación superior',
  homeremind48: 'Richard Norman',
  homeremind49: 'Gerente de comunidad',
  homeremind50: 'Paul Garrett',
  homeremind51: 'Diseñadora de interfaz de usuario / UX',


  ieoIntro1: 'Incentivos de liquidez',
  ieoIntro2: 'Reserva de apuestas',
  ieoIntro3: 'Socios del ecosistema',
  ieoIntro4: 'Venta pública',
  ieoIntro5: 'Venta Privada A',
  ieoIntro6: 'Ronda de semillas',
  ieoIntro7: 'Equipo LinkToJoin',
  ieoIntro8: 'Reserva de tarifa empresarial',
}