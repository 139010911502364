import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-9fd6db30"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "data-block-c"
};
const _hoisted_2 = {
  class: "data-block-r"
};
import { ref, onBeforeUnmount, watch } from 'vue';
export default {
  __name: 'DataBlock',
  props: {
    price: {
      type: String,
      default: '0'
    },
    max: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    decimal: {
      type: Number,
      default: 2
    },
    bottom: {
      type: Boolean,
      default: false
    },
    colorL: {
      type: String
    },
    bg: {
      type: String
    }
  },

  setup(__props) {
    const props = __props;
    const num = ref(0);
    const bgWidth = ref('0%');
    const last = ref(0);
    const lastWatch = watch(() => props.price, () => {
      num.value = (Math.random() * (props.max - props.min) + props.min).toFixed(2);
      bgWidth.value = (num.value / props.max * 100).toFixed(2) + '%';
      let minus = [0.05, 0.04, 0.03, 0.02, 0.01];

      if (props.bottom) {
        minus = [0.01, 0.02, 0.03, 0.04, 0.05];
      }

      let lastNum = 0;

      if (Number(props.price) > 0.01) {
        lastNum = props.price - minus[props.index] - Number((Math.random() * 0.01).toFixed(4));
      } else {
        const mx = Math.pow(0.1, props.decimal - 4);
        lastNum = Number(props.price) - minus[props.index] * mx - Number((Math.random() * mx).toFixed(props.decimal - 1));
      }

      last.value = lastNum > 0 ? lastNum.toFixed(props.decimal) : 0;
    });
    onBeforeUnmount(() => {
      lastWatch();
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: "data-block-l",
        style: _normalizeStyle({
          color: props.colorL
        })
      }, _toDisplayString(last.value), 5), _createElementVNode("div", _hoisted_2, _toDisplayString(num.value), 1), _createElementVNode("div", {
        class: "data-block-bg",
        style: _normalizeStyle({
          backgroundColor: props.bg,
          width: bgWidth.value
        })
      }, null, 4)]);
    };
  }

};