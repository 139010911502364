import req from '@/util/request'

export function marketList(contract = '', secondContract = '') {
  const data = {}
  if (contract !== '') data['contract'] = contract
  if (secondContract !== '') data['second_contract'] = secondContract
  return req.post('/api/exchange/market', data)
}

export function getCaptcha(captchaId = '') {
  const data = {}
  if (captchaId !== '') data['captcha_id'] = captchaId
  return req.post('/api/captcha/index', data)
}

export function sendEmailVerify(data) {
  return req.post('/api/captcha/sendEmailVerify', data)
}

export function register(data) {
  return req.post('/api/login/register', data)
}

export function loginApi(data) {
  return req.post('/api/login/index', data)
}

export function passwordUpdate(data) {
  return req.post('/api/account/password', data)
}

export function passwordForget(data) {
  return req.post('/api/login/forgot', data)
}

export function updateEmail(data) {
  return req.post('/api/account/email', data)
}

export function getUserInfo(data) {
  return req.post('/api/account/info', data)
}

export function exchangeRobot(data) {
  return req.post('/api/exchange/robot', data)
}

export function getUserAsset(data) {
  return req.post('/api/account/asset', data)
}

export function exchangeTrade(type, data) {
  return req.post(`/api/exchange/trade/${type}`, data)
}

export function orderList(data) {
  return req.post('/api/exchange/orderList', data)
}

export function exchangeCancel(type, data) {
  return req.post(`/api/exchange/cancel/${type}`, data)
}

export function contractList(data) {
  return req.post('/api/exchange/contractList', data)
}

export function balanceHistory(data) {
  return req.post('/api/account/fund', data)
}

export function exchangeCoin(data) {
  return req.post('/api/exchange/coin', data)
}

export function coinContract(data) {
  return req.post('/api/exchange/coinContract', data)
}

export function withdraw(data) {
  return req.post('/api/account/withdraw', data)
}

export function transfer(data) {
  return req.post('/api/account/assetMove', data)
}

export function rechargeList(data) {
  return req.post('/api/account/rechargeList', data)
}

export function withdrawList(data) {
  return req.post('/api/account/withdrawList', data)
}

export function homeInfo(data) { // type: 0 轮播图 1 公告 
  return req.post('/api/common/article', data)
}

export function auth(data) {
  return req.post('/api/account/auth', data)
}

export function inviteCode(data) {
  return req.post('/api/account/inviteList', data)
}

export function getConfig(data) {
  return req.post('/api/common/config', data)
}

export function closeContract(data) {
  return req.post('/api/exchange/closeContract', data)
}

export function setSlTp(data) {
  return req.post('/api/exchange/setSlTp', data)
}

export function getRechargeCode(data) {
  return req.post('/api/account/wallet', data)
}

export function ieoOrder(data) {
  return req.post('/api/ieo/order', data)
}

export function ieoTrade(data) {
  return req.post('/api/ieo/trade', data)
}

export function closeContractAll(data) {
  return req.post('/api/exchange/closeContractAll', data)
}

export function assetRate(data) {
  return req.post('/api/exchange/holdAssetRate', data)
}

export function withdrawCancel(data) {
  return req.post('/api/account/withdrawCancel', data)
}

export function pledgeLock(data) {
  return req.post('/api/ieo/pledgeLock', data)
}

export function ieoSell(data) {
  return req.post('/api/ieo/ieoSell', data)
}

export function pledgeList(data) {
  return req.post('/api/ieo/pledgeList', data)
}

export function ieoSellList(data) {
  return req.post('/api/ieo/ieoSellList', data)
}

export function ieoStat(data) {
  return req.post('/api/ieo/stat', data)
}

export function accountStat(data) {
  return req.post('/api/account/stat', data)
}

export function accountInvite(data) {
  return req.post('/api/account/invite', data)
}

export function accountCommission(data) {
  return req.post('/api/account/commission', data)
}