export default {
  required: 'Requeridas',
  home: 'Início',
  market: 'Mercados',
  currency: 'Troca',
  walletTrade: 'Local',
  contract: 'Contrato',
  wallet: 'Carteiras',
  pulling: 'Puxe para baixo para atualizar...',
  loosing: 'Solte para atualizar...',
  loading: 'Carregando...',
  loadingError: 'Falha ao carregar',
  nothing: 'Sem dados',
  finished: '',
  price: 'Preço',
  number: 'Quantidade',
  recharge: 'Depósito',
  transfer: 'Transferir',
  invite: 'Convidar',
  inviteDes: 'Convide amigos para se juntar a nós',
  contact: 'Contate-nos',
  contactDes: '7*24 On-line',
  fastTrade: 'Opções',
  name: 'Nome',
  fastPrice: 'Preço',
  trend: '24H %',
  all: 'Todos',
  growthList: 'Gainers',
  loserList: 'Perdedoras',
  vol: '24H Vol',
  buy: 'Comprar',
  sell: 'Vender',
  optimal: 'Preço de mercado',
  marketOrder: 'Comércio de mercado',
  limitOrder: 'Comércio Limite',
  useable: 'Disponível',
  tradeVol: 'Montante',
  entrust: 'Ordem limite',
  entrustTotal: 'Montante',
  noLogin: 'Sair, ',
  toLogin: 'Conecte-se agora',
  cancelOrder: 'Cancelar',
  cancelError: 'Falha ao cancelar',
  cancelSuccess: 'Cancelar com sucesso',
  cancelTip: 'Claro que cancela?',
  completed: 'Concluída',
  canceled: 'Cancelada',
  marketPrice: 'Mercado',
  limitPrice: 'Limite',
  entrustPrice: 'Preço',
  entrustNum: 'Quantidade',
  currencyType: 'Símbolo',
  headerCurrencyType: 'Símbolo',
  currencyOrder: 'Registro Comercial',
  history: 'História',
  tryAgain: 'Tente novamente',
  entrustPriceTip: 'preço de entrada',
  tradeNumTip: 'quantidade de entrada',
  tradeNumLess: 'A quantidade deve ser maior que 0',
  numLessTip: 'Saldo insuficiente',
  tradePriceTip: 'Valor de entrada',
  balanceTip: 'Saldo insuficiente',
  tradeError: 'Falha ao negociar',
  tradeSuccess: 'Negocie com sucesso',
  tip: 'Perceber',
  tradeConfirm: 'Confirme para negociar?',
  cancelText: 'Cancelar',
  confirmText: 'confirme',
  toBuy: 'Comprar',
  toSell: 'Vender a descoberto',
  confirmToBuy: 'Comprar',
  confirmToSell: 'Vender a descoberto',
  slPrice: 'SL Preço',
  sl: 'SL',
  tp: 'TP',
  tpPrice: 'TP Preço',
  currentOrder: 'Ordem atual',
  historyOrder: 'História',
  setting: 'Definir',
  unwind: 'Perto',
  handUnwind: 'Fechar manualmente',
  futuresPay: '',
  forceUnwind: 'Liquidação',
  slTp: 'SL/TP',
  fastTradeUnwind: 'Opções',
  blowUp: 'Liquidação',
  openNum: 'Quantidade',
  warrant: 'Margem',
  openPrice: 'Preço',
  fee: 'Taxa',
  profit: 'Lucro',
  lastPrice: 'Preço atual',
  unwindPrice: 'Preço Fechado',
  unwindError: 'Falha ao fechar',
  unwindSuccess: 'Fechar com sucesso',
  setSuccess: 'Definido com sucesso',
  setError: 'Falha ao definir',
  setSlTp: 'Definir SL/TP',
  second: 's',
  multiple: 'Múltipla',
  direction: 'Tipo',
  tradeCurrency: 'Símbolo',
  loss: 'Perda',
  fill: 'Vencer',
  executePrice: 'Preço',
  currentPrice: 'Preço atual',
  closePrice: 'Preço Fechado',
  secondNum: 'Período',
  contractOrder: 'Ordens de Futuros',
  fastTradeOrder: 'Ordens de Opções',
  overview: 'Visão geral',
  ieoBuy: 'IEO',
  balanceTotal: 'Ativo total',
  withdraw: 'retirar',
  tradeOrder: 'Ordem comercial',
  rechargeHistory: 'Registro de Depósito',
  withdrawHistory: 'Retirar registro',
  freeze: 'Congeladas',
  amountTo: '',
  tipText: 'Perceber',
  ieoTip: 'O IEO não está aberto agora, tente novamente mais tarde',
  financeList: 'Registro Financeiro',
  currencyAccount: 'Conta Spot',
  contractAccount: 'Conta de Futuros',
  time: 'Tempo',
  status: 'Status',
  balanceTyle: 'Conta',
  pay: 'Resultado',
  income: 'Renda',
  lastBalance: 'Saldo',
  orderNo: 'ID',
  money: 'Montante',
  date: 'Encontro',
  finallyIncome: 'receber valor',
  commitMoney: 'Montante',
  orderNum: 'ID',
  waitReview: 'Revendo',
  reviewPass: 'Passado',
  reviewReject: 'Rejeitada',
  waitWallet: 'Revendo',
  walletPass: 'Passado',
  walletReject: 'Rejeitada',
  withdrawSuccess: 'Retirada com sucesso',
  withdrawError: 'Falha ao transferir',
  high24H: '24H Alta',
  low24H: '24H Baixo',
  num24H: '24H Vol',
  minute: 'Minutos',
  hour: 'Horas',
  day: 'Dia',
  week: 'Semana',
  markets: 'Mercado',
  open: 'Abrir',
  close: 'Perto',
  high: 'Alta',
  low: 'Baixo',
  volume: 'Vol',
  fastLogin: 'Conecte-se agora',
  register: 'registro',
  auth: 'Verificar',
  authSuccess: 'Verificada',
  noPass: 'Rejeitada',
  authCommit: 'Revendo',
  onAuth: 'Não verificado',
  onLogin: 'Sair',
  securitySet: 'Segurança',
  userAgree: 'Suporte a usuários',
  aboutUs: 'Sobre nós',
  logout: 'Sair',
  logoutSuccess: 'Sair com sucesso',
  lookAuthInfo: 'Verifique minhas informações',
  authAgain: 'Verificar novamente',
  authInfo: 'Em formação',
  account: 'Conta',
  countryArea: 'País/Região',
  idNo: 'ID#',
  idNoTip: 'Insira o número de identificação',
  email: 'Email',
  emailInput: 'Insira o endereço de e-mail',
  setLoginPwd: 'Definir senha de login',
  loginPwdInput: 'Digite a senha de login (8-20 caracteres)',
  loginPwdTip: 'Inserir senha de login',
  loginPwdLen: 'A senha de login deve ter de 8 a 20 caracteres',
  setFinancePwd: 'Definir senha do fundo',
  financePwdInput: 'Insira a senha do fundo (6-20 caracteres)',
  financePwdTip: 'Insira a senha do fundo',
  financePwdLen: 'A senha do fundo deve ter de 6 a 20 caracteres',
  inviteCode: 'Código de Convite',
  inviteCodeTip: 'Inserir código de convite',
  inviteInput: 'O código de convite é obrigatório',
  imageCode: 'Captcha',
  imageCodeInput: 'Inserir captcha',
  registerTip: 'Registrar significa que você concorda ',
  agree: '《Acordo de usuários》',
  agreeText: 'Acordo de usuários',
  next: 'Próxima',
  registerSuccess: 'Registre-se completamente',
  registerError: 'Falha ao registrar',
  securityCheck: 'Segurança',
  checkCodeInput: 'Insira o código de verificação',
  sendCheckCode: 'Mandar',
  sendSuccess: 'Enviei',
  sendError: 'Falha ao enviar',
  welcome: 'Bem-vindo ao',
  loginEmail: 'Email',
  loginEmailInput: 'Insira o endereço de e-mail',
  loginPwd: 'Senha de login',
  login: 'Conecte-se',
  forgetPwd: 'Esqueceu a senha',
  loginTip: 'Entrar significa que você concorda',
  loginSuccess: 'Entrar completamente',
  loginError: 'Falha ao fazer login',
  fullname: 'Nome',
  fullnameTip: 'Insira seu nome',
  country: 'País/Região',
  countryTip: 'País/região de entrada',
  imgFront: 'Carregue uma foto da frente do seu ID',
  imgReverse: 'Carregue uma foto do verso do seu documento de identidade',
  commit: 'Enviar',
  commitSuccess: 'Enviar completamente',
  commitError: 'Falha ao enviar',
  selectCurrency: 'Escolha cripto',
  rechargeNet: 'Rede',
  rechargeAddress: 'Endereço de Depósito',
  copyAddress: 'Copiar endereço',
  minRecharge: 'Valor mínimo de depósito',
  copySuccess: 'Copiar completamente',
  copyError: 'Falha ao copiar',
  withdrawTip: 'Você pode sacar depois de verificar sua conta',
  withdrawAddress: 'Endereço de retirada',
  withdrawAddressInput: 'Insira o endereço de retirada',
  withdrawNet: 'Rede',
  withdrawNum: 'Montante',
  withdrawNumInput: 'Valor de entrada',
  withdrawNumLess: 'Saldo insuficiente',
  withdrawMin: 'Valor mínimo de retirada',
  balanceUse: 'Disponível',
  actualMoney: 'receber valor',
  actualMoneyText: 'receber valor',
  financePwd: 'Senha do fundo',
  trade: '',
  withdrawFail: 'Falha ao retirar',
  commitTime: 'Encontro',
  from: 'A partir de',
  to: 'Para',
  transferMoney: 'Valor de entrada',
  transferSuccess: 'Transferir completamente',
  transferError: 'Falha ao transferir',
  transferHistory: 'Registro de transferência',
  updateFinancePwd: 'Alterar senha do fundo',
  confirmPwd: 'Confirme a Senha',
  confirmPwdInput: 'Insira a senha de confirmação',
  updatePwd: 'Mudar senha',
  updatePwdSuccess: 'mudar completamente',
  updatePwdError: 'Falha ao alterar',
  noAlike: 'A senha é inconsistente',
  emailUpdate: 'Mude o endereço de email',
  loginPwdUpdate: 'Alterar senha de acesso',
  financePwdUpdate: 'Alterar senha do fundo',
  newEmail: 'Novo Endereço de Email',
  emailUpdateTip: 'Insira o novo endereço de e-mail',
  checkEmail: 'Verificar endereço de e-mail',
  updateEmail: 'Mude o endereço de email',
  updateEmailError: 'Falha ao alterar',
  updateEmailSuccess: 'mudar completamente',
  checkError: 'Falha ao verificar',
  pwdLen: '8-20 caracteres',
  inviteUrl: 'Link de referência',
  myInviteCode: 'Código de Convite',
  fastInvite: 'Convidar agora',
  myInvite: 'Lista de convites',
  registerTime: 'Encontro',
  bulletin: 'Anúncio',
  inviteFriend: 'Convide amigos',
  saveAccount: 'Salvar conta e senha',
  indicator: 'Indicadoras',
  type: 'Tipos de',
  amount: 'Montante',
  scanError: 'Falha no reconhecimento de imagem',
  scanInitErr: 'Inicialização falhou',
  noCamera: 'Sem permissão para câmera',
  secondTip: 'O valor não deve ser inferior a {tip}',
  completePrice: 'Preço',
  profitText: 'Lucro',
  profitAndLoss: 'Lucro e perda estimados',
  timeUp: 'tempo limite',
  balanceText: 'História',
  closeAll: 'Feche tudo',
  assetRate: 'Taxa de Margem',
  cancel: 'Cancelar',
  cancelInfo: 'Confirmar para cancelar?',
  buyCurrency: 'Símbolo',
  closeCurrency: 'Moeda',
  buyPrice: 'Preço',
  valuation: 'Avaliação',
  profitP: 'Produção',
  buySuccess: 'com sucesso',
  swiperTitle1: 'A maior bolsa de criptomoedas digitais do mundo',
  swiperSub1: 'Confiado por mais de 100.000 usuários',

  ieoBuying: 'Em andamento',
  ieoOpen: 'Data de início (UTC-5)',
  ieoClose: 'Data de término(UTC-5)',
  about: 'Cerca de',
  ieoPlan: 'Distribuição',
  ieoInfo: 'Em formação',
  ieoContact: 'Mídia social',
  ieoStart: 'Inicialização',
  ieoBuyStart: 'IEO Começar',
  ieoBuyEnd: 'IEO Terminar',
  ieoIsTrade: 'Lançar',
  introduce: 'Introdução',
  ieoSubBuy: 'IEO',
  subBuyHistory: 'IEO História',
  rechargeTip1: '1. Este é o seu endereço mais novo e exclusivo, a plataforma nunca pedirá que você transfira fundos para outra conta ou solicite seu código de verificação.',
  rechargeTip2: '2. O depósito requer pelo menos 10 blocos de rede para confirmação. Após a confirmação, sua conta receberá automaticamente os fundos.',
  withdrawTip1: '1. Certifique-se de selecionar a mesma rede para esta retirada como aquela no lado do depósito e verifique se a plataforma de recebimento oferece suporte à rede. Caso contrário, você perderá seus bens.',
  withdrawTip2: '2. Os bloqueios de rede confirmarão sua retirada após a plataforma aprovar sua inscrição.',
  withdrawTip3: '3. A retirada requer pelo menos 10 blocos de rede para confirmação. Após a confirmação, sua conta receberá automaticamente os fundos.',
  coming: 'Está chegando.',
  createTeam: 'Fundo de reserva',
  angelFund: 'Fundo anjo',
  ecology: 'Instituições e Ecossistemas',
  presale: 'Lançamento de pré-venda',
  mechanism: 'Mecanismo de Mineração',
  trading: 'Negociação de câmbio',

  issuePrice: 'Preço de Emissão',
  minBuyPrice: 'Assinatura Mínima',
  buyNum: 'Assinaturas por Dia',
  buyTotal: 'Assinatura Diária Total',
  Increment: 'Incremento de Assinatura',
  blockchain: 'Blockchain',

  sellTotal: 'Emissão Total',
  currentBuy: 'Assinatura Atual',
  lessMinTip: 'Não pode ser inferior ao valor mínimo.',
  stakeAccount: 'Conta de aposta',
  serviceAccount: 'Conta de serviço',
  lock: 'Trancar',
  lockPrice: 'Preço bloqueado',
  ieoHistory: 'História do IEO',
  lockHistory: 'Histórico de bloqueio',
  sellHistory: 'Histórico de vendas',
  stake: 'Estacando',
  service: 'Serviço',
  ieoBuyTip: '*Aviso IEO: O número de LTJ é limitado. Analisaremos o aplicativo IEO do usuário e sortearemos aleatoriamente a cada 3 horas. Usuários com uma quantidade relativamente grande terão maior probabilidade de comprar LTJ. Cada usuário pode comprar até 3 vezes por dia.',
  ieoLockTip: '*Preço de bloqueio: os usuários precisam usar o USDT como moeda de garantia para bloquear o preço do LTJ, e a taxa de garantia é de 30% do valor do LTJ. Após o lançamento do LTJ, os usuários podem vender o LTJ pelo preço bloqueado naquele momento.',
  ieoSellTip: '*Venda IEO: A venda do LTJ exige 10% do valor do LTJ como taxa de serviço. Depois de vender o LTJ, o USDT que você trocou será descongelado em 20 dias e o USDT prometido será descongelado em 20 dias.',
  whitePaper: 'PAPEL BRANCO',
  cost: 'Custo',
  currentEstimate: 'Avaliação atual',
  stakeNum: 'Valor da aposta',
  estimate: 'Avaliação',
  consume: 'Consumir',

  seedSell: 'Semente Rodada',
  privateSell: 'Rodada Privada',
  openSell: 'Rodada KOL',
  ieoAward: 'Rodada Pública',
  ieoGrowUp: 'Equipe',
  partner: 'Conselheiros',
  consultant: 'Marketing',
  ieoName: 'Liquidez',
  conAward: 'IA para ganhar',
  staking: 'Estacando',

  release: 'Liberar',
  closedTime: 'Tempo Fechado',
  remark: 'Observação',

  totalProfit: 'Lucro total',
  transferIn: 'Transferência de entrada',
  transferOut: 'Transferir para Fora',
  equity: 'patrimônio líquido',

  
  homeremind1: 'Criptomoedas populares',
  homeremind2: 'Ver mais mercados',
  homeremind3: 'Último preço(USD)',
  homeremind4: 'Mudança 24H',
  homeremind5: 'Ação',
  homeremind6: 'Veja mais',
  homeremind7: "Principais escolhas dos usuários",
  homeremind8: 'Volume de Negociação 24h',
  homeremind9: 'Ativos virtuais principais',
  homeremind10: 'Nossos principais produtos',
  homeremind11: 'Uma grande variedade de ferramentas de negociação para escolher',
  homeremind12: 'Ver',
  homeremind13: 'Mais de 700 criptomoedas disponíveis para negociação',
  homeremind14: 'Atualize sua experiência negociando futuros',
  homeremind15: 'NYECoins Earn',
  homeremind16: 'Melhores APYs do setor',
  homeremind17: 'Nossos serviços diversificados',
  homeremind18: 'Explore possibilidades ilimitadas com nossos diversos serviços',
  homeremind19: 'Assinatura IEO',
  homeremind20: "As melhores criptomoedas em pré-venda",
  homeremind21: 'Atendimento ao cliente VIP',
  homeremind22: '7*24 horas',
  homeremind23: 'Comunidade',
  homeremind24: 'Descubra oportunidades de riqueza aqui conosco',
  homeremind25: 'Negocie em qualquer lugar. Qualquer lugar, qualquer hora.',
  homeremind26: 'Fique por dentro de nosso aplicativo e cliente de desktop.',
  homeremind27: 'Siga-nos nas redes sociais',
  homeremind28: 'Mantenha-se atualizado com nossas últimas notícias',
  homeremind29: 'Fique na negociação. Qualquer lugar, qualquer hora.',
  homeremind30: 'Fique por dentro de nosso APP móvel e terminal de site.',

  msb: 'NYECoins é regulamentado em MSB, EUA.',
  msb1: 'Confiável, seguro, rápido',
  checkNow: 'Verifique agora',

  foundingTeam: 'Equipe Fundadora',
  advisorTeam: 'Equipe de consultores',
  community: 'Comunidade',

  phone: 'Número de telefone',
  phoneTip: 'Insira seu número de telefone',


  rebateAccount: 'conta de comissão',
  rebateType: 'tipo',
  recommend: 'Referência',
  myRebate: 'minha comissão',
  rebateRule: 'Regras da Comissão',
  yes: 'Sim',
  no: 'Não',
  authName: 'Verificar',
  countryName: 'Área do país',
  allCommission: 'Comissão total',
  myClient: 'Meus clientes',
  inviteUrl1: 'Link de referência',
  inviteCode1: 'Código de Referencia',
  commissioned: 'comissão',

  swiperTitle2: 'O líder global em comércio de criptografia on-line. Compre, venda e mantenha criptomoedas.',
  swiperSub2: 'Orientamos milhões de usuários globais em sua jornada pelos ativos digitais.',

  homeremind31: 'O que nós fazemos?',
  homeremind32: 'Queremos confundir a linha entre os comerciantes de criptomoedas e os consumidores comuns. Popularizaremos a criptomoeda oferecendo entrada gratuita e ajudando as pessoas a compreender e confiar no que acreditamos ser o futuro do dinheiro.',
  homeremind33: 'Características de NYECOINS',
  homeremind34: 'NYECOINS reúne fornecedores e compradores sem um banco central ou administrador único para garantir negócios claros e justos',
  homeremind35: 'Custos de transação mais baixos',
  homeremind36: 'Uma plataforma descentralizada disponível para clientes com menores pagamentos de transações e despesas operacionais.',
  homeremind37: 'Transações C2C',
  homeremind38: 'As operações C2C são fáceis, rápidas e seguras com NYECOINS. Envie BTC ou a moeda de sua escolha para qualquer cliente da plataforma.',
  homeremind39: 'Kit de ferramentas premium para marcas',
  homeremind40: 'Otimizamos a segmentação do consumidor, dando às empresas as ferramentas e informações para conhecer seus clientes.',
  homeremind41: 'Apoiado por parceiros globais',
  homeremind42: 'Equipe NYECOINS',
  homeremind43: 'Deixe-nos apresentar a equipe que fará tudo acontecer.',
  homeremind44: 'Mark Nichols',
  homeremind45: 'Cofundador',
  homeremind46: 'Edward Blankenship',
  homeremind47: 'Engenheiro de software senior',
  homeremind48: 'Richard Norman',
  homeremind49: 'Gerente de comunidade',
  homeremind50: 'Paul Garrett',
  homeremind51: 'Designer UI/UX',


  ieoIntro1: 'Incentivos de Liquidez',
  ieoIntro2: 'Reserva de Estacamento',
  ieoIntro3: 'Parceiros do Ecossistema',
  ieoIntro4: 'Venda Pública',
  ieoIntro5: 'Venda Privada A',
  ieoIntro6: 'Rodada de Sementes',
  ieoIntro7: 'Equipe LinkToJoin',
  ieoIntro8: 'Reserva de taxa empresarial',
}