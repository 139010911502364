export default {
  required: 'Необходимый',
  home: 'Начинать',
  market: 'Рынки',
  currency: 'торговля',
  walletTrade: 'Место',
  contract: 'Фьючерсы',
  wallet: 'Кошельки',
  pulling: 'Потяните вниз, чтобы обновить...',
  loosing: 'Отпустите, чтобы обновить...',
  loading: 'Загрузка...',
  loadingError: 'Ошибка загрузки',
  nothing: 'Нет данных',
  finished: '',
  price: 'Цена',
  number: 'Количество',
  recharge: 'Депозит',
  transfer: 'Перевод',
  invite: 'Приглашать',
  inviteDes: 'Пригласите друзей присоединиться к нам',
  contact: 'Контакт',
  contactDes: '7*24 онлайн',
  fastTrade: 'Опции',
  name: 'Имя',
  fastPrice: 'Цена',
  trend: '24H %',
  all: 'Все',
  growthList: 'Гейнеры',
  loserList: 'Неудачники',
  vol: '24H Vol',
  buy: 'Купить',
  sell: 'Продавать',
  optimal: 'Рыночная цена',
  marketOrder: 'Рыночная торговля',
  limitOrder: 'Лимитная торговля',
  useable: 'Доступный',
  tradeVol: 'Количество',
  entrust: 'Лимитный ордер',
  entrustTotal: 'Количество',
  noLogin: 'Выйти, ',
  toLogin: 'Войти сейчас',
  cancelOrder: 'Отмена',
  cancelError: 'Не удалось отменить',
  cancelSuccess: 'Отменить успешно',
  cancelTip: 'Обязательно отменить？',
  completed: 'Завершенный',
  canceled: 'Отменено',
  marketPrice: 'Рынок',
  limitPrice: 'Ограничение',
  entrustPrice: 'Цена',
  entrustNum: 'Количество',
  currencyType: 'Условное обозначение',
  headerCurrencyType: 'Валюта',
  currencyOrder: 'Торговая запись',
  history: 'История',
  tryAgain: 'Попробуй еще раз',
  entrustPriceTip: 'Входная цена',
  tradeNumTip: 'Входное количество',
  tradeNumLess: 'Количество должно быть больше 0',
  numLessTip: 'Недостаточный баланс',
  tradePriceTip: 'Сумма ввода',
  balanceTip: 'Недостаточный баланс',
  tradeError: 'Не удалось торговать',
  tradeSuccess: 'Торгуйте успешно',
  tip: 'Уведомление',
  tradeConfirm: 'Подтвердить обмен？',
  cancelText: 'Отмена',
  confirmText: 'Подтверждать',
  toBuy: 'Cкупать',
  toSell: 'продавать без покрыти',
  confirmToBuy: 'Cкупать',
  confirmToSell: 'продавать без покрыти',
  slPrice: 'SL Цена',
  sl: 'SL',
  tp: 'TP',
  tpPrice: 'TP Цена',
  currentOrder: 'Текущий заказ',
  historyOrder: 'История',
  setting: 'Поставил',
  unwind: 'Закрывать',
  handUnwind: 'Закрыть вручную',
  futuresPay: '',
  forceUnwind: 'Ликвидация',
  slTp: 'SL/TP',
  fastTradeUnwind: 'Опции',
  blowUp: 'Ликвидация',
  openNum: 'Количество',
  warrant: 'Прибыль',
  openPrice: 'Цена',
  fee: 'Платеж',
  profit: 'Выгода',
  lastPrice: 'Текущая цена',
  unwindPrice: 'Закрытая цена',
  unwindError: 'Не удалось закрыть',
  unwindSuccess: 'Закрыть успешно',
  setSuccess: 'Установлено успешно',
  setError: 'Не удалось установить',
  setSlTp: 'Поставил SL/TP',
  second: 's',
  multiple: 'Несколько',
  direction: 'Виды',
  tradeCurrency: 'Условное обозначение',
  loss: 'потеря',
  fill: 'выгода',
  executePrice: 'Цена',
  currentPrice: 'Текущая цена',
  closePrice: 'Закрытая цена',
  secondNum: 'Период',
  contractOrder: 'Фьючерсные ордера',
  fastTradeOrder: 'Заказы опционов',
  overview: 'Обзор',
  ieoBuy: 'IEO',
  balanceTotal: 'Всего активов',
  withdraw: 'отзывать',
  tradeOrder: 'Торговый приказ',
  rechargeHistory: 'Депозитная запись',
  withdrawHistory: 'Вывести запись',
  freeze: 'замороженный',
  amountTo: '',
  tipText: 'Уведомление',
  ieoTip: 'IEO сейчас не открывается, повторите попытку позже',
  financeList: 'Финансовая отчетность',
  currencyAccount: 'Спотовая учетная запись',
  contractAccount: 'Фьючерсный счет',
  time: 'Время',
  status: 'Положение дел',
  balanceTyle: 'Счет',
  pay: 'Исход',
  income: 'Доход',
  lastBalance: 'баланс',
  orderNo: 'ID',
  money: 'Количество',
  date: 'дата',
  finallyIncome: 'Получить сумму',
  commitMoney: 'Количество',
  orderNum: 'ID',
  waitReview: 'Проверка',
  reviewPass: 'Прошедший',
  reviewReject: 'Отклоненный',
  waitWallet: 'Проверка',
  walletPass: 'Прошедший',
  walletReject: 'Отклоненный',
  withdrawSuccess: 'Выйти успешно',
  withdrawError: 'Не удалось передать',
  high24H: '24H Высокий',
  low24H: '24H Низкий',
  num24H: '24H Объем торгов',
  minute: 'Минуты',
  hour: 'Часы',
  day: 'День',
  week: 'Неделя',
  markets: 'Рынок',
  open: 'Открыть',
  close: 'Закрывать',
  high: 'Высокий',
  low: 'Низкий',
  volume: 'Объем торгов',
  fastLogin: 'Войти сейчас',
  register: 'регистр',
  auth: 'Проверять',
  authSuccess: 'проверено',
  noPass: 'Отклоненный',
  authCommit: 'Проверка',
  onAuth: 'Непроверенный',
  onLogin: 'Выйти',
  securitySet: 'Безопасность',
  userAgree: 'Соглашения',
  aboutUs: 'О нас',
  logout: 'Выйти',
  logoutSuccess: 'Выйти успешно',
  lookAuthInfo: 'Проверить мою информацию',
  authAgain: 'Подтвердить еще раз',
  authInfo: 'Информация',
  account: 'Счет',
  countryArea: 'Страна/регион',
  idNo: 'ID#',
  idNoTip: 'Введите идентификационный номер',
  email: 'Эл. адрес',
  emailInput: 'Введите адрес электронной почты',
  setLoginPwd: 'Установить пароль для входа',
  loginPwdInput: 'Введите пароль для входа (8-20 символов)',
  loginPwdTip: 'Введите пароль для входа',
  loginPwdLen: 'Пароль для входа должен быть 8-20 символов',
  setFinancePwd: 'Установить пароль фонда',
  financePwdInput: 'Введите пароль фонда (6-20 символов)',
  financePwdTip: 'Введите пароль фонда',
  financePwdLen: 'Пароль фонда должен состоять из 6-20 символов.',
  inviteCode: 'Код приглашения',
  inviteCodeTip: 'Введите код приглашения',
  inviteInput: 'Требуется код приглашения',
  imageCode: 'Captcha',
  imageCodeInput: 'Введите капчу',
  registerTip: 'Регистрация означает, что вы согласны ',
  agree: '《Пользовательское соглашение》',
  agreeText: 'Пользовательское соглашение',
  next: 'Следующий',
  registerSuccess: 'Зарегистрироваться полностью',
  registerError: 'Не удалось зарегистрироваться',
  securityCheck: 'Безопасность',
  checkCodeInput: 'Введите проверочный код',
  sendCheckCode: 'послать',
  sendSuccess: 'Отправил',
  sendError: 'Не удалось отправить',
  welcome: 'Добро пожаловать в',
  loginEmail: 'Эл. адрес',
  loginEmailInput: 'Введите адрес электронной почты',
  loginPwd: 'Логин Пароль',
  login: 'Авторизоваться',
  forgetPwd: 'Забыли пароль',
  loginTip: 'Логин означает, что вы согласны',
  loginSuccess: 'Войти полностью',
  loginError: 'Не удалось войти',
  fullname: 'Имя',
  fullnameTip: 'Введите свое имя',
  country: 'Страна/регион',
  countryTip: 'Введите страну/регион',
  imgFront: 'Загрузите фотографию лицевой стороны вашего удостоверения личности',
  imgReverse: 'Загрузите фотографию обратной стороны вашего удостоверения личности',
  commit: 'Представлять на рассмотрение',
  commitSuccess: 'Отправить полностью',
  commitError: 'Не удалось отправить',
  selectCurrency: 'Выбрать крипто',
  rechargeNet: 'Сеть',
  rechargeAddress: 'Адрес депозита',
  copyAddress: 'Скопировать адрес',
  minRecharge: 'Минимальная сумма депозита',
  copySuccess: 'Копировать полностью',
  copyError: 'Не удалось скопировать',
  withdrawTip: 'Вы можете снять деньги после подтверждения своей учетной записи',
  withdrawAddress: 'Адрес вывода',
  withdrawAddressInput: 'Введите адрес вывода',
  withdrawNet: 'Сеть',
  withdrawNum: 'Количество',
  withdrawNumInput: 'Сумма ввода',
  withdrawNumLess: 'Недостаточный баланс',
  withdrawMin: 'Минимальная сумма вывода',
  balanceUse: 'Доступный',
  actualMoney: 'Получить сумму',
  actualMoneyText: 'Получить сумму',
  financePwd: 'Пароль фонда',
  trade: '',
  withdrawFail: 'Не удалось вывести',
  commitTime: 'Дата',
  from: 'Из',
  to: 'К',
  transferMoney: 'Сумма ввода',
  transferSuccess: 'Перенести полностью',
  transferError: 'Не удалось передать',
  transferHistory: 'Запись передачи',
  updateFinancePwd: 'Изменить пароль фонда',
  confirmPwd: 'Подтвердить Пароль',
  confirmPwdInput: 'Введите пароль для подтверждения',
  updatePwd: 'Изменить пароль',
  updatePwdSuccess: 'Полностью изменить',
  updatePwdError: 'Не удалось изменить',
  noAlike: 'Пароль несовместим',
  emailUpdate: 'Изменить адрес электронной почты',
  loginPwdUpdate: 'Изменить пароль для входа',
  financePwdUpdate: 'Изменить пароль фонда',
  newEmail: 'Новый E-mail адрес',
  emailUpdateTip: 'Введите новый адрес электронной почты',
  checkEmail: 'Подтвердить электронную почту',
  updateEmail: 'Изменить адрес электронной почты',
  updateEmailError: 'Не удалось изменить',
  updateEmailSuccess: 'Полностью изменить',
  checkError: 'Не удалось подтвердить',
  pwdLen: '8-20 символов',
  inviteUrl: 'Реферальная ссылка',
  myInviteCode: 'Код приглашения',
  fastInvite: 'Пригласить сейчас',
  myInvite: 'Список приглашенных',
  registerTime: 'Дата',
  bulletin: 'Объявление',
  inviteFriend: 'Пригласить друзей',
  saveAccount: 'Сохранить учетную запись и пароль',
  indicator: 'Индикаторы',
  type: 'Виды',
  amount: 'Количество',
  scanError: 'Не удалось распознать изображение',
  scanInitErr: 'Ошибка инициализации',
  noCamera: 'Нет разрешения на камеру',
  secondTip: 'Сумма не менее {tip}',
  completePrice: 'Цена',
  profitText: 'Выгода',
  profitAndLoss: 'Расчетная прибыль и убытки',
  timeUp: 'Время вышло',
  balanceText: 'История',
  closeAll: 'Закрыть все',
  assetRate: 'Ставка маржи',
  cancel: 'Отмена',
  cancelInfo: 'Подтвердить, чтобы отменить?',
  buyCurrency: 'Условное обозначение',
  closeCurrency: 'Валюта',
  buyPrice: 'Цена',
  valuation: 'оценка',
  profitP: 'Урожай',
  buySuccess: 'Успешно',
  swiperTitle1: 'Лучшая в мире цифровая криптобиржа',
  swiperSub1: 'Нам доверяют более 100 000 пользователей',

  ieoBuying: 'Непрерывный',
  ieoOpen: 'Дата начала (UTC-5)',
  ieoClose: 'Дата окончания(UTC-5)',
  about: 'О',
  ieoPlan: 'Распределение',
  ieoInfo: 'Информация',
  ieoContact: 'Социальные медиа',
  ieoStart: 'Инициализация',
  ieoBuyStart: 'IEO Начинать',
  ieoBuyEnd: 'IEO Заканчивать',
  ieoIsTrade: 'Запуск',
  introduce: 'Вступление',
  ieoSubBuy: 'IEO',
  subBuyHistory: 'IEO История',
  rechargeTip1: '1. Это ваш самый новый и уникальный адрес, платформа никогда не попросит вас перевести средства на другой счет или запросит ваш код подтверждения.',
  rechargeTip2: '2. Депозит требует не менее 10 сетевых блоков для подтверждения. После подтверждения на ваш счет автоматически поступят средства.',
  withdrawTip1: '1. Убедитесь, что вы выбрали ту же сеть для вывода средств, что и для депозита, и убедитесь, что принимающая платформа поддерживает эту сеть. В противном случае вы потеряете свои активы.',
  withdrawTip2: '2. Сетевые блоки подтвердят ваш вывод после того, как платформа одобрит вашу заявку.',
  withdrawTip3: '3. Для вывода требуется не менее 10 сетевых блоков для подтверждения. После подтверждения на ваш счет автоматически поступят средства.',
  coming: 'Это скоро произойдет.',
  createTeam: 'Резервный фонд',
  angelFund: 'Фонд Ангела',
  ecology: 'Институты и экосистемы',
  presale: 'Предпродажный релиз',
  mechanism: 'Механизм добычи',
  trading: 'Биржевая торговля',

  issuePrice: 'Цена вопроса',
  minBuyPrice: 'Минимальная подписка',
  buyNum: 'Подписок в день',
  buyTotal: 'Общая дневная подписка',
  Increment: 'Увеличение подписки',
  blockchain: 'Блокчейн',

  sellTotal: 'Общий выпуск',
  currentBuy: 'Текущая подписка',
  lessMinTip: 'Не может быть меньше минимальной суммы.',
  stakeAccount: 'Ставочный счет',
  serviceAccount: 'Сервисный аккаунт',
  lock: 'Замок',
  lockPrice: 'Заблокированная цена',
  ieoHistory: 'История НОО',
  lockHistory: 'История блокировки',
  sellHistory: 'История продаж',
  stake: 'Ставка',
  service: 'Услуга',
  ieoBuyTip: '*Уведомление IEO: количество LTJ ограничено. Мы просматриваем заявку пользователя на IEO и случайным образом разыгрываем лоты каждые 3 часа. Пользователи с относительно большой суммой будут иметь более высокую вероятность покупки LTJ. Каждый пользователь может покупать до 3 раз в день.',
  ieoLockTip: '* Фиксированная цена: пользователям необходимо использовать USDT в качестве залоговой валюты, чтобы зафиксировать цену LTJ, а коэффициент залога составляет 30% от стоимости LTJ. После запуска LTJ пользователи могут продавать LTJ по зафиксированной на тот момент цене.',
  ieoSellTip: '* Продажа IEO: Продажа LTJ требует 10% от стоимости LTJ в качестве платы за обслуживание. После продажи LTJ обмененные вами USDT будут разморожены через 20 дней, а заложенные USDT — через 20 дней.',
  whitePaper: 'БЕЛАЯ БУМАГА',
  cost: 'расходы',
  currentEstimate: 'Текущая оценка',
  stakeNum: 'Сумма ставки',
  estimate: 'оценка',
  consume: 'Потреблять',

  seedSell: 'Семенной раунд',
  privateSell: 'Частный раунд',
  openSell: 'КОЛ Раунд',
  ieoAward: 'Публичный раунд',
  ieoGrowUp: 'Команда',
  partner: 'Советники',
  consultant: 'Маркетинг',
  ieoName: 'Ликвидность',
  conAward: 'ИИ для заработка',
  staking: 'Ставка',

  release: 'Выпускать',
  closedTime: 'Время закрытия',
  remark: 'Примечание',

  totalProfit: 'Общая прибыль',
  transferIn: 'Трансфер в',
  transferOut: 'Трансфер из',
  equity: 'чистая стоимость',

  
  homeremind1: 'Популярные криптовалюты',
  homeremind2: 'Посмотреть больше рынков',
  homeremind3: 'Последняя цена(USD)',
  homeremind4: '24-часовая смена',
  homeremind5: 'Действие',
  homeremind6: 'Посмотреть больше',
  homeremind7: "Лучший выбор пользователей",
  homeremind8: '24-часовой объем торгов',
  homeremind9: 'Премьер-виртуальные активы',
  homeremind10: 'Наши основные продукты',
  homeremind11: 'Широкий выбор торговых инструментов на выбор',
  homeremind12: 'Место',
  homeremind13: 'Более 700 криптовалют, доступных для торговли',
  homeremind14: 'Улучшите свой опыт, торгуя фьючерсами',
  homeremind15: 'NYECoins Earn',
  homeremind16: 'Лучшие APY в отрасли',
  homeremind17: 'Наши разнообразные услуги',
  homeremind18: 'Откройте для себя неограниченные возможности с нашими разнообразными услугами',
  homeremind19: 'Подписка на IEO',
  homeremind20: "Лучшие предпродажные криптовалюты",
  homeremind21: 'Обслуживание VIP-клиентов',
  homeremind22: '7*24 часа',
  homeremind23: 'Сообщество',
  homeremind24: 'Откройте для себя возможности обогащения здесь, с нами',
  homeremind25: 'Торгуйте на ходу. Где угодно, когда угодно.',
  homeremind26: 'Будьте в курсе событий с помощью нашего приложения и клиента для настольных компьютеров.',
  homeremind27: 'Следуйте за нами в социальных сетях',
  homeremind28: 'Будьте в курсе наших последних новостей',
  homeremind29: 'Оставайтесь в трейдинге. Где угодно, когда угодно.',
  homeremind30: 'Будьте в курсе событий с помощью нашего мобильного приложения и веб-терминала.',

  msb: 'NYECoins регулируется MSB, США.',
  msb1: 'Надежный, безопасный, быстрый',
  checkNow: 'Проверь сейчас',

  foundingTeam: 'Команда основателей',
  advisorTeam: 'Команда консультантов',
  community: 'Сообщество',

  phone: 'Номер телефона',
  phoneTip: 'Введите свой номер телефона',


  rebateAccount: 'комиссионный счет',
  rebateType: 'тип',
  recommend: 'Направления',
  myRebate: 'моя комиссия',
  rebateRule: 'Правила комиссии',
  yes: 'Да',
  no: 'Нет',
  authName: 'Проверять',
  countryName: 'Страна/регион',
  allCommission: 'Общая комиссия',
  myClient: 'Мои клиенты',
  inviteUrl1: 'Реферальная ссылка',
  inviteCode1: 'Промо-код',
  commissioned: 'комиссия',

  swiperTitle2: 'Мировой лидер в онлайн-торговле криптовалютой. Покупайте, продавайте и храните криптовалюты.',
  swiperSub2: 'Мы помогли миллионам пользователей со всего мира в их путешествии по цифровым активам.',

  homeremind31: 'Что мы делаем?',
  homeremind32: 'Мы хотим стереть грань между торговцами криптовалютой и обычными потребителями. Мы будем популяризировать криптовалюту, предоставляя бесплатный вход и помогая людям понять и довериться тому, что, по нашему мнению, является будущим денег.',
  homeremind33: 'Особенности NYECOINS',
  homeremind34: 'NYECOINS объединяет продавцов и покупателей без центрального банка или единого администратора, чтобы обеспечить четкие и честные сделки.',
  homeremind35: 'Снижение транзакционных издержек',
  homeremind36: 'Децентрализованная платформа, доступная клиентам с меньшими транзакционными платежами и операционными расходами.',
  homeremind37: 'C2C-транзакции',
  homeremind38: 'С NYECOINS операции C2C становятся простыми, быстрыми и безопасными. Отправьте BTC или валюту по вашему выбору любому клиенту на платформе.',
  homeremind39: 'Премиум-инструментарий для брендов',
  homeremind40: 'Мы оптимизировали таргетинг на потребителей, предоставив компаниям инструменты и знания, позволяющие лучше узнать своих клиентов.',
  homeremind41: 'При поддержке глобальных партнеров',
  homeremind42: 'Команда NYECOINS',
  homeremind43: 'Позвольте представить вам команду, которая сделает все это возможным.',
  homeremind44: 'Mark Nichols',
  homeremind45: 'Соучредитель',
  homeremind46: 'Edward Blankenship',
  homeremind47: 'Старший инженер-программист',
  homeremind48: 'Richard Norman',
  homeremind49: 'Менеджер сообщества',
  homeremind50: 'Paul Garrett',
  homeremind51: 'UI/UX дизайнер',


  ieoIntro1: 'Стимулы ликвидности',
  ieoIntro2: 'Ставочный резерв',
  ieoIntro3: 'Экосистемные партнеры',
  ieoIntro4: 'Публичная продажа',
  ieoIntro5: 'Частная продажа А',
  ieoIntro6: 'Семенной раунд',
  ieoIntro7: 'Команда LinkToJoin',
  ieoIntro8: 'Резерв корпоративных сборов',
}