import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-7aaafc9d"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "currency-item"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = {
  class: "left"
};
const _hoisted_4 = {
  style: {
    "margin-left": "8px"
  }
};
const _hoisted_5 = {
  class: "right"
};
const _hoisted_6 = {
  class: "num"
};
const _hoisted_7 = {
  class: "price"
};
import { fmtImageSrc } from '@/util';
export default {
  __name: 'CurrencyItem',
  props: {
    icon: {
      type: String
    },
    name: {
      type: String,
      default: '--'
    },
    total: {
      type: String,
      default: '0'
    },
    active: {
      type: String,
      default: '0'
    }
  },

  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_van_image, {
        src: _unref(fmtImageSrc)(props.icon),
        width: "25",
        height: "25",
        round: ""
      }, null, 8, ["src"]), _createElementVNode("span", _hoisted_4, _toDisplayString(props.name), 1)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, _toDisplayString(props.total), 1), _createElementVNode("div", _hoisted_7, "$" + _toDisplayString(props.active), 1)])])]);
    };
  }

};