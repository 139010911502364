import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-73b56804"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "register-container"
};
const _hoisted_2 = {
  style: {
    "background-color": "#F6F6F6"
  }
};
const _hoisted_3 = {
  class: "register-content"
};
const _hoisted_4 = {
  class: "title"
};
const _hoisted_5 = {
  class: "label"
};
const _hoisted_6 = {
  class: "label"
};
const _hoisted_7 = {
  class: "label"
};
const _hoisted_8 = {
  class: "label"
};
const _hoisted_9 = {
  style: {
    "color": "red"
  }
};
const _hoisted_10 = {
  class: "label"
};
const _hoisted_11 = {
  style: {
    "color": "red"
  }
};
const _hoisted_12 = {
  class: "register-footer"
};
const _hoisted_13 = {
  class: "agreement"
};
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { Toast } from 'vant';
import 'vant/es/toast/style';
import NavGradient from '@/components/NavGradient.vue';
import CheckPopup from '@/components/CheckPopup.vue';
import { getCaptcha, register } from '@/apis';
import { useI18n } from "vue-i18n";
export default {
  __name: 'RegisterView',

  setup(__props) {
    const {
      t
    } = useI18n();
    const router = useRouter();
    const store = useStore();
    const route = useRoute();
    const email = ref('');
    const loginPwd = ref('');
    const financePwd = ref('');
    const inviteCode = ref('');
    const captcha = ref('123123');
    const phone = ref('');
    const checkShow = ref(false);
    const nextLoading = ref(false);
    const captchaId = ref('');
    const captchaImg = ref('');

    if (route.query.code) {
      inviteCode.value = route.query.code;
    }

    const init = async () => {
      const res = await getCaptcha();

      if (res.code === 200) {
        captchaId.value = res.data.id;
        captchaImg.value = res.data.img;
      }
    };

    onMounted(() => {
      init();
    });

    const showEmailCheck = () => {
      if (email.value === '') {
        Toast({
          message: t('emailInput'),
          position: 'bottom'
        });
        return;
      }

      if (loginPwd.value === '') {
        Toast({
          message: t('loginPwdTip'),
          position: 'bottom'
        });
        return;
      }

      if (loginPwd.value.length < 8 || loginPwd.value.length > 20) {
        Toast({
          message: t('loginPwdLen'),
          position: 'bottom'
        });
        return;
      }

      if (financePwd.value === '') {
        Toast({
          message: t('financePwdTip'),
          position: 'bottom'
        });
        return;
      }

      if (financePwd.value.length < 6 || financePwd.value.length > 20) {
        Toast({
          message: t('financePwdLen'),
          position: 'bottom'
        });
        return;
      }

      if (inviteCode.value === '') {
        Toast({
          message: t('inviteCodeTip'),
          position: 'bottom'
        });
        return;
      }

      if (captcha.value === '') {
        Toast({
          message: t('imageCodeInput'),
          position: 'bottom'
        });
        return;
      }

      if (phone.value === '') {
        Toast({
          message: t('phoneTip'),
          position: 'bottom'
        });
        return;
      }

      checkShow.value = true;
    };

    const next = async data => {
      nextLoading.value = true;
      const res = await register({
        captcha_id: captchaId.value,
        captcha_code: captcha.value,
        email: email.value,
        email_verify_key: data.checkKey,
        email_verify_code: data.checkCode,
        password: loginPwd.value,
        invite_code: inviteCode.value,
        ex_password: financePwd.value,
        phone: phone.value
      });
      nextLoading.value = false;

      if (res.code !== 200) {
        Toast({
          message: res.message !== '' ? res.message : t('registerError'),
          position: 'bottom'
        });
        return;
      }

      if (res.data.token) {
        store.commit('tokenChange', res.data.token);
        store.commit('emailChange', email.value);
        localStorage.setItem('astore_info', JSON.stringify({
          token: res.data.token,
          time: Math.round(new Date().getTime() / 1000),
          email: email.value
        }));
      }

      Toast({
        message: t('registerSuccess'),
        position: 'bottom'
      });
      router.replace({
        name: 'home'
      });
    };

    return (_ctx, _cache) => {
      const _component_a_input = _resolveComponent("a-input");

      const _component_a_input_password = _resolveComponent("a-input-password");

      const _component_van_button = _resolveComponent("van-button");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(NavGradient), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", null, [_createElementVNode("h2", _hoisted_4, _toDisplayString(_unref(t)('register')), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(t)('email')), 1), _createVNode(_component_a_input, {
        style: {
          "height": "40px",
          "font-size": "15px"
        },
        allowClear: "",
        placeholder: _unref(t)('emailInput'),
        value: email.value,
        "onUpdate:value": _cache[0] || (_cache[0] = $event => email.value = $event)
      }, null, 8, ["placeholder", "value"]), _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(t)('setLoginPwd')), 1), _createVNode(_component_a_input_password, {
        class: "pwd-input",
        placeholder: _unref(t)('loginPwdInput'),
        allowClear: "",
        value: loginPwd.value,
        "onUpdate:value": _cache[1] || (_cache[1] = $event => loginPwd.value = $event)
      }, null, 8, ["placeholder", "value"]), _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(t)('setFinancePwd')), 1), _createVNode(_component_a_input_password, {
        class: "pwd-input",
        placeholder: _unref(t)('setFinancePwd'),
        allowClear: "",
        value: financePwd.value,
        "onUpdate:value": _cache[2] || (_cache[2] = $event => financePwd.value = $event)
      }, null, 8, ["placeholder", "value"]), _createElementVNode("div", _hoisted_8, [_createTextVNode(_toDisplayString(_unref(t)('inviteCode')), 1), _createElementVNode("span", _hoisted_9, " (" + _toDisplayString(_unref(t)('required')) + ")", 1)]), _createVNode(_component_a_input, {
        class: "pwd-input",
        placeholder: _unref(t)('inviteInput'),
        allowClear: "",
        value: inviteCode.value,
        "onUpdate:value": _cache[3] || (_cache[3] = $event => inviteCode.value = $event)
      }, null, 8, ["placeholder", "value"]), _createElementVNode("div", _hoisted_10, [_createTextVNode(_toDisplayString(_unref(t)('phone')), 1), _createElementVNode("span", _hoisted_11, " (" + _toDisplayString(_unref(t)('required')) + ")", 1)]), _createVNode(_component_a_input, {
        class: "pwd-input",
        placeholder: _unref(t)('phoneTip'),
        allowClear: "",
        value: phone.value,
        "onUpdate:value": _cache[4] || (_cache[4] = $event => phone.value = $event)
      }, null, 8, ["placeholder", "value"])]), _createElementVNode("div", _hoisted_12, [_createElementVNode("p", _hoisted_13, [_createTextVNode(_toDisplayString(_unref(t)('registerTip')) + " ", 1), _createElementVNode("span", {
        style: {
          "color": "rgb(171,84,219)",
          "letter-spacing": "0px"
        },
        onClick: _cache[5] || (_cache[5] = $event => _unref(router).push({
          name: 'agreeList'
        }))
      }, _toDisplayString(_unref(t)('agree')), 1)]), _createVNode(_component_van_button, {
        color: "rgb(171,84,219)",
        block: "",
        onClick: showEmailCheck,
        style: {
          "margin": "22px 0 10px 0",
          "--van-button-default-height": "40px",
          "font-size": "17px",
          "--van-button-border-radius": "4px"
        }
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(t)('next')), 1)]),
        _: 1
      }), _createVNode(_component_van_button, {
        color: "#04AC92",
        block: "",
        onClick: _cache[6] || (_cache[6] = $event => _unref(router).replace({
          name: 'home'
        })),
        style: {
          "height": "40px",
          "font-size": "17px",
          "--van-button-border-radius": "4px"
        }
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(t)('home')), 1)]),
        _: 1
      })])])]), _createVNode(CheckPopup, {
        "check-show": checkShow.value,
        onHide: _cache[7] || (_cache[7] = $event => checkShow.value = false),
        email: email.value,
        captcha: captcha.value,
        "captcha-id": captchaId.value,
        onNext: next,
        text: _unref(t)('register'),
        "next-loading": nextLoading.value
      }, null, 8, ["check-show", "email", "captcha", "captcha-id", "text", "next-loading"])]);
    };
  }

};