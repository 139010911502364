import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "app-container"
};
import { onBeforeMount, onMounted } from 'vue';
import { useStore } from 'vuex';
import BigNumber from "bignumber.js";
import moment from 'moment';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'App',

  setup(__props) {
    const i18n = useI18n();
    const store = useStore();
    onBeforeMount(() => {
      try {
        let lang = localStorage.getItem("websetlang") || "en";
        store.commit('langChange', lang);

        const msg = require(`@/language/lang/${lang}`).default;

        i18n.setLocaleMessage(lang, msg);
        i18n.locale.value = lang;
      } catch (err) {
        console.warn(err);
      }
    });
    onMounted(() => {
      window.addEventListener('beforeunload', () => {
        if (store.state.token !== '') {
          localStorage.setItem('astore_info', JSON.stringify({
            token: store.state.token,
            time: moment().unix(),
            email: store.state.email
          }));
        }
      }); // //解决hw问题
      // window.localStorage.clear()
      // window.sessionStorage.clear()
    });
    store.commit('wsInit');
    store.commit('getUser');
    const info = localStorage.getItem("astore_info");

    if (info) {
      const {
        token,
        time,
        email
      } = JSON.parse(info);

      if (token && time && email) {
        const now = moment().unix();
        const timeDif = BigNumber(now).minus(time).toNumber();

        if (timeDif <= 3600) {
          store.commit('tokenChange', token);
          store.commit('emailChange', email);
        } else {
          localStorage.removeItem("astore_info");
        }
      }
    }

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view, null, {
        default: _withCtx(({
          Component
        }) => [(_openBlock(), _createBlock(_KeepAlive, {
          include: "TabView,UserView"
        }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1024))]),
        _: 1
      })]);
    };
  }

};