import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-440716ee"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  style: {
    "overflow-y": "auto",
    "height": "100%"
  }
};
const _hoisted_2 = {
  class: "vp-title"
};
const _hoisted_3 = {
  class: "vp-content"
};
export default {
  __name: 'CustomPopupList',
  props: {
    cShow: {
      type: Boolean
    },
    title: {
      type: String
    }
  },
  emits: ['hide'],

  setup(__props, {
    emit
  }) {
    const props = __props;
    return (_ctx, _cache) => {
      const _component_van_popup = _resolveComponent("van-popup");

      return _openBlock(), _createBlock(_component_van_popup, {
        class: "custom-v-popup",
        show: props.cShow,
        position: "left",
        onClickOverlay: _cache[0] || (_cache[0] = $event => emit('hide')),
        style: {
          "width": "70%",
          "height": "100%"
        }
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(props.title), 1), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "default")])])]),
        _: 3
      }, 8, ["show"]);
    };
  }

};