export function fmtImageSrc(src) {
  return process.env.VUE_APP_BASE_API + src
}

export function fmtNumber(num) {
  return `${num}`.replace(/(?:\.0*|(\.\d+?)0+)$/, '$1')
}

export function formatDate(ts, fmt) {
  if (!ts || ts <= 0) {
    return "-";
  }
  if (ts < 9999999999) {
    ts *= 1000;
  }
  const date = new Date(ts);
  if (!fmt) {
    fmt = "yyyy-MM-dd hh:mm:ss";
  }
  const month = date.getMonth();
  const o = {
    "M+": month + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
    "q+": Math.floor((month + 3) / 3),
    S: date.getMilliseconds(),
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (const k in o) {
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  }
  return fmt;
}